import { Texts } from '../../../pure-js/libs/Texts'
import { State } from '../hooks/useAppState'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import iconCompleted from '/iconCompleted.svg'
import { memo } from 'react'

type Props = {
  state: State
}

const CourseRegistrationStepFiveClosedContent = ({ state }: Props) => (
  <Box fullWidth direction="row" align="center" gap="15px">
    {state.isRegistrationCompleted || (!!state.obfuscatedEnrollment?.mainUser && state.isPaid) ? (
      <img src={iconCompleted} alt="Completed icon" width={24} height={24} />
    ) : null}
    <FigmaText
      textKey={
        state?.activeRegisterStep === 5 || (!!state.obfuscatedEnrollment?.mainUser && state.isPaid)
          ? Texts.registrationHeadersAndStatusHeaderCssDone
          : Texts.registrationHeadersAndStatusHeaderCssNotDone
      }
      text={getFigmaText(Texts.registrationHeadersAndStatusHeaderFinalSummary)}
    />
  </Box>
)

export default memo(CourseRegistrationStepFiveClosedContent)
