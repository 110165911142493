import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

type Props = {
  color?: string
}

const Spinner = ({ color }: Props) => {
  return <StyledSpinner color={color} />
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  border: 2px solid ${({ color }) => color || '#fff'};
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`

export default Spinner
