import { useQuery } from 'react-query'
import { EnrollmentWithPayment } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { getObfuscatedEnrollment } from './CloudFunctionsApiHandler'

export enum QueryKeys {
  ENROLLMENT = 'ENROLLMENT',
  OBFUSCATED_ENROLLMENT = 'OBFUSCATED_ENROLLMENT'
}

export const useEnrollment = (state: State) =>
  useQuery<EnrollmentWithPayment>(
    [QueryKeys.ENROLLMENT],
    () => getObfuscatedEnrollment({ enrollmentId: state.enrollmentId }, state),
    { enabled: !!state.enrollmentId }
  )

export const useObfuscatedEnrollment = (enrollmentId: string, state: State) =>
  useQuery<EnrollmentWithPayment>(
    [QueryKeys.OBFUSCATED_ENROLLMENT],
    () => getObfuscatedEnrollment({ enrollmentId: enrollmentId }, state),
    { enabled: !!enrollmentId }
  )
