import { memo, useEffect, useState } from 'react'
import Box from './Box'
import iconCompleted from '/public/iconCompleted.svg'
import iconArrow from '/public/iconArrow.svg'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { CourseRegistrationFormValues, isValidEmail } from '../helpers/CourseRegistrationStepOneHelper'
import { ContinueButton } from './StyledComponents'
import { State } from '../hooks/useAppState'
import styled from '@emotion/styled'
import { EIGHT_PX, FOUR_PX, TWELVE_PX } from '../enums/Spacings'
import { Checkbox, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'
import { REGISTER_PAGE_MAX_WIDTH } from '../enums/HardCodedValues'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Client } from '../../../pure-js/types/GrooverTypes'
import { styled as muiStyled } from '@mui/material/styles'
import { Add } from '@mui/icons-material'
import { DanceRoles } from '../../../pure-js/types/DanceRoles'

type Props = {
  state: State
  client?: Client
  formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>
  role: DanceRoles
  changeRole: (isPartnerRole: boolean, newRole: DanceRoles) => void
  hasPartner: boolean
  onNextStep: () => void
  setHasPartner: (hasPartner: boolean) => void
  onSubmit: (data: CourseRegistrationFormValues) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
  isSingleRegistration?: boolean
}

const CourseRegistrationStepOneNoPartnerForm = ({
  state,
  client,
  onNextStep,
  formProps,
  role,
  changeRole,
  hasPartner,
  setHasPartner,
  onSubmit,
  onSetIsMainUserStudent,
  onSetIsMainUserSenior,
  isSingleRegistration = false
}: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Handles resize for mobile/web resolutions
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Set type to 'single' when it's a single registration activity
  useEffect(() => {
    if (isSingleRegistration) {
      changeRole(false, 'single')
    }
  }, [isSingleRegistration, changeRole])

  const handleSelectStudentDiscount = () => {
    if (state.isRegistrationCompleted || state.isRegistrationSubmitted || state.obfuscatedEnrollment?.mainUser?.phone)
      return
    if (state.isMainUserStudent) return onSetIsMainUserStudent(false)
    onSetIsMainUserStudent(true)
    onSetIsMainUserSenior(false)
  }

  const handleSelectSeniorDiscount = () => {
    if (state.isRegistrationCompleted || state.isRegistrationSubmitted || state.obfuscatedEnrollment?.mainUser?.phone)
      return
    if (state.isMainUserSenior) return onSetIsMainUserSenior(false)
    onSetIsMainUserStudent(false)
    onSetIsMainUserSenior(true)
  }

  return (
    <FormContainer fullWidth>
      <form>
        <Box fullWidth fullPadding spacing="16px">
          <Box direction="row" gap="15px">
            {state.isRegistrationCompleted ||
              (!!state.obfuscatedEnrollment?.mainUser && <img src={iconCompleted} width={24} height={24} />)}
            <FigmaText
              textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
              text={getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="name"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.mainUser?.name
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment.mainUser?.name ? state.obfuscatedEnrollment.mainUser?.name : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegNameHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.mainUser?.phone
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment.mainUser?.phone
                      ? state.obfuscatedEnrollment.mainUser?.phone
                      : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegPhoneNumberHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="email"
              rules={{
                validate: (email) => {
                  if (!email) return false
                  return isValidEmail(email)
                }
              }}
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  type="email"
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.mainUser?.email
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment.mainUser?.email
                      ? state.obfuscatedEnrollment.mainUser?.email
                      : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegEmailHelper}
                />
              )}
            />
          </Box>

          {!!client?.showSsnInRegistration && (
            <Box fullWidth top bottom spacing="8px">
              <Controller
                control={formProps.control}
                name="mainUserSsn"
                render={({ field, fieldState }) => (
                  <TextFieldWithLabelForm
                    disabled={
                      state.isRegistrationCompleted ||
                      state.isRegistrationSubmitted ||
                      !!state.obfuscatedEnrollment?.mainUser?.ssn
                    }
                    {...field}
                    value={
                      state.obfuscatedEnrollment.mainUser?.ssn ? state.obfuscatedEnrollment.mainUser?.ssn : field.value
                    }
                    fullWidth
                    fieldState={fieldState}
                    placeholderTextKey={Texts.registrationStep1AttendeesRegSocialSecurityNumber}
                  />
                )}
              />
            </Box>
          )}

          <Box
            id="user-types-container"
            fullWidth
            direction="row"
            changeDirectionOnMobile
            align="center"
            justify="flex-start"
            top
            spacing={EIGHT_PX}
          >
            {!isSingleRegistration && (
              <UserTypeBox id="user-types-roles" spacing={EIGHT_PX} alignSelf="flex-start">
                <FormControl>
                  <StyledRadioGroup
                    row
                    value={state.obfuscatedEnrollment?.mainUser?.type || role}
                    onChange={(e) => {
                      if (
                        state.isRegistrationCompleted ||
                        state.isRegistrationSubmitted ||
                        state.obfuscatedEnrollment?.mainUser?.phone
                      )
                        return
                      changeRole(false, e.target.value as 'leader' | 'follower' | 'both')
                    }}
                  >
                    <StyledFormControlLabel
                      value="leader"
                      control={<StyledRadio />}
                      label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelLeader)}
                      disabled={
                        state.isRegistrationCompleted ||
                        state.isRegistrationSubmitted ||
                        !!state.obfuscatedEnrollment?.mainUser?.phone
                      }
                    />
                    <StyledFormControlLabel
                      value="follower"
                      control={<StyledRadio />}
                      label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelFollower)}
                      disabled={
                        state.isRegistrationCompleted ||
                        state.isRegistrationSubmitted ||
                        !!state.obfuscatedEnrollment?.mainUser?.phone
                      }
                    />
                    {!!client?.allowBothForCouples && (
                      <StyledFormControlLabel
                        value="both"
                        control={<StyledRadio />}
                        label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelBoth)}
                        disabled={
                          state.isRegistrationCompleted ||
                          state.isRegistrationSubmitted ||
                          !!state.obfuscatedEnrollment?.mainUser?.phone
                        }
                      />
                    )}
                  </StyledRadioGroup>
                </FormControl>
              </UserTypeBox>
            )}
            <UserTypeDiscounts
              id="user-types-discounts"
              direction="row"
              alignSelf="flex-start"
              spacing={TWELVE_PX}
              gap={TWELVE_PX}
            >
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox
                  disabled={state.isRegistrationCompleted || state.isRegistrationSubmitted}
                  checked={
                    // @ts-ignore
                    state.obfuscatedEnrollment?.discountUserInput?.mainUser?.isStudent || state.isMainUserStudent
                  }
                  onClick={handleSelectStudentDiscount}
                />
                <FigmaText textKey={Texts.registrationStep3ActivitiesStudentCheckboxLabel} />
              </Box>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox
                  disabled={state.isRegistrationCompleted || state.isRegistrationSubmitted}
                  // @ts-ignore
                  checked={state.obfuscatedEnrollment?.discountUserInput?.mainUser?.isSenior || state.isMainUserSenior}
                  onClick={handleSelectSeniorDiscount}
                />
                <FigmaText textKey={Texts.registrationStep3ActivitiesSeniorCheckboxLabel} />
              </Box>
            </UserTypeDiscounts>
          </Box>

          <Box
            id="bottom-container"
            fullWidth
            direction={windowWidth <= 768 ? 'column' : 'row'}
            align="center"
            justify="space-between"
            spacing={EIGHT_PX}
            top
          >
            <Box id="cta-button-container" direction="row" justify="space-between" align="center" fullWidth>
              {!state.isRegistrationCompleted &&
                !hasPartner &&
                !state.obfuscatedEnrollment?.mainUser?.phone &&
                !isSingleRegistration && (
                  <AddPartnerButton
                    isDisabled={state.isRegistrationCompleted || state.isRegistrationSubmitted}
                    direction="row"
                    align="center"
                    onClick={
                      state.isRegistrationCompleted || state.isRegistrationSubmitted
                        ? undefined
                        : () => setHasPartner(true)
                    }
                  >
                    <Add sx={{ fontSize: 20, marginRight: '8px', color: '#2e7d32' }} />
                    <FigmaText textKey={Texts.registrationCtactaAddPartner} />
                  </AddPartnerButton>
                )}
              {(!hasPartner || isSingleRegistration) &&
                !state.isRegistrationCompleted &&
                !state.obfuscatedEnrollment?.partnerUser?.phone && (
                  <ContinueButton
                    isDisabled={state.hasFormErrors}
                    disabled={state.hasFormErrors}
                    onClick={
                      state.obfuscatedEnrollment?.mainUser?.phone ? onNextStep : formProps.handleSubmit(onSubmit)
                    }
                  >
                    <FigmaText textKey={Texts.registrationCtactaContinue} />
                    <img src={iconArrow} width={16} height={16} />
                  </ContinueButton>
                )}
            </Box>
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}
const UserTypeDiscounts = styled(Box)`
  padding: 4px;
`
const UserTypeBox = styled(Box)`
  background-color: rgb(243, 242, 242);
  border-radius: 12px;
`

const FormContainer = styled(Box)`
  background-color: #fcfcfc;
  max-width: ${REGISTER_PAGE_MAX_WIDTH};
  box-shadow: 0px 0px 8px 0px #2422bb;
  border-radius: ${BorderRadixes.moderate};
`

const AddPartnerButton = styled(Box)<{ isDisabled?: boolean }>`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  border: 1px solid #aaa;
  background-color: ${(props) => (props.isDisabled ? '#f5f5f5' : 'white')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  border-radius: ${BorderRadixes.button};
  display: flex;
  align-items: center;
  color: #2e7d32;
  padding: 8px 16px;

  &:hover {
    background-color: #f5f5f5;
  }
`

const StyledRadioGroup = muiStyled(RadioGroup)`
  gap: 6px;
`

const StyledFormControlLabel = muiStyled(FormControlLabel)`
  border-radius: 12px;
  margin: 0;
  padding: 8px 16px;
  color: black;
  
  &.Mui-checked {
    background-color: #0E0349;
    
    .MuiTypography-root {
      color: black;
    }
  }

  .MuiTypography-root {
    color: black;
  }
`

const StyledRadio = muiStyled(Radio)`
  padding: 4px;
`

export default memo(CourseRegistrationStepOneNoPartnerForm)
