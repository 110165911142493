import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { FeatureFlagsContext, FlagNames } from '../libs/FeatureFlags'
import { ActivityDetailPage } from '../pages/ActivityDetailPage'
import FeatureFlagsAdminPage from '../pages/FeatureFlagsAdminPage'
import IframePage from '../pages/IframePage'
import PayPage from '../pages/PayPage'
import PaySuccessPage from '../pages/PaySuccessPage'
import { PublicActivitiesPage } from '../pages/PublicActivitiesPage'
import RegisterPage from '../pages/RegisterPage'
import SettingsEmailsPage from '../pages/SettingsEmailsPage'
import { RootPage } from '../pages/SignInPage'
import StaticDemoPage from '../pages/StaticDemoPage'
import SwishPayPage from '../pages/SwishPayPage'
import TestPage from '../pages/TestPage'
import EmptyActivity from './EmptyActivity'
import SandboxPage from './SandboxPage'
import { UrlShortRedirectPage } from '../pages/UrlShortRedirectPage'

const NavigationPublicRoutes: React.FC = () => {
  const { isFeatureEnabled, getStorageKey } = useContext(FeatureFlagsContext)

  const FeatureFlagsPage = () => {
    return <FeatureFlagsAdminPage storageKey={getStorageKey()} />
  }

  return (
    <Routes>
      <Route path={RoutePath.INCORRECT_ROUTE} Component={EmptyActivity} />
      <Route path={RoutePath.ROOT} Component={RootPage} />
      <Route path={RoutePath.URL_SHORT_REDIRECT} Component={UrlShortRedirectPage} />
      <Route path={RoutePath.ACTIVITY_DETAIL} Component={ActivityDetailPage} />
      <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
      <Route path={RoutePath.IFRAME} Component={IframePage} />
      <Route path={RoutePath.DEMOPAGE} Component={StaticDemoPage} />
      <Route path={RoutePath.PAY} Component={PayPage} />
      <Route path={RoutePath.PAY_SUCCESS} Component={PaySuccessPage} />
      <Route path={RoutePath.REGISTER} Component={RegisterPage} />
      <Route path={RoutePath.SWISH_PAY} Component={SwishPayPage} />
      <Route path={RoutePath.PUBLIC_ACTIVITIES} Component={PublicActivitiesPage} />
      <Route path={RoutePath.TEST} Component={TestPage} />
      <Route path={RoutePath.FEATURES} Component={FeatureFlagsPage} />
      <Route path={RoutePath.REGISTER_OBFUSCATED} Component={RegisterPage} />

      {isFeatureEnabled(FlagNames.BETA) && <Route path={RoutePath.SETTINGS_EMAILS} Component={SettingsEmailsPage} />}
    </Routes>
  )
}

export default NavigationPublicRoutes
