import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getUrlShort } from '../libs/DBApiHandler'

export const UrlShortRedirectPage = () => {
  const { hash } = useParams<{ hash: string }>() // Read "hash" from URL params
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      try {
        setIsLoading(true)
        if (!hash) {
          setError('Hash is missing')
          setIsLoading(false)
          return
        }

        // Retrieve the original URL from Firestore
        const data = await getUrlShort(hash)

        if (data) {
          const redirectUrl = data.url
          if (redirectUrl) {
            // Check if URL is internal or external
            if (redirectUrl.startsWith('/') || redirectUrl.startsWith(window.location.origin)) {
              // For internal links, use React Router navigation
              const internalPath = redirectUrl.startsWith('/')
                ? redirectUrl
                : redirectUrl.replace(window.location.origin, '')
              navigate(internalPath)
            } else {
              // For external links, we still need to use window.location
              window.location.href = redirectUrl
            }
          } else {
            setError('Original URL not found')
          }
        } else {
          setError('Invalid hash')
        }
      } catch (err) {
        console.error('Error fetching original URL:', err)
        setError('Failed to fetch data')
      } finally {
        setIsLoading(false)
      }
    }

    fetchOriginalUrl()
  }, [hash, navigate])

  return (
    <div className="redirect-container">
      {isLoading ? (
        <div className="spinner">Redirecting...</div>
      ) : error ? (
        <p className="error-state">{error}</p>
      ) : null}
    </div>
  )
}
