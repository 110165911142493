import { memo } from 'react'
import { Texts } from '../../../pure-js/libs/Texts'
import { State } from '../hooks/useAppState'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import iconCompleted from '/public/iconCompleted.svg'

type Props = {
  state: State
}

const CourseRegistrationStepOneClosedContent = ({ state }: Props) => {
  const attendeeLabel = getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)

  return (
    <Box fullWidth direction="row" align="center" gap="15px">
      {(!!state.name && !!state.email && !!state.phoneNumber) || !!state.obfuscatedEnrollment?.mainUser ? (
        <img src={iconCompleted} width={24} height={24} />
      ) : null}
      <FigmaText
        textKey={
          state?.activeRegisterStep > 1 || !!state.obfuscatedEnrollment?.mainUser
            ? Texts.registrationHeadersAndStatusHeaderCssDone
            : Texts.registrationHeadersAndStatusHeaderCssActive
        }
        text={attendeeLabel}
      />
    </Box>
  )
}

export default memo(CourseRegistrationStepOneClosedContent)
