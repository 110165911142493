import { memo } from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { CourseRegistrationFormValues, isValidEmail } from '../helpers/CourseRegistrationStepOneHelper'
import styled from '@emotion/styled'
import { ContinueButton } from './StyledComponents'
import { State } from '../hooks/useAppState'
import iconCompleted from '/public/iconCompleted.svg'
import iconArrow from '/public/iconArrow.svg'
import { EIGHT_PX, FOUR_PX, TWELVE_PX } from '../enums/Spacings'
import { Checkbox, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'
import { Client } from '../../../pure-js/types/GrooverTypes'
import { styled as muiStyled } from '@mui/material/styles'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Clear } from '@mui/icons-material'
import { DanceRoles } from '../../../pure-js/types/DanceRoles'

type Props = {
  state: State
  client?: Client
  formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>
  partnerRole: DanceRoles
  changeRole: (isPartnerRole: boolean, newRole: DanceRoles) => void
  handleRemovePartner: () => void
  onNextStep: () => void
  onSubmit: (data: CourseRegistrationFormValues) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
}

const CourseRegistrationStepOnePartnerForm = ({
  state,
  client,
  formProps,
  partnerRole,
  changeRole,
  handleRemovePartner,
  onNextStep,
  onSubmit,
  onSetIsPartnerStudent,
  onSetIsPartnerSenior
}: Props) => {
  const handleSelectStudentDiscount = () => {
    if (
      state.isRegistrationCompleted ||
      state.isRegistrationSubmitted ||
      state.obfuscatedEnrollment?.partnerUser?.phone
    )
      return
    if (state.isPartnerStudent) return onSetIsPartnerStudent(false)
    onSetIsPartnerStudent(true)
    onSetIsPartnerSenior(false)
  }

  const handleSelectSeniorDiscount = () => {
    if (
      state.isRegistrationCompleted ||
      state.isRegistrationSubmitted ||
      state.obfuscatedEnrollment?.partnerUser?.phone
    )
      return
    if (state.isPartnerSenior) return onSetIsPartnerSenior(false)
    onSetIsPartnerStudent(false)
    onSetIsPartnerSenior(true)
  }

  console.log(client)
  return (
    <FormContainer fullWidth>
      <form>
        <Box fullWidth fullPadding spacing="16px">
          <Box direction="row" gap="15px">
            {(state.isRegistrationCompleted || !!state.obfuscatedEnrollment?.partnerUser?.phone) && (
              <img src={iconCompleted} width={24} height={24} />
            )}
            <FigmaText
              textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
              text={getFigmaText(Texts.registrationHeadersAndStatusHeaderAttendee)}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerName"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.partnerUser?.name
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment?.partnerUser?.name
                      ? state.obfuscatedEnrollment?.partnerUser?.name
                      : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegNameHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerPhoneNumber"
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.partnerUser?.phone
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment?.partnerUser?.phone
                      ? state.obfuscatedEnrollment?.partnerUser?.phone
                      : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegPhoneNumberHelper}
                />
              )}
            />
          </Box>
          <Box fullWidth top bottom spacing="8px">
            <Controller
              control={formProps.control}
              name="partnerEmail"
              rules={{
                validate: (email) => {
                  if (!email) return false
                  return isValidEmail(email)
                }
              }}
              render={({ field, fieldState }) => (
                <TextFieldWithLabelForm
                  type="email"
                  disabled={
                    state.isRegistrationCompleted ||
                    state.isRegistrationSubmitted ||
                    !!state.obfuscatedEnrollment?.partnerUser?.email
                  }
                  {...field}
                  value={
                    state.obfuscatedEnrollment?.partnerUser?.email
                      ? state.obfuscatedEnrollment?.partnerUser?.email
                      : field.value
                  }
                  fullWidth
                  fieldState={fieldState}
                  placeholderTextKey={Texts.registrationStep1AttendeesRegEmailHelper}
                />
              )}
            />
          </Box>

          {!!client?.showSsnInRegistration && (
            <Box fullWidth top bottom spacing="8px">
              <Controller
                control={formProps.control}
                name="partnerUserSsn"
                render={({ field, fieldState }) => (
                  <TextFieldWithLabelForm
                    disabled={
                      state.isRegistrationCompleted ||
                      state.isRegistrationSubmitted ||
                      !!state.obfuscatedEnrollment?.partnerUser?.ssn
                    }
                    {...field}
                    value={
                      state.obfuscatedEnrollment?.partnerUser?.ssn
                        ? state.obfuscatedEnrollment?.partnerUser?.ssn
                        : field.value
                    }
                    fullWidth
                    fieldState={fieldState}
                    placeholderTextKey={Texts.registrationStep1AttendeesRegSocialSecurityNumber}
                  />
                )}
              />
            </Box>
          )}

          <Box id="user-types-container" direction="row" fullWidth changeDirectionOnMobile align="center">
            <UserTypeBox id="user-types-roles" spacing={EIGHT_PX} alignSelf="flex-start">
              <FormControl>
                <StyledRadioGroup
                  row
                  value={state.obfuscatedEnrollment?.partnerUser?.type || partnerRole}
                  onChange={(e) => {
                    if (
                      state.isRegistrationCompleted ||
                      state.isRegistrationSubmitted ||
                      state.obfuscatedEnrollment?.partnerUser?.phone
                    )
                      return
                    changeRole(true, e.target.value as 'leader' | 'follower' | 'both' | 'single')
                  }}
                >
                  <StyledFormControlLabel
                    value="leader"
                    control={<StyledRadio />}
                    label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelLeader)}
                    disabled={
                      state.isRegistrationCompleted ||
                      state.isRegistrationSubmitted ||
                      !!state.obfuscatedEnrollment?.partnerUser?.phone
                    }
                  />
                  <StyledFormControlLabel
                    value="follower"
                    control={<StyledRadio />}
                    label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelFollower)}
                    disabled={
                      state.isRegistrationCompleted ||
                      state.isRegistrationSubmitted ||
                      !!state.obfuscatedEnrollment?.partnerUser?.phone
                    }
                  />
                  {!!client?.allowBothForCouples && (
                    <StyledFormControlLabel
                      value="both"
                      control={<StyledRadio />}
                      label={getFigmaText(Texts.registrationStep1AttendeesRegRoleLabelBoth)}
                      disabled={
                        state.isRegistrationCompleted ||
                        state.isRegistrationSubmitted ||
                        !!state.obfuscatedEnrollment?.partnerUser?.phone
                      }
                    />
                  )}
                </StyledRadioGroup>
              </FormControl>
            </UserTypeBox>
            <UserTypeDiscounts
              id="user-types-discounts"
              direction="row"
              alignSelf="flex-start"
              align="center"
              spacing={TWELVE_PX}
              gap={TWELVE_PX}
            >
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox
                  checked={
                    // @ts-ignore
                    state.obfuscatedEnrollment.discountUserInput?.partnerUser?.isStudent || state.isPartnerStudent
                  }
                  onClick={handleSelectStudentDiscount}
                />
                <FigmaText textKey={Texts.registrationStep3ActivitiesStudentCheckboxLabel} />
              </Box>
              <Box direction="row" align="center" gap={FOUR_PX}>
                <Checkbox
                  // @ts-ignore
                  checked={state.obfuscatedEnrollment.discountUserInput?.partnerUser?.isSenior || state.isPartnerSenior}
                  onClick={handleSelectSeniorDiscount}
                />
                <FigmaText textKey={Texts.registrationStep3ActivitiesSeniorCheckboxLabel} />
              </Box>
            </UserTypeDiscounts>
          </Box>

          <Box fullWidth direction="row" align="center" justify="space-between" top spacing="8px">
            {!state.obfuscatedEnrollment?.partnerUser && (
              <Box direction="row" align="center" gap={TWELVE_PX}>
                {!state.isRegistrationCompleted && (
                  <RemovePartnerButton
                    direction="row"
                    align="center"
                    onClick={
                      state.isRegistrationCompleted || state.isRegistrationSubmitted ? undefined : handleRemovePartner
                    }
                  >
                    <Clear sx={{ fontSize: 20, marginRight: '8px' }} />
                    <FigmaText textKey={Texts.registrationCtactaRemovePartner} />
                  </RemovePartnerButton>
                )}
              </Box>
            )}
            {!state.isRegistrationCompleted ? (
              <ContinueButton
                isDisabled={state.hasFormErrors}
                disabled={state.hasFormErrors}
                onClick={state.obfuscatedEnrollment?.partnerUser?.phone ? onNextStep : formProps.handleSubmit(onSubmit)}
              >
                <FigmaText textKey={Texts.registrationCtactaContinue} />
                <img src={iconArrow} width={16} height={16} />
              </ContinueButton>
            ) : null}
          </Box>
        </Box>
      </form>
    </FormContainer>
  )
}

const UserTypeDiscounts = styled(Box)`
  padding: 4px;
`

const UserTypeBox = styled(Box)`
  background-color: rgb(243, 242, 242);
  border-radius: 12px;
`

const FormContainer = styled(Box)`
  max-width: 865px;
  border-radius: 12px;
  background: #fcfcfc;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const StyledRadioGroup = muiStyled(RadioGroup)`
  gap: 6px;
`

const StyledFormControlLabel = muiStyled(FormControlLabel)`
  border-radius: 12px;
  margin: 0;
  padding: 8px 16px;
  color: black;
  
  &.Mui-checked {
    background-color: #0E0349;
    
    .MuiTypography-root {
      color: black;
    }
  }

  .MuiTypography-root {
    color: black;
  }
`

const StyledRadio = muiStyled(Radio)`
  padding: 4px;
`

const RemovePartnerButton = styled(Box)`
  cursor: pointer;
  border: 1px solid #aaa;
  padding: 8px 16px;
  border-radius: ${BorderRadixes.moderate};
  background-color: white;
  color: red;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f5f5f5;
  }
`

export default memo(CourseRegistrationStepOnePartnerForm)
