import React from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import { Discount } from '../../../pure-js/types/DiscountTypes'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from './FigmaText'
import { formatText, getDiscountName, getFigmaText } from '../libs/TextRepository'

interface RegistrationDiscountDisplayProps {
  matchedDiscounts: Discount[]
}

const RegistrationDiscountDisplay: React.FC<RegistrationDiscountDisplayProps> = ({ matchedDiscounts }) => {
  // Function to format the discount value based on calculation type
  const formatDiscountValue = (discount: Discount): string => {
    switch (discount.calculationType) {
      case 'percentage':
        return `${discount.value}%`
      case 'amount':
        return `${discount.value / 100} SEK`
      default:
        return `${discount.value}` // Fallback in case there's an unknown type
    }
  }
  return (
    <>
      {matchedDiscounts
        .filter((discount) => discount?.value !== 0)
        .map((discount, index) => {
          const hasCourseDiscounts = discount?.type === 'multi_course_matrix' && discount.courseDiscounts
          return (
            <React.Fragment key={`${index}-matched-discounts`}>
              <DiscountTextContainer
                fullWidth
                justify="space-between"
                direction="row"
                hasCourseDiscounts={!!hasCourseDiscounts}
              >
                <Box>
                  <FigmaText
                    textKey={Texts.componentsPublicDiscountCssPublicActivityDiscountName}
                    text={getDiscountName(discount?.type)}
                  />
                </Box>
                {(!discount.courseDiscounts || discount.courseDiscounts.every((arr) => !!arr.course)) && (
                  <Box>
                    {['NaN SEK', 'undefined%'].includes(formatDiscountValue(discount))
                      ? null
                      : formatDiscountValue(discount)}
                  </Box>
                )}
              </DiscountTextContainer>

              {hasCourseDiscounts && (
                <CourseDiscountList>
                  {discount.courseDiscounts.map((courseDiscount, i) => (
                    <CourseDiscountItem key={`${i}-discount-index`}>
                      <Box direction="row">
                        <FigmaText
                          textKey={Texts.componentsPublicDiscountCssPublicActivityDiscountSubRule}
                          text={formatText(
                            getFigmaText(Texts.componentsPublicDiscountCssPublicActivityDiscountSubRule),
                            [`${courseDiscount.course}`]
                          )}
                        />
                      </Box>
                      <Box>
                        <FigmaText
                          textKey={Texts.componentsPublicDiscountCssPublicActivityDiscountSubRule}
                          text={
                            discount.calculationType === 'percentage'
                              ? `${courseDiscount.discount}%`
                              : `${courseDiscount.discount / 100} SEK`
                          }
                        />
                      </Box>
                    </CourseDiscountItem>
                  ))}
                </CourseDiscountList>
              )}
            </React.Fragment>
          )
        })}
    </>
  )
}

// Conditionally apply margin-bottom based on the presence of course discounts
const DiscountTextContainer = styled(Box)<{ hasCourseDiscounts: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  border-radius: 4px;
  color: #0c9f2c;
  background-color: rgba(0, 119, 24, 0.05);
  padding: 8px;
  border-left: 4px solid #0c9f2c;
  box-sizing: border-box; /* Ensure padding is included in the element's width */
  margin-bottom: ${({ hasCourseDiscounts }) => (hasCourseDiscounts ? '0' : '16px')}; /* Remove margin when needed */
`

const CourseDiscountList = styled(Box)`
  color: #666;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 119, 24, 0.1);
  border-left: 4px solid #0c9f2c;
  padding-left: 20px;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-bottom: 16px;
`

const CourseDiscountItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;

  align-items: center;
  width: 100%;
  padding-right: 8px;
`

export default RegistrationDiscountDisplay
