import { Activity } from '../../../pure-js/types/GrooverTypes'
import { memo } from 'react'
import { State } from '../hooks/useAppState'

import { ClosedContainer, OpenContainer } from './StyledComponents'
import CourseRegistrationStepFiveClosedContent from './CourseRegistrationStepFiveClosedContent'
import CourseRegistrationStepFiveOpenedContent from './CourseRegistrationStepFiveOpenedContent'

type Props = {
  state: State
  activity: Activity
  onOpenStepFive: () => void
  registerActiveStep: number
  onSetMainUserSsn: (mainUserSsn: string) => void
  onSetPartnerUserSsn: (partnerUserSsn: string) => void
}

const CourseRegistrationStepFive = ({
  state,
  activity,
  onOpenStepFive,
  registerActiveStep,
  onSetMainUserSsn,
  onSetPartnerUserSsn
}: Props) => {
  return registerActiveStep !== 5 ? (
    <ClosedContainer onClick={state.isRegistrationCompleted ? onOpenStepFive : undefined}>
      <CourseRegistrationStepFiveClosedContent state={state} />
    </ClosedContainer>
  ) : (
    <OpenContainer>
      <CourseRegistrationStepFiveOpenedContent
        state={state}
        activity={activity}
        onSetMainUserSsn={onSetMainUserSsn}
        onSetPartnerUserSsn={onSetPartnerUserSsn}
      />
    </OpenContainer>
  )
}

export default memo(CourseRegistrationStepFive)
