import Box from './Box'
import FigmaText, { FigmaTextOrSkeleton } from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Calculation, CalculationDiscount } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { EIGHT_PX, FOUR_PX } from '../enums/Spacings'
import { Discount } from './PriceContainerUserPricing'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import styled from '@emotion/styled'

type Props = {
  calculations: Calculation[]
  isLoading: boolean
}

type GroupedCalculation = {
  activities: Activity[]
  activitiesTotalPrice: number
  finalPrice: number
  discounts: CalculationDiscount[]
}

type GroupedDiscount = Omit<CalculationDiscount, 'id' | 'input' | 'output'>

const PriceContainerActivity = ({ calculations, isLoading }: Props) => {
  const groupedCalculations = calculations.reduce<GroupedCalculation>(
    (acc, calc) => {
      acc.activities = [...acc.activities, calc.activity]
      acc.activitiesTotalPrice += calc.activity.price
      acc.finalPrice += calc.finalPrice
      acc.discounts = [...acc.discounts, ...calc.discounts]
      return acc
    },
    { activities: [], discounts: [], finalPrice: 0, activitiesTotalPrice: 0 }
  )

  const mainActivity: Activity = groupedCalculations.activities[0]

  const hasMatrixOrCombinedDiscount = groupedCalculations.discounts.some(
    (discount) => discount.type === 'multi_course_matrix' || discount.type === 'combined'
  )

  return (
    <Box fullWidth key={mainActivity.id} top bottom spacing={EIGHT_PX}>
      {ActivityHeader(hasMatrixOrCombinedDiscount, isLoading)}
      {Object.entries(
        groupedCalculations.discounts
          .filter((discount) => discount.type === 'multi_course_matrix' || discount.type === 'combined')
          .reduce<Record<string, GroupedDiscount>>((acc, discount) => {
            if (acc[discount.name]) {
              acc[discount.name].discount += discount.discount
            } else {
              acc[discount.name] = {
                type: discount.type,
                name: discount.name,
                discount: discount.discount,
                discountCalculationType: discount.discountCalculationType,
                discountValue: discount.discountValue
              }
            }
            return acc
          }, {})
      ).map(([group, groupedDiscount]) => {
        if (groupedDiscount.type === 'multi_course_matrix') {
          return RenderActivityDiscount(Texts.componentsPricingControlBundleDiscountRow, groupedDiscount)
        } else if (groupedDiscount.type === 'combined') {
          return RenderActivityDiscount(Texts.componentsPricingControlExtentionDiscountRow, groupedDiscount)
        }
      })}

      {/* Only show activity total sum if we have certain kinds of discounts. Other discounts are shown in the user pricing
      component. */}
      {/* {hasMatrixOrCombinedDiscount && (
        <Box alignSelf="flex-end" top spacing={EIGHT_PX}>
          <FigmaText
            textKey={Texts.componentsPricingControlCssPriceRowAmount}
            text={`${String(groupedCalculations.finalPrice / 100)} SEK`}
          />
        </Box>
      )} */}
    </Box>
  )

  function RenderActivityDiscount(textKey: TextKey, discount: GroupedDiscount) {
    return (
      <Box gap={FOUR_PX} fullWidth key={discount.name}>
        <Box
          key={discount.name}
          fullWidth
          direction="row"
          align="center"
          justify="space-between"
          top
          spacing={EIGHT_PX}
        >
          <FigmaText textKey={textKey} />

          <Box direction="row" align="center" gap={FOUR_PX}>
            {/* Show relative discount value if percentage (eg. _10%_ (100 SEK)) */}
            {discount.discountCalculationType === 'percentage' && (
              <>
                {' '}
                <FigmaText
                  textKey={Texts.componentsPricingControlDiscountValue}
                  text={`${String(discount.discountValue)}%`}
                />
              </>
            )}
            <Discount fullPadding spacing="2px">
              <FigmaText
                textKey={Texts.componentsPricingControlCssPaymentDiscountAmount}
                text={`-${discount.discount / 100} SEK`}
              />
            </Discount>
          </Box>
        </Box>
      </Box>
    )
  }

  function ActivityHeader(hasDiscount: boolean, isLoading: boolean) {
    return (
      <Box fullWidth direction="row" justify="space-between">
        <FigmaTextOrSkeleton
          isLoading={isLoading}
          textKey={Texts.componentsPricingControlCssPaymentRow}
          text={mainActivity.name}
        />
        <ActivityHeaderBox>
          <FigmaTextOrSkeleton
            isLoading={isLoading}
            textKey={Texts.componentsPricingControlCssActivitySubTotal}
            text={String(groupedCalculations.activitiesTotalPrice / 100) + ' SEK'}
          />
        </ActivityHeaderBox>
      </Box>
    )
  }
}

const ActivityHeaderBox = styled(Box)`
  direction: row;
  min-width: fit-content;
  gap: 4px;
  white-space: nowrap;
`

export default PriceContainerActivity
