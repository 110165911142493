export enum RegisterActions {
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
  SAVE_REGISTRATION_DATA = 'SAVE_REGISTRATION_DATA',
  SET_IS_REGISTRATION_COMPLETED = 'SET_IS_REGISTRATION_COMPLETED',
  SET_FINAL_PRICE = 'SET_FINAL_PRICE',
  SET_FORM_ERRORS = 'SET_FORM_ERRORS',
  SET_SELECTED_ACTIVITIES = 'SET_SELECTED_ACTIVITIES',
  SET_IS_MAIN_USER_STUDENT = 'SET_IS_MAIN_USER_STUDENT',
  SET_IS_PARTNER_STUDENT = 'SET_IS_PARTNER_STUDENT',
  SET_IS_MAIN_USER_SENIOR = 'SET_IS_MAIN_USER_SENIOR',
  SET_IS_PARTNER_SENIOR = 'SET_IS_PARTNER_SENIOR',
  SET_ENROLLMENT_ID = 'SET_ENROLLMENT_ID',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_IS_PAID = 'SET_IS_PAID',
  SET_IS_REGISTRATION_SUBMITTED = 'SET_IS_REGISTRATION_SUBMITTED',
  SET_MAIN_USER_ACTIVE_PERIODS = 'SET_MAIN_USER_ACTIVE_PERIODS',
  SET_PARTNER_USER_ACTIVE_PERIODS = 'SET_PARTNER_USER_ACTIVE_PERIODS',
  SET_OBFUSCATED_ENROLLMENT = 'SET_OBFUSCATED_ENROLLMENT',
  SET_MAIN_USER_SSN = 'SET_MAIN_USER_SSN',
  SET_PARTNER_USER_SSN = 'SET_PARTNER_USER_SSN',
  SET_ENROLLMENT_COMMENT = 'SET_ENROLLMENT_COMMENT'
}
