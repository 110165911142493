import React from 'react'
import { Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from '../libs/TextRepository'

interface RegistrationLocationProps {
  customer: Client
  activity: Activity
}

const RegistrationLocation: React.FC<RegistrationLocationProps> = ({ customer, activity }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDqP2HzJj8g82cwrNDF0YEoVhh26KwDUZs'

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  }

  const location = customer.locations.find((loc) => loc.id === activity.locationId)

  return (
    <div className="segment-container">
      <Box direction="row" gap="6px" align="center" justify="space-between" fullWidth bottom>
        <Typography variant="h5" component="h2" className="header-title">
          {getFigmaText(Texts.externalActivityPageSegmentPlaceHeader)}
        </Typography>
        <IconButton
          className="share-button"
          aria-label="share event"
          onClick={() => {
            location?.googleUrl && window.open(location.googleUrl, '_blank')
          }}
        >
          <ShareIcon />
          <Typography className="share-button-text">Öppna i Google Maps</Typography>
        </IconButton>
      </Box>
      <Box direction="column" gap="6px" fullWidth bottom>
        <Typography variant="body1">
          <strong>{location?.name}</strong>
          <br />
          {location?.adress}
        </Typography>
        <Box direction="column" gap="6px" align="center">
          <Typography variant="body1">
            <strong>{getFigmaText(Texts.externalActivityPageRoomPlace)}</strong> <br />
            {activity.place}
          </Typography>
        </Box>
      </Box>

      {googleMapsApiKey && location?.center && (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={location.center} zoom={17}>
              <Marker position={location.center} />
            </GoogleMap>
          </div>
        </LoadScript>
      )}
    </div>
  )
}

export default RegistrationLocation
