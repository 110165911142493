import { styled } from '@mui/material'
import dayjs from 'dayjs'
import { memo, useEffect, useState } from 'react'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { Texts } from '../../../pure-js/libs/Texts'
import { Client, EnrollmentWithPayment, VatData } from '../../../pure-js/types/GrooverTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { EIGHT_PX, FOUR_PX, SIX_PX, TWELVE_PX } from '../enums/Spacings'
import { formatText, getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import { State } from '../hooks/useAppState'
import '../../../pure-js/libs/ArrayHelper'
import { useActivity } from '../hooks/useActivity'
import { getClient } from '../libs/DBApiHandler'

type Props = {
  ref: any
  mainUserCalculations: Calculation[]
  partnerUserCalculations: Calculation[]
  state: State
  vatData: VatData
  finalPrice: number
  obfuscatedEnrollment?: EnrollmentWithPayment
}

const CourseRegistrationReceipt = ({
  ref,
  mainUserCalculations,
  partnerUserCalculations,
  state,
  finalPrice,
  vatData,
  obfuscatedEnrollment
}: Props) => {
  const urlActivityId = window.location.pathname.split('/')[2]
  const [client, setClient] = useState<Client | undefined>(undefined)

  const getPaymentMethodName = () => {
    const sources =
      state.obfuscatedEnrollment?.payments?.length > 0
        ? state.obfuscatedEnrollment?.payments.map((p) => p.source)
        : obfuscatedEnrollment?.payments.map((p) => p.source)

    return sources?.onlyUniqueJoin(true).replace('Manual', getFigmaText(Texts.registrationStep5PaymentRegPaymentBank))
  }

  const { data: activity } = useActivity(urlActivityId)

  useEffect(() => {
    const fetchClient = async () => {
      if (activity?.clientId) {
        const clientData = await getClient(activity.clientId)
        if (clientData) setClient(clientData)
      }
    }

    if (activity?.id) {
      fetchClient()
    }
  }, [activity?.clientId])

  return (
    <ReceiptBox ref={ref} fullWidth fullPadding spacing={TWELVE_PX}>
      {!!state.obfuscatedEnrollment?.paymentDetails?.paymentRef ||
        (obfuscatedEnrollment?.paymentDetails?.paymentRef && (
          <Box fullWidth bottom align="flex-end">
            <FigmaText
              textKey={Texts.componentsReceiptReceiptIdCss}
              text={
                !!state.obfuscatedEnrollment?.paymentDetails?.paymentRef
                  ? state.obfuscatedEnrollment?.paymentDetails?.paymentRef
                  : obfuscatedEnrollment?.paymentDetails?.paymentRef
              }
            />
          </Box>
        ))}

      {client && client.name && client.orgNo && client.address && (
        <Box fullWidth direction="row" justify="space-between" bottom>
          <FigmaText textKey={Texts.componentsReceiptReceiptPaymentRecieverLabel} />
          <Box gap={FOUR_PX} align="flex-end">
            <FigmaText textKey={Texts.componentsReceiptReceiptPaymentRecieverValueCss} text={client.name} />
            <FigmaText textKey={Texts.componentsReceiptReceiptPaymentRecieverValueCss} text={client.orgNo} />
            <FigmaText textKey={Texts.componentsReceiptReceiptPaymentRecieverValueCss} text={client.address} />
          </Box>
        </Box>
      )}

      <Box fullWidth direction="row" justify="space-between" bottom spacing={TWELVE_PX}>
        <FigmaText textKey={Texts.componentsReceiptReceiptPaymentDateLabel} />
        <Box align="flex-end">
          <FigmaText
            textKey={Texts.componentsReceiptReceiptPaymentDateValue}
            text={dayjs(
              !!state.obfuscatedEnrollment?.paymentDetails?.paymentDate
                ? state.obfuscatedEnrollment?.paymentDetails?.paymentDate
                : obfuscatedEnrollment?.paymentDetails?.paymentDate
            ).format('YYYY-MM-DD HH:mm')}
          />
        </Box>
      </Box>

      <Box fullWidth direction="row" justify="space-between" bottom spacing={TWELVE_PX}>
        <FigmaText textKey={Texts.componentsReceiptReceiptPaymentPaymentMethodLabel} />
        <Box align="flex-end">
          <FigmaText textKey={Texts.componentsReceiptReceiptPaymentPaymentMethodCss} text={getPaymentMethodName()} />
        </Box>
      </Box>

      <Box fullWidth direction="row" justify="space-between" bottom spacing={TWELVE_PX}>
        <FigmaText textKey={Texts.componentsReceiptReceiptPaymentCustomerLabel} />
        <Box align="flex-end">
          <FigmaText
            textKey={Texts.componentsReceiptReceiptPaymentCustomerCss}
            text={
              // @ts-ignore
              state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                ? // @ts-ignore
                  state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                : state.name
            }
          />
          <FigmaText textKey={Texts.componentsReceiptReceiptPaymentCustomerCss} text={state.mainUserSsn} />
        </Box>
      </Box>

      {(state.partnerPhoneNumber || state.obfuscatedEnrollment?.partnerUser?.phone) && (
        <Box fullWidth direction="row" justify="space-between" bottom spacing={TWELVE_PX}>
          <FigmaText textKey={Texts.componentsReceiptReceiptPaymentCustomerLabel} />
          <Box align="flex-end">
            <FigmaText
              textKey={Texts.componentsReceiptReceiptPaymentCustomerCss}
              text={
                // @ts-ignore
                state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                  ? // @ts-ignore
                    state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                  : state.partnerName
              }
            />
            <FigmaText textKey={Texts.componentsReceiptReceiptPaymentCustomerCss} text={state.partnerUserSsn} />
          </Box>
        </Box>
      )}

      <Box fullWidth bottom gap={FOUR_PX}>
        <FigmaText textKey={Texts.componentsReceiptReceiptPaymentDescriptionLabel} />

        <Box fullWidth top spacing={EIGHT_PX} gap={FOUR_PX}>
          <FigmaText
            textKey={Texts.componentsPricingControlPricingAttendeeGroup}
            text={
              // @ts-ignore
              !!state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                ? // @ts-ignore
                  state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                : state.name
            }
          />
          {mainUserCalculations.map((calculation) => (
            <DescriptionBox key={calculation.activity.id} fullWidth fullPadding spacing={SIX_PX}>
              <FigmaText
                textKey={Texts.componentsReceiptReceiptPaymentDescriptionCss}
                text={calculation.activity.name}
              />
            </DescriptionBox>
          ))}
        </Box>

        <Box fullWidth top spacing={EIGHT_PX} gap={FOUR_PX}>
          <FigmaText
            textKey={Texts.componentsPricingControlPricingAttendeeGroup}
            text={
              // @ts-ignore
              !!state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                ? // @ts-ignore
                  state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                : state.partnerName
            }
          />
          {partnerUserCalculations.map((calculation) => (
            <DescriptionBox key={calculation.activity.id} fullWidth fullPadding spacing={SIX_PX}>
              <FigmaText
                textKey={Texts.componentsReceiptReceiptPaymentDescriptionCss}
                text={calculation.activity.name}
              />
            </DescriptionBox>
          ))}
        </Box>
      </Box>

      <Box fullWidth direction="row" justify="space-between" bottom spacing={FOUR_PX}>
        <FigmaText textKey={Texts.componentsReceiptReceiptPaymentTotal} />
        <Box align="flex-end">
          <FigmaText
            textKey={Texts.componentsReceiptReceiptPaymentValueCss}
            text={`${String(state.obfuscatedEnrollment?.priceDetails ? state.obfuscatedEnrollment?.priceDetails?.reduce((acc, pd) => acc + pd.finalPrice, 0) / 100 : obfuscatedEnrollment?.priceDetails ? obfuscatedEnrollment?.priceDetails?.reduce((acc, pd) => acc + pd.finalPrice, 0) / 100 : '')} SEK`}
            // text={finalPrice.toString() + ' SEK'}
          />
        </Box>
      </Box>

      {!!vatData &&
        Object.keys(vatData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((vat) => (
            <Box key={vat} fullWidth direction="row" align="center" justify="space-between">
              <FigmaText
                textKey={Texts.componentsReceiptReceiptPaymentVat}
                text={formatText(getFigmaText(Texts.componentsReceiptReceiptPaymentVat), [vat])}
              />
              <FigmaText
                textKey={Texts.componentsReceiptReceiptPaymentVatValueCss}
                text={vatData[vat].toFixed(2).toString() + ' SEK'}
              />
            </Box>
          ))}
    </ReceiptBox>
  )
}

const ReceiptBox = styled(Box)`
  border: 1px solid #929292;
  border-radius: ${BorderRadixes.regular};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
`

const DescriptionBox = styled(Box)`
  background-color: #f9f9f9;
  border-radius: ${BorderRadixes.small};
`

export default memo(CourseRegistrationReceipt)
