import { useForm } from '../hooks/useForm'
import { memo, useEffect } from 'react'
import { State } from '../hooks/useAppState'
import {
  CourseRegistrationFormValues,
  ValidationSchema,
  ValidationSchemaPartner,
  ValidationSchemaPartnerSsn,
  ValidationSchemaSsn
} from '../helpers/CourseRegistrationStepOneHelper'
import { ClosedContainer } from './StyledComponents'
import usePartnerAndRoles from '../hooks/usePartnerAndRoles'
import CourseRegistrationStepOneClosedContent from './CourseRegistrationStepOneClosedContent'
import CourseRegistrationStepOneOpenedContent from './CourseRegistrationStepOneOpenedContent'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { useClient } from '../hooks/useClient'

type Props = {
  state: State
  activity: Activity
  onNextStep: () => void
  onOpenStepOne: () => void
  registerActiveStep: number
  onSetFormErrors: (hasFormErrors: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSaveRegistrationData: (data: CourseRegistrationFormValues) => void
}

const CourseRegistrationStepOne = ({
  state,
  activity,
  onNextStep,
  onOpenStepOne,
  registerActiveStep,
  onSetFormErrors,
  onSetIsPartnerSenior,
  onSetIsPartnerStudent,
  onSetIsMainUserSenior,
  onSetIsMainUserStudent,
  onSaveRegistrationData
}: Props) => {
  const { hasPartner, setHasPartner, role, partnerRole, changeRole, handleRemovePartner } = usePartnerAndRoles()
  const client = useClient(activity)

  const formProps = useForm<CourseRegistrationFormValues>(
    hasPartner
      ? client?.showSsnInRegistration
        ? ValidationSchemaPartnerSsn
        : ValidationSchemaPartner
      : client?.showSsnInRegistration
        ? ValidationSchemaSsn
        : ValidationSchema,
    {
      defaultValues: {
        name: '',
        phoneNumber: '',
        email: '',
        role: 'leader',
        partnerName: '',
        partnerPhoneNumber: '',
        partnerEmail: '',
        partnerRole: 'follower',
        mainUserSsn: '',
        partnerUserSsn: ''
      },
      mode: 'all',
      reValidateMode: 'onChange'
    }
  )

  useEffect(() => {
    if (Object.keys(formProps.formState.errors).length > 0) {
      onSetFormErrors(true)
    } else {
      onSetFormErrors(false)
    }
  }, [Object.keys(formProps.formState.errors).length])

  const onSubmit = (data: CourseRegistrationFormValues) => {
    console.log(data, 'DATA')
    onSaveRegistrationData({ ...data, role, partnerRole })
    onNextStep()
  }

  return registerActiveStep !== 1 ? (
    <ClosedContainer onClick={registerActiveStep > 1 ? onOpenStepOne : undefined}>
      <CourseRegistrationStepOneClosedContent state={state} />
    </ClosedContainer>
  ) : (
    <CourseRegistrationStepOneOpenedContent
      state={state}
      client={client as Client}
      activity={activity}
      formProps={formProps}
      role={role}
      hasPartner={hasPartner}
      onNextStep={onNextStep}
      setHasPartner={setHasPartner}
      changeRole={changeRole}
      partnerRole={partnerRole}
      onSubmit={onSubmit}
      onSetIsMainUserStudent={onSetIsMainUserStudent}
      onSetIsMainUserSenior={onSetIsMainUserSenior}
      onSetIsPartnerStudent={onSetIsPartnerStudent}
      onSetIsPartnerSenior={onSetIsPartnerSenior}
      handleRemovePartner={handleRemovePartner}
    />
  )
}

export default memo(CourseRegistrationStepOne)
