import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import Box from './Box'
import ClosedActivityDetails from './ClosedActivityDetails'
import FigmaText from './FigmaText'
import ExpandedActivityDetails from './ExpandedActivityDetails'
import { memo } from 'react'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { EIGHT_PX, SIXTEEN_PX, TWELVE_PX } from '../enums/Spacings'
import { Texts } from '../../../pure-js/libs/Texts'
import expandIcon from '/public/expandIcon.svg'
import collapseIcon from '/public/collapseIcon.svg'

type Props = {
  activity: Activity
  expanded: boolean
  onExpand: () => void
  onOpenGoogleMaps: (e: React.MouseEvent<HTMLDivElement>) => void
}

const ActivityDetails = ({ activity, expanded, onExpand, onOpenGoogleMaps }: Props) => {
  return (
    <ActivityBox fullWidth fullPadding spacing={SIXTEEN_PX} onClick={onExpand}>
      <Box fullWidth direction="row" gap={TWELVE_PX}>
        <img src={expanded ? collapseIcon : expandIcon} alt="Toggle" />
        <Box fullWidth direction="column">
          <FigmaText textKey={Texts.componentsExternalCourseExtCourseColName} text={activity.name} />
          {!expanded && <ClosedActivityDetails activity={activity} />}
        </Box>
      </Box>
      {expanded && <ExpandedActivityDetails activity={activity} onOpenGoogleMaps={onOpenGoogleMaps} />}
    </ActivityBox>
  )
}

const ActivityBox = styled(Box)`
  cursor: pointer;
  user-select: none;
  border: 1px solid #1b9323;
  border-radius: ${BorderRadixes.moderate};
`

export default memo(ActivityDetails)
