import styled from '@emotion/styled'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { Texts } from '../../../pure-js/libs/Texts'
import { defaultSpacing, TWELVE_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText, { FigmaTextOrSkeleton } from './FigmaText'
import UserPricing from './PriceContainerUserPricing'

type Props = {
  mainUserPrices: Calculation[]
  partnerUserPrices?: Calculation[]
  hasPartner: boolean
  finalPrice: number
  vatData: Record<number, number>
  state: State
  isLoading: boolean
}

const PriceContainerComponent = ({
  mainUserPrices,
  partnerUserPrices,
  hasPartner,
  finalPrice,
  vatData,
  state,
  isLoading
}: Props) => {
  const vatLabel = getFigmaText(Texts.componentsReceiptReceiptPaymentVat)
  const vatLabelFor = (vat) => vatLabel.replace('xxx', vat)
  return (
    <Container fullPadding spacing={defaultSpacing}>
      <UserPricing
        isLoading={isLoading}
        userPrices={mainUserPrices}
        userName={
          !!state.obfuscatedEnrollment?.mainUser?.name
            ? state.obfuscatedEnrollment?.mainUser?.name
            : (state.name as string)
        }
      />
      {(hasPartner || !!state.obfuscatedEnrollment?.partnerUser?.phone) && (
        <UserPricing
          isLoading={isLoading}
          userPrices={partnerUserPrices || []}
          userName={
            !!state.obfuscatedEnrollment?.partnerUser?.name
              ? state.obfuscatedEnrollment?.partnerUser?.name
              : (state.partnerName as string)
          }
        />
      )}

      {/* Show total discount */}
      {[...mainUserPrices, ...(partnerUserPrices || [])].length > 2 && (
        <TotalDiscountRow
          fullWidth
          direction="row"
          align="center"
          justify="space-between"
          fullPadding
          spacing={TWELVE_PX}
        >
          <FigmaText textKey={Texts.componentsPricingControlSavingsTotal} />
          <FigmaText
            textKey={Texts.componentsPricingControlSavingsTotalValue}
            text={
              [...mainUserPrices, ...(partnerUserPrices || [])]
                .reduce(
                  (sum, calc) =>
                    sum +
                    calc.discounts.reduce((discountSum, discount) => discountSum + (discount.discount || 0), 0) / 100,
                  0
                )
                .toString() + ' SEK'
            }
          />
        </TotalDiscountRow>
      )}

      {/* Show total price */}
      <Box fullWidth direction="row" align="center" justify="space-between" top spacing="22px">
        <FigmaTextOrSkeleton isLoading={isLoading} textKey={Texts.componentsPricingControlPaymentTotalRow} />
        <FigmaTextOrSkeleton
          isLoading={isLoading}
          textKey={Texts.componentsPricingControlCssUserSubtotal}
          text={finalPrice.toString() + ' SEK'}
        />
      </Box>

      {/* Show VAT */}
      {!!vatData &&
        Object.keys(vatData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((vat) => (
            <Box fullWidth direction="row" align="center" justify="space-between" top spacing="10px" key={vat}>
              <FigmaTextOrSkeleton
                isLoading={isLoading}
                textKey={Texts.componentsReceiptReceiptPaymentVat}
                text={vatLabelFor(vat)}
              />
              <FigmaTextOrSkeleton
                isLoading={isLoading}
                textKey={Texts.componentsReceiptReceiptPaymentVatValueCss}
                text={vatData[vat].toString() + ' SEK'}
              />
            </Box>
          ))}
    </Container>
  )
}

const TotalDiscountRow = styled(Box)`
  background-color: #f0f7eb;
  border-radius: 12px;
`

const Container = styled(Box)`
  width: 100%;
  background-color: #efefef;
  border-radius: 12px;
`

export default PriceContainerComponent
