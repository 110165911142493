import styled from '@emotion/styled'
import { Texts } from '../../../pure-js/libs/Texts'
import { getPriceFromCalculations } from '../../../pure-js/libs/calculateLogic/CalculateDiscountHelpers'
import { Calculation, CalculationDiscount } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { EIGHT_PX, FOUR_PX, TWELVE_PX } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import { FigmaTextOrSkeleton, SkeletonCustom } from './FigmaText'
import PriceContainerActivity from './PriceContainerActivity'

type Props = {
  userPrices: Calculation[]
  userName: string
  isLoading: boolean
}

const UserPricing = ({ userPrices, userName, isLoading }: Props) => {
  // Calculate all discounts up front
  const { studentDiscount, seniorDiscount, coupleDiscount, memberDiscount } = userPrices.reduce(
    (acc, calculation) => {
      if (calculation.discounts) {
        calculation.discounts.forEach((discount) => {
          if (discount.type === 'student') {
            acc.studentDiscount += discount.discount / 100
          } else if (discount.type === 'senior') {
            acc.seniorDiscount += discount.discount / 100
          } else if (discount.type === 'couples') {
            acc.coupleDiscount += discount.discount / 100
          } else if (discount.type === 'member') {
            acc.memberDiscount += discount.discount / 100
          }
        })
      }
      return acc
    },
    { studentDiscount: 0, seniorDiscount: 0, coupleDiscount: 0, memberDiscount: 0 }
  )

  const totalPrice = getPriceFromCalculations(userPrices) / 100

  const getDiscount = (discountType: string) => {
    const calculation = userPrices[0]
    if (calculation?.discounts) {
      return calculation.discounts.find((discount) => discount.type === discountType)
    }
    return undefined
  }

  const discountsArray = [
    { type: 'student', amount: studentDiscount, figma: Texts.componentsPricingControlStudentDiscountRow },
    { type: 'senior', amount: seniorDiscount, figma: Texts.componentsPricingControlSeniorDiscountRow },
    { type: 'couples', amount: coupleDiscount, figma: Texts.componentsPricingControlCoupleDiscountRow },
    { type: 'member', amount: memberDiscount, figma: Texts.componentsPricingControlMemberDiscountRow }
  ]

  return (
    <Box fullWidth bottom spacing={EIGHT_PX}>
      <FigmaTextOrSkeleton
        isLoading={isLoading}
        textKey={Texts.componentsPricingControlPricingAttendeeGroup}
        text={userName.toUpperCase()}
      />
      <UserContainer fullWidth fullPadding spacing="16px">
        {/* Iterate through activitites */}
        {Object.entries(
          userPrices
            ?.sort((a, b) => b.finalPrice - a.finalPrice)
            .reduce<Record<string, Calculation[]>>((acc, calculation) => {
              const key = calculation.activity.group || calculation.activity.id
              return {
                ...acc,
                [key]: [...(acc[key] || []), calculation]
              }
            }, {})
        ).map(([_group, calculations]) => (
          <Box fullWidth key={calculations[0].activity.id}>
            <PriceContainerActivity isLoading={isLoading} calculations={calculations} />
            <Box
              fullWidth
              style={{
                borderBottom: '1px solid #eee',
                paddingTop: '4px',
                paddingBottom: '4px'
              }}
            />
          </Box>
        ))}

        {/* Show user discounts  */}
        {isLoading ? (
          <SkeletonCustom>
            <DiscountRow isLoading={isLoading} label={'Student'} amount={10} />
          </SkeletonCustom>
        ) : (
          discountsArray.map((discount) => {
            if (discount.amount > 0) {
              return (
                <DiscountRow
                  isLoading={isLoading}
                  key={discount.type}
                  label={getFigmaText(discount.figma)}
                  amount={discount.amount}
                  discount={getDiscount(discount.type)}
                />
              )
            }
          })
        )}

        {/* Show user total price */}
        <Box alignSelf="flex-end" top spacing={TWELVE_PX} style={{ fontStyle: 'italic' }}>
          <FigmaTextOrSkeleton
            isLoading={isLoading}
            textKey={Texts.componentsPricingControlCssUserSubtotal}
            text={`${String(totalPrice)} SEK`}
          />
        </Box>
      </UserContainer>
    </Box>
  )
}

const DiscountRow = ({
  label,
  amount,
  discount,
  isLoading
}: {
  label: string
  amount: number
  discount?: CalculationDiscount
  isLoading: boolean
}) => (
  <Box fullWidth direction="row" align="center" justify="space-between" top bottom spacing={TWELVE_PX}>
    <FigmaTextOrSkeleton
      isLoading={isLoading}
      textKey={Texts.componentsPricingControlStudentDiscountRow}
      text={label}
    />
    <Box direction="row" align="center" gap={FOUR_PX}>
      {/* Show relative discount value if percentage (eg. -> 10% <- (100 SEK)) */}
      {discount?.discountCalculationType === 'percentage' && (
        <FigmaTextOrSkeleton
          isLoading={isLoading}
          textKey={Texts.componentsPricingControlDiscountValue}
          text={`${discount?.discountValue || 0}%`}
        />
      )}
      <Discount fullPadding spacing={FOUR_PX}>
        <FigmaTextOrSkeleton
          isLoading={isLoading}
          textKey={Texts.componentsPricingControlCssPaymentDiscountAmount}
          text={`-${String(amount)} SEK`}
        />
      </Discount>
    </Box>
  </Box>
)

const UserContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

export const Discount = styled(Box)`
  background-color: #70ab37;
  border-radius: 8px;
  padding: 2px 8px;
  min-width: 80px;
  text-align: right;
  white-space: nowrap;
`

export default UserPricing
