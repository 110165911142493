import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import styled from '@emotion/styled'
import Box from '../components/Box'
import { Client, Activity } from '../../../pure-js/types/GrooverTypes'

import './ActivityDetailPage.css' // Import the CSS file
import RegistrationInfo from '../components/RegistrationInfo'
import RegistrationPrice from '../components/RegistrationPrice'
import RegistrationOccurences from '../components/RegistrationOccurences'
import RegistrationLocation from '../components/RegistrationLocation'
import OtherActivititesTabs from '../components/OtherActivitiesTabs'
import { useEffect, useState } from 'react'
import { getActivity, getClient } from '../libs/DBApiHandler'
import FigmaText from '../components/FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'

export const ActivityDetailPage = () => {
  const { activityId } = useParams<{ activityId: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const [activity, setActivity] = useState<Activity | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [client, setClient] = useState<Client | null>(null)
  const [error, setError] = useState<string | null>(null)
  const isFromPublicPage = location.state?.from === 'PublicActivitiesPage'

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        setIsLoading(true)
        if (!activityId) {
          setError('Activity ID is missing')
          setIsLoading(false)
          return
        }

        const activityData = await getActivity(activityId)
        if (activityData) {
          setActivity(activityData)

          if (activityData.clientId) {
            const clientData = await getClient(activityData.clientId)
            if (clientData) {
              setClient(clientData)
            } else {
              setError('Client not found')
            }
          }
        } else {
          setError('Activity not found')
        }
      } catch (err) {
        setError('Failed to fetch data')
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchActivity()
  }, [activityId])

  const isActivityValid = activity && activity.name

  const handleBack = () => {
    navigate(-1)
  }
  useEffect(() => {
    const sendHeightToParent = () => {
      const height = document.documentElement.scrollHeight || document.body.scrollHeight
      window.parent.postMessage({ type: 'adjustHeight', height }, '*')
      //console.log(`Activity page 📤 Sending height: ${height}px to parent`)
    }

    // Trigger on load and resize
    sendHeightToParent()
    window.addEventListener('resize', sendHeightToParent)

    // Optional: Monitor DOM changes for dynamic content updates
    const observer = new MutationObserver(sendHeightToParent)
    observer.observe(document.body, { childList: true, subtree: true })

    // Cleanup
    return () => {
      window.removeEventListener('resize', sendHeightToParent)
      observer.disconnect()
    }
  }, [])

  return (
    <div className="master-container">
      {isLoading ? (
        <div className="spinner">Loading...</div>
      ) : error ? (
        <p className="error-state">{error}</p>
      ) : isActivityValid ? (
        <>
          <Box id="nav-container" direction="row" justify="space-between" align="center">
            {isFromPublicPage && (
              <BackButton onClick={handleBack} direction="row" align="center" gap="8px">
                <ArrowBack sx={{ fontSize: 20 }} />
                <FigmaText textKey={Texts.externalActivityPageNavigationReturnLabel} />
              </BackButton>
            )}
          </Box>
          <Box direction="row" gap="16px" mobileColumn fullWidth>
            <LeftContainer gap="16px">
              <RegistrationInfo activity={activity} client={client || undefined} />
              <RegistrationPrice activity={activity} client={client || undefined} />
            </LeftContainer>
            <RightContainer gap="16px" fullWidth>
              {client && (
                <>
                  <RegistrationLocation activity={activity} customer={client} />
                  <OtherActivititesTabs activity={activity} client={client} />
                </>
              )}
              <RegistrationOccurences activity={activity} />
            </RightContainer>
          </Box>
        </>
      ) : (
        <p className="empty-state">No course or event found</p>
      )}
    </div>
  )
}

const LeftContainer = styled(Box)`
  flex: 0.6;
`

const RightContainer = styled(Box)`
  flex: 0.4;
`

const BackButton = styled(Box)`
  cursor: pointer;
  color: #333;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  width: fit-content;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  span {
    font-size: 14px;
  }
`
