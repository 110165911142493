import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import Box from './Box'
import { Discount } from '../../../pure-js/types/DiscountTypes'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import RegistrationDiscountDisplay from './RegistrationDiscountDisplay'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { formatText, getFigmaText } from '../libs/TextRepository'

interface ActivityInfoProps {
  activity: Activity
  client?: Client
}

const RegistrationPrice: React.FC<ActivityInfoProps> = ({ activity, client }) => {
  const [matchedDiscounts, setMatchedDiscounts] = useState<Discount[]>([])

  const getMatchedDiscounts = (): Discount[] => {
    if (!client || !activity.discountIds) return []

    return activity.discountIds
      .map((discountId) => client.discounts.find((discount) => discount.id === discountId))
      .filter(Boolean) as Discount[]
  }

  useEffect(() => {
    if (activity && client) {
      setMatchedDiscounts(getMatchedDiscounts())
    }
  }, [activity, client])

  return (
    <SegmentContainer fullWidth>
      <FigmaText textKey={Texts.externalActivityPageSegmentPriceHeader} />
      <Box direction="row" fullWidth justify="flex-start" gap="16px" align="center">
        <PriceText>{activity.price ? `${activity.price / 100} SEK` : 'Gratis'}</PriceText>
        {activity.priceAtDoor ? (
          <PriceTextAtDoor>
            {formatText(getFigmaText(Texts.externalActivityPageSegmentPricePriceAtTheDoor), [
              (activity.priceAtDoor ?? 0) / 100
            ])}
          </PriceTextAtDoor>
        ) : null}
      </Box>

      {client && client.hideDiscountsInPublicPage ? null : (
        <RegistrationDiscountDisplay matchedDiscounts={matchedDiscounts} />
      )}
    </SegmentContainer>
  )
}

const SegmentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
`

const PriceText = styled(Box)`
  margin-top: 8px;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
`

const PriceTextAtDoor = styled(Box)`
  margin-top: 8px;
  font-size: 16px;
  color: #e99243;
  margin-bottom: 16px;
  padding: 8px 6px;
  border-radius: 6px;
  border: 1px solid #e99243;
`
export default RegistrationPrice
