import { useMemo } from 'react'
import {
  calculateDiscounts,
  getPriceFromCalculations
} from '../../../pure-js/libs/calculateLogic/CalculateDiscountHelpers'
import { Activity, Client, VatData } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { getVatFromBruttoPrice } from '../../../pure-js/libs/EnrollmentHelper'

interface CalculationParams {
  activity?: Activity
  client?: Client
  state: State
}

// Pure calculation function
export const calculateDiscountData = ({ activity, client, state }: CalculationParams) => {
  const hasMainUser = (!!state.name && !!state.email && !!state.phoneNumber) || !!state.obfuscatedEnrollment?.mainUser

  const hasPartner =
    (!!state?.partnerName && !!state?.partnerEmail && !!state?.partnerPhoneNumber && !!state?.partnerRole) ||
    !!state.obfuscatedEnrollment?.partnerUser

  const participantsAmount = hasPartner || !!state.obfuscatedEnrollment?.partnerUser?.phone ? 2 : 1

  let mainUserPrices = [] as Calculation[]
  let partnerUserPrices = [] as Calculation[]
  let finalPrice = 0
  let vatData: VatData = {}

  if (activity && client) {
    mainUserPrices = calculateDiscounts(
      !!state.obfuscatedEnrollment?.activities ? state.obfuscatedEnrollment?.activities : state?.selectedActivities,
      client,
      {
        // @ts-ignore
        isStudent: state.obfuscatedEnrollment?.discountUserInput?.mainUser?.isStudent || state.isMainUserStudent,
        // @ts-ignore
        isSenior: state.obfuscatedEnrollment?.discountUserInput?.mainUser?.isSenior || state.isMainUserSenior,
        // @ts-ignore
        userActivePeriods:
          // @ts-ignore
          state.obfuscatedEnrollment?.discountUserInput?.mainUser?.userActivePeriods || state.mainUserActivePeriods,
        participantsAmount
      }
    )

    if (hasPartner) {
      partnerUserPrices = calculateDiscounts(
        !!state.obfuscatedEnrollment?.activities ? state.obfuscatedEnrollment?.activities : state?.selectedActivities,
        client,
        {
          // @ts-ignore
          isStudent: state.obfuscatedEnrollment?.discountUserInput?.partnerUser?.isStudent || state.isPartnerStudent,
          // @ts-ignore
          isSenior: state.obfuscatedEnrollment?.discountUserInput?.partnerUser?.isSenior || state.isPartnerSenior,
          // @ts-ignore
          userActivePeriods:
            // @ts-ignore
            state.obfuscatedEnrollment?.discountUserInput?.partnerUser?.userActivePeriods ||
            state.mainUserActivePeriods,
          participantsAmount
        }
      )
    }

    const calculateUserTotal = (prices: Calculation[]) => {
      const subtotal = getPriceFromCalculations(prices) / 100
      finalPrice += subtotal
      if (!vatData[activity.vat]) vatData[activity.vat] = 0
      vatData[activity.vat] += getVatFromBruttoPrice(subtotal, activity.vat)
      vatData[activity.vat] = Math.round(vatData[activity.vat] * 100) / 100
    }

    calculateUserTotal(mainUserPrices)
    if (partnerUserPrices) calculateUserTotal(partnerUserPrices)
  }

  const finalPriceMainUser = getPriceFromCalculations(mainUserPrices) / 100
  const finalPricePartnerUser = getPriceFromCalculations(partnerUserPrices) / 100

  return {
    hasMainUser,
    hasPartner,
    mainUserPrices,
    partnerUserPrices,
    finalPrice,
    finalPriceMainUser,
    finalPricePartnerUser,
    vatData
  }
}

const useDiscountCalculations = (params: CalculationParams) => {
  return useMemo(
    () => calculateDiscountData(params),
    [
      params.activity,
      params.client,
      params.state,
      params.state.mainUserActivePeriods,
      params.state.partnerUserActivePeriods
    ]
  )
}

export default useDiscountCalculations
