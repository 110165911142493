import { Discount } from '../../types/DiscountTypes'
import { fixMathRounding, getActivitiesWithDiscountId, getPriceFromCalculation } from './CalculateDiscountHelpers'
import {
  CalculateDiscountLogic,
  Calculation,
  CalculationDiscount,
  CalculationUserInput
} from './CalculateDiscountTypes'

export class SeniorDiscountLogic implements CalculateDiscountLogic {
  type = 'senior'
  discountDefinition: Discount

  constructor(discount: Discount) {
    this.discountDefinition = discount
  }

  chooseEligible(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    if (!userInput.isSenior) return []

    return getActivitiesWithDiscountId(previousCalculations, this.discountDefinition.id)
  }

  calculate(previousCalculations: Calculation[], userInput: CalculationUserInput) {
    const eligibleActivities = this.chooseEligible(previousCalculations, userInput)

    previousCalculations.map((calculation) => {
      if (!eligibleActivities.includes(calculation.activity.id)) return
      const activityPrice = getPriceFromCalculation(calculation)
      const discountAmount =
        this.discountDefinition.calculationType === 'amount'
          ? this.discountDefinition.value // amount discount
          : fixMathRounding(activityPrice * (this.discountDefinition.value / 100)) // percentage discount
      const studentDiscount: CalculationDiscount = {
        type: this.type,
        name: this.discountDefinition.name,
        id: this.discountDefinition.id,
        input: activityPrice,
        discount: discountAmount,
        output: fixMathRounding(activityPrice - discountAmount),
        discountCalculationType: this.discountDefinition.calculationType,
        discountValue: this.discountDefinition.value,
        discountGroup: this.discountDefinition.discountGroup
      }
      calculation.discounts = calculation.discounts || []
      calculation.discounts.push(studentDiscount)
      calculation.finalPrice = studentDiscount.output
    })

    return previousCalculations
  }
}
