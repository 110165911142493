import styled from '@emotion/styled'
import Box from '../Box'
import { ActivityCardProps } from '../../types/activity.types'
import { ActivityMetadata } from './ActivityMetadata'
import { getActivityMetadata } from '../../config/activity.config'
import { getThemeConfig } from '../../themeConfig'

export const ActivityCard: React.FC<ActivityCardProps> = ({ activity, onClick }) => (
  <StyledActivityCard onClick={onClick} fullWidth gap="0px" $type={activity.type} key={`activity-${activity.id}`}>
    {activity.bannerUrl ? <BannerImage src={activity.bannerUrl} alt={activity.name} /> : null}
    <ActivityCardContent>
      <ActivityTitle>{activity.name}</ActivityTitle>
      <ActivityMetadata items={getActivityMetadata(activity)} />
    </ActivityCardContent>
  </StyledActivityCard>
)

const getBgColor = (type?: string) => {
  const themeConfig = getThemeConfig()
  console.log(themeConfig)
  switch (type) {
    case 'Course':
      return themeConfig.courseBGColor
    case 'Occasion':
      return themeConfig.occationBGColor
    default:
      return themeConfig.otherBGColor
  }
}

const BannerImage = styled.img`
  border-radius: 8px;
`
const StyledActivityCard = styled(Box)<{ $type?: string }>`
  color: ${() => getThemeConfig().textColor} !important;
  border-radius: 8px;
  border: 1px solid ${() => getThemeConfig().activityBoxBorder};
  cursor: pointer;
  background: ${({ $type }) => getBgColor($type)};
  &:hover {
    background: ${({ $type }) => {
      const bgColor = getBgColor($type)
      return bgColor === '#fff' ? getThemeConfig().activityHover : `${bgColor}cc`
    }};
  }
`

const ActivityCardContent = styled(Box)`
  padding: 8px 16px;
  margin-bottom: 8px;
`

const ActivityTitle = styled.p`
  color: ${() => getThemeConfig().textColor};
`
