import Box from '../components/Box'
import welcome from '/public/welcome.png'
import { FORTY_EIGHT_PX } from '../enums/Spacings'

const SignInPage: React.FC = () => {
  return (
    <Box fullWidth fullHeight align="center" top gap={FORTY_EIGHT_PX}>
      <img src={welcome} width={500} height={550} style={{ borderRadius: 20 }} />
      <h1>Welcome to Coursely!</h1>
    </Box>
  )
}

export const RootPage = SignInPage
export default SignInPage
