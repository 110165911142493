import { Discount } from '../../types/DiscountTypes'
import { Activity, Client } from '../../types/GrooverTypes'
import { CalculateDiscountLogic, Calculation, CalculationUserInput } from './CalculateDiscountTypes'
import { CombinedDiscountLogic } from './CombinedDiscountLogic'
import { CouplesDiscountLogic } from './CouplesDiscountLogic'
import { MemberDiscountLogic } from './MemberDiscountLogic'
import { MultiCourseMatrixDiscountLogic } from './MultiCourseMatrixDiscountLogic'
import { SeniorDiscountLogic } from './SeniorDiscountLogic'
import { StudentDiscountLogic } from './StudentDiscountLogic'
import { ZeroDiscountLogic } from './ZeroDiscountLogic'

// we operate on price in cents, and want to have no decimals
export const fixMathRounding = (n: number): number => Math.round(n / 100) * 100

export const getPriceFromCalculation = (calculation: Calculation) => {
  // if no discounts, return activity price
  if (calculation.discounts.length === 0) return calculation.activity.price
  // else return last discount price
  return calculation.discounts[calculation.discounts.length - 1].output
}

export const getPriceFromCalculations = (calculations: Calculation[]) => {
  return calculations.reduce((acc, calculation) => {
    return fixMathRounding(acc + getPriceFromCalculation(calculation))
  }, 0)
}

export const createInitialCalculations = (activities: Activity[]): Calculation[] => {
  // get initial calculations with no discounts
  return activities.map((activity) => {
    return { activity, discounts: [], finalPrice: activity.price }
  })
}

export const getActivitiesWithDiscountId = (calculations: Calculation[], discountId: string): string[] => {
  return calculations
    .map((calculation) => (calculation.activity.discountIds.includes(discountId) ? calculation.activity.id : null))
    .filter((activityId): activityId is string => activityId !== null)
}

// returns class instance that implements given discount logic
export const discountLogicFactory = (discount: Discount): CalculateDiscountLogic => {
  if (discount.type === 'student') return new StudentDiscountLogic(discount)
  if (discount.type === 'senior') return new SeniorDiscountLogic(discount)
  if (discount.type === 'couples') return new CouplesDiscountLogic(discount)
  if (discount.type === 'combined') return new CombinedDiscountLogic(discount)
  if (discount.type === 'multi_course_matrix') return new MultiCourseMatrixDiscountLogic(discount)
  if (discount.type === 'member') return new MemberDiscountLogic(discount)
  // here? unsupported discount type; will use zero discount logic
  console.warn(`discountLogicFactory: discount type "${discount.type}" is not implemented; will use ZeroDiscountLogic`)
  return new ZeroDiscountLogic()
}

const getBestDiscount = (activities: Activity[], client: Client, userInput: CalculationUserInput): Calculation[] => {
  const initial = createInitialCalculations(activities)

  const calculateSingleDiscount = (
    calculations: Calculation[],
    discount: Discount,
    userInput: CalculationUserInput
  ): Calculation[] => {
    const logic = discountLogicFactory(discount)
    const result = logic.calculate([...calculations], userInput)

    // Ensure no calculation results in negative price
    return result.map((calculation) => {
      if (calculation.discounts.length > 0) {
        // Get last discount
        const lastDiscount = calculation.discounts[calculation.discounts.length - 1]

        // If output would be negative, adjust the discount to make output 0
        if (lastDiscount.output < 0) {
          lastDiscount.output = 0
          lastDiscount.discount = lastDiscount.input // Discount is at most the input price
        }

        // Update finalPrice
        calculation.finalPrice = lastDiscount.output
      }
      return calculation
    })
  }

  // Fing best combinable discount
  const findBestValueDiscount = (discountGroup: Discount[], groupName?: string): Discount | null => {
    return (
      discountGroup.reduce((best: { discount: Discount; amount: number } | null, d) => {
        const calculationFreeze = JSON.parse(JSON.stringify(currentCalculations)) // freeze current calculations...
        const calcs = calculateSingleDiscount(calculationFreeze, d, userInput)

        return calcs.reduce((innerBest, activityCalc) => {
          const discountCalcs = groupName
            ? activityCalc.discounts.filter((dd) => dd.discountGroup === groupName)
            : activityCalc.discounts
          if (discountCalcs.length > 0) {
            const discountAmount = activityCalc.discounts.find((ddd) => ddd.id == d.id)?.discount || 0
            if (!innerBest || discountAmount > innerBest.amount) {
              return { discount: d, amount: discountAmount }
            }
          }
          return innerBest
        }, best)
      }, null)?.discount ?? null
    )
  }

  let currentCalculations: Calculation[] = [...initial]

  // Prepare step 1: additional logic for combinable discounts
  const combinableDiscountsAll = client.discounts.filter((d) => d.canBeCombined)
  const combinableDiscounts: Discount[] = []

  // Apply each combinable discount in sequence
  const processedGroup = new Set<string>()
  combinableDiscountsAll.forEach((discount) => {
    // check if discount needs additional selection if has discountGroup
    if (discount.discountGroup) {
      // skip group if already processed
      if (processedGroup.has(discount.discountGroup)) return

      // if this group is excluded, then...
      const discountGroup = combinableDiscountsAll.filter((d) => d.discountGroup === discount.discountGroup)
      if (discountGroup.some((d) => d.excludeGroupInCombination === discount.discountGroup)) {
        // ... find best discount in this group
        const bestDiscount = findBestValueDiscount(discountGroup, discount.discountGroup)

        if (bestDiscount) {
          processedGroup.add(discount.discountGroup)
          combinableDiscounts.push(bestDiscount)
          return
        }
      }
    }

    combinableDiscounts.push(discount)
  })

  // Step 1: Process combinable discounts
  combinableDiscounts.forEach((discount) => {
    calculateSingleDiscount(currentCalculations, discount, userInput)
  })

  // Step 2: Process non-combinable discounts
  const nonCombinableDiscounts = client.discounts.filter((d) => !d.canBeCombined)
  let bestNonCombinable = findBestValueDiscount(nonCombinableDiscounts)
  if (bestNonCombinable) {
    calculateSingleDiscount(currentCalculations, bestNonCombinable, userInput)
  }

  return currentCalculations
}

export const calculateDiscounts = (
  activities: Activity[],
  client: Client,
  userInput: CalculationUserInput
): Calculation[] => {
  return getBestDiscount(activities, client, userInput)
}
