import { useEffect, useId, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Input } from '@mui/material'

import Box from '../components/Box'
import { createSwishPaymentIntent } from '../libs/CloudFunctionsApiHandler'
import useAppState from '../hooks/useAppState'
import { useSwishPaymentIntentStatus } from '../libs/DBApiHandler'

const SwishPayPage = () => {
  const SWISH_PAYMENT_STATUS_PENDING = 'PENDING'
  const { enrollmentId } = useParams<{ enrollmentId: string }>()
  const { state } = useAppState()

  const id = useId()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [phone, setPhone] = useState('')
  const [swishInstructionUUID, setSwishInstructionUUID] = useState('')
  const [swishPaymentIntentStatus, setSwishPaymentIntentStatus] = useState(SWISH_PAYMENT_STATUS_PENDING)

  // monitor swish payment status
  const swishPaymentIntent = useSwishPaymentIntentStatus(swishInstructionUUID)
  console.log('swishPaymentIntent', swishPaymentIntent)

  useEffect(() => {
    if (!swishPaymentIntent) return
    if (swishPaymentIntent.status == 'success') {
      // @ts-ignore
      const { status } = swishPaymentIntent.data
      if (status) {
        setSwishPaymentIntentStatus(status)
      }
    }
  }, [swishPaymentIntent.data])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsError(false)
    setIsLoading(true)
    setSwishPaymentIntentStatus(SWISH_PAYMENT_STATUS_PENDING)
    const response = await createSwishPaymentIntent({ enrollmentId: enrollmentId || '', payerPhone: phone }, state)
    console.log('createSwishPaymentIntent response', response)
    if ('isError' in response) {
      setIsError(true)
      setIsLoading(false)
      return
    }
    setSwishInstructionUUID(response.instructionUUID)
    setIsLoading(false)
  }

  if (!enrollmentId) return <h1>No enrollment ID</h1>

  return (
    <Box width="650px" fullHeight>
      <h1>Swish pay for {enrollmentId}</h1>
      {enrollmentId && (
        <>
          {isLoading && <h2>Preparing payment...</h2>}
          {!isLoading && swishInstructionUUID && swishPaymentIntentStatus !== 'PAID' && (
            <>
              <h2>Confirm payment in your Swish mobile app</h2>
              <h3>Instruction UUID: {swishInstructionUUID}</h3>
              <h4>status: {swishPaymentIntentStatus}</h4>
            </>
          )}
          {!isLoading && swishInstructionUUID && swishPaymentIntentStatus == 'PAID' && <h2>Thank you for purchase!</h2>}
          {!isLoading && swishInstructionUUID == '' && (
            <form onSubmit={handleSubmit}>
              <div>
                <label>
                  Phone
                  <Input id={id} name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}></Input>
                </label>
                <button onClick={handleSubmit}>Pay</button>
              </div>
            </form>
          )}
          {isError && <h3>Error initializing payment</h3>}
        </>
      )}
    </Box>
  )
}

export default SwishPayPage
