import { Language, TextKeyWithOnlyText } from '../../../pure-js/types/Antiloop.js'
import { Texts } from '../../../pure-js/libs/Texts.js'
import { getLanguageCode } from '../hooks/useDefaultLanguage.js'
import { useContext } from 'react'
import { FeatureFlagsContext, FlagNames } from './FeatureFlags.js'

const XXX = 'xxx'
export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: string
}

export const getFigmaText = (textKey: TextKeyWithOnlyText, language: Language = getLanguageCode()): string => {
  const { getFlagValue } = useContext(FeatureFlagsContext)
  const defaultLanguage = getFlagValue<Language>(FlagNames.LANG_USER) || 'sv'

  switch (language) {
    // case sv:
    //   return textKey.texts?.sv || textKey.characters || ''
    default:
      return textKey.texts[defaultLanguage] || textKey.characters || ''
  }
}

export const getDiscountName = (type: string) => {
  if (!type) {
    return 'Unknown discount'
  }
  switch (type) {
    case 'student':
      return getFigmaText(Texts.componentsPricingControlStudentDiscountRow)
    case 'senior':
      return getFigmaText(Texts.componentsPricingControlSeniorDiscountRow) //'senior'
    case 'couples':
      return getFigmaText(Texts.componentsPricingControlCoupleDiscountRow)
    case 'multi_course_matrix':
      return getFigmaText(Texts.componentsPricingControlBundleDiscountRow)
    case 'combined':
      return getFigmaText(Texts.componentsPricingControlExtentionDiscountRow) //'Extended course'
    case 'member':
      return getFigmaText(Texts.componentsPricingControlMemberDiscountRow) //'Extended course'
    default:
      return 'Unknown discount'
  }
}

export const formatText = (
  text: string,
  params: Printable[],
  { boldParams, boldParamsIndex, splitCharacter = XXX }: Options = {}
): string => {
  const _params = params
    .map((p) => p?.toString() || '')
    .map((p, i) => {
      if (boldParams) return makeBold(p)
      if (boldParamsIndex === i) return makeBold(p)
      return p
    })

  return text
    .split(splitCharacter)
    .reduce((a, b, i) => ({ res: a.res + b + (_params[a.i] || '').toString(), i: i + 1 }), { res: '', i: 0 }).res
}

export function makeBold(p: string): string {
  return `<b>${p}</b>`
}
