import styled from '@emotion/styled'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useRef, useState } from 'react'
import { Calculation } from '../../../pure-js/libs/calculateLogic/CalculateDiscountTypes'
import { obfuscateName } from '../../../pure-js/libs/EnrollmentHelper'
import { getQRCode, QRRequestVisit } from '../../../pure-js/libs/QRHelper'
import { Activity, EnrollmentWithPayment, Payment, PaymentMethod } from '../../../pure-js/types/GrooverTypes'
import CourseRegistrationReceipt from '../components/CourseRegistrationReceipt'
import QRCodeElement from '../components/QRCodeElement'
import { State } from '../hooks/useAppState'

const enum MenuItem {
  RegistrationReceipt = 'REGISTRATION_RECEIPT',
  RegistrationReceiptObfuscated = 'REGISTRATION_RECEIPT_OBFUSCATED',
  QRCode = 'QR_CODE'
}

type RegistrationReceiptProps = {
  obfuscated?: boolean
}

const PreviewEmailRegistrationReceipt = ({ obfuscated }: RegistrationReceiptProps) => {
  const receiptRef = useRef<any>(null)
  const mainCalcs: Calculation[] = [
    {
      activity: {
        id: 'xxxx1',
        name: 'Activity 1',
        type: 'Course',
        status: 'active',
        createdAt: new Date().toString()
      } as Activity,
      discounts: [
        {
          type: 'discount',
          name: 'Discount 1',
          id: 'xxxx1',
          input: 100,
          discount: 10,
          output: 90,
          discountCalculationType: 'amount',
          discountValue: 10
        }
      ],
      finalPrice: 100
    }
  ]
  const partnerCalcs: Calculation[] = [
    {
      activity: {
        id: 'xxxx1',
        name: 'Activity 2',
        type: 'Course',
        status: 'active',
        createdAt: new Date().toString()
      } as Activity,
      discounts: [
        {
          type: 'discount',
          name: 'Discount 1',
          id: 'xxxx1',
          input: 100,
          discount: 10,
          output: 90,
          discountCalculationType: 'amount',
          discountValue: 10
        }
      ],
      finalPrice: 200
    }
  ]
  const enrollment = {
    paymentDetails: {
      paymentRef: '1234567890-ABCD-EFGH',
      paymentOperator: 'Coursely',
      paymentDate: new Date().toString(),
      paymentInfo: 'card-visa-1234'
    },
    payments: [
      {
        id: 'xxxx1',
        createdAt: new Date().toString(),
        paymentRef: '1234567890-ABCD-EFGH',
        paymentOperator: 'Coursely',
        paymentDate: new Date().toString(),
        paymentInfo: 'card-visa-1234',
        amount: 100,
        source: 'stripe'
      } as any as Payment
    ]
  } as EnrollmentWithPayment
  const finalPrice =
    mainCalcs.reduce((acc, calc) => acc + calc.finalPrice, 0) +
    partnerCalcs.reduce((acc, calc) => acc + calc.finalPrice, 0)

  const state = { name: 'John Doe', partnerName: 'Alice Cooper', paymentMethod: PaymentMethod.Stripe } as State
  if (obfuscated) {
    const mu = {
      name: obfuscateName(state.name),
      id: 'dummy-id',
      email: 'dumm-email',
      phone: 'phone',
      type: 'leader'
    } as const
    const pu = {
      name: obfuscateName(state.partnerName!),
      id: 'dummy-id',
      email: 'dumm-email',
      phone: 'phone',
      type: 'leader'
    } as const
    state.obfuscatedEnrollment = {} as EnrollmentWithPayment
    state.obfuscatedEnrollment.mainUser = mu
    state.obfuscatedEnrollment.partnerUser = pu
    state.obfuscatedEnrollment.paymentDetails = enrollment.paymentDetails
  }

  const handleDownloadPDF = async () => {
    const element = receiptRef.current
    const canvas = await html2canvas(element, { scale: 2 })
    const imgData = canvas.toDataURL('image/png')

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    })

    const imgWidth = 190 // A4 width in mm minus margins
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
    pdf.save(`TEST_Course_Registration_Receipt__${dayjs(new Date()).format('YYYYMMDDHHmm')}.pdf`)
  }

  return (
    <div>
      <h2>Registration - receipt</h2>
      <p>
        <button onClick={handleDownloadPDF}>Download PDF</button>
      </p>
      <div>
        obfuscated: {obfuscated ? 'true' : 'false'}
        <CourseRegistrationReceipt
          ref={receiptRef}
          mainUserCalculations={mainCalcs}
          partnerUserCalculations={partnerCalcs}
          state={state}
          vatData={{ 25: finalPrice * 0.25 }}
          finalPrice={finalPrice}
          obfuscatedEnrollment={obfuscated ? state.obfuscatedEnrollment : enrollment}
        />
      </div>
    </div>
  )
}

const QRCodeTest = () => {
  const [qrCode, setQRCode] = useState('')

  const prepareQRCode = (value: string) => {
    const t = getQRCode({
      type: 'visit',
      data: { enrollmentId: value }
    } as QRRequestVisit)

    setQRCode(t)
  }

  return (
    <div>
      <h2>QR code</h2>
      <p>
        QR type: <ChipBox>visit</ChipBox>
        <br />
        enrollmentId: <input type="text" size={15} onChange={(e) => prepareQRCode(e.target.value)} />
        <br />
        <br />
        {qrCode && <QRCodeElement value={qrCode} showText={true} />}
      </p>
    </div>
  )
}

const SettingsEmailsPage: React.FC = () => {
  const [menuItem, setMenuItem] = useState<MenuItem>()

  const handleMenuItemClick = (item: MenuItem) => {
    setMenuItem(item)
  }
  const isMenuItemSelected = (item: MenuItem) => (menuItem === item ? 'selected' : '')
  const menu = {
    [MenuItem.RegistrationReceipt]: 'Registration - receipt',
    [MenuItem.RegistrationReceiptObfuscated]: 'Registration - receipt (obfuscated)',
    [MenuItem.QRCode]: 'QR code'
  }
  return (
    <div>
      <h1>Settings - Emails</h1>
      <p>Preview & test download email & PDF attachments.</p>
      <MenuList>
        {(Object.keys(menu) as MenuItem[]).map((key: MenuItem) => (
          <li key={key} className={isMenuItemSelected(key)} onClick={() => handleMenuItemClick(key)}>
            {menu[key]}
          </li>
        ))}
      </MenuList>
      {menuItem === MenuItem.RegistrationReceipt ? <PreviewEmailRegistrationReceipt /> : null}
      {menuItem === MenuItem.RegistrationReceiptObfuscated ? (
        <PreviewEmailRegistrationReceipt obfuscated={true} />
      ) : null}
      {menuItem === MenuItem.QRCode ? <QRCodeTest /> : null}
    </div>
  )
}

const MenuList = styled.ul`
  cursor: pointer;
  color: blue;
  & :hover {
    text-decoration: underline;
  }

  & li.selected {
    font-weight: bold;
  }
`

const ChipBox = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 16px;
  background-color: #e0e0e0;
  // font-size: 0.8125rem;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.87);
  margin: 4px;
  border: none;
  &:hover {
    background-color: #cacaca;
  }
`

export default SettingsEmailsPage
