import { useState } from 'react'
import { CourseRegistrationFormValues } from '../helpers/CourseRegistrationStepOneHelper'
import { DanceRoles } from '../../../pure-js/types/DanceRoles'

const usePartnerAndRoles = () => {
  const [hasPartner, setHasPartner] = useState(false)
  const [role, setRole] = useState<DanceRoles>('leader')
  const [partnerRole, setPartnerRole] = useState<DanceRoles>('follower')

  const changeRole = (isPartnerRole: boolean, newRole: DanceRoles) => {
    if (isPartnerRole) {
      // Update the partner's role
      setPartnerRole(newRole)

      // Update the main user's role only if:
      // - The new partner role is not 'both'
      // - The main user's current role is not 'both'
      setRole((currentRole) => {
        if (newRole === 'both' || currentRole === 'both') {
          return currentRole // Do not change if either is 'both'
        }
        return newRole === 'leader' ? 'follower' : 'leader'
      })
    } else {
      // Update the main user's role
      setRole(newRole)

      // Update the partner's role only if:
      // - The new main user role is not 'both'
      // - The partner's current role is not 'both'
      setPartnerRole((currentPartnerRole) => {
        if (newRole === 'both' || currentPartnerRole === 'both') {
          return currentPartnerRole // Do not change if either is 'both'
        }
        return newRole === 'leader' ? 'follower' : 'leader'
      })
    }
  }

  const handleRemovePartner = (formProps: any) => {
    setHasPartner(false)
    const fieldsToReset = {
      partnerName: '',
      partnerPhoneNumber: '',
      partnerEmail: '',
      partnerRole: 'follower'
    }
    Object.entries(fieldsToReset).forEach(([field, value]) => {
      formProps.setValue(field as keyof CourseRegistrationFormValues, value)
    })
  }

  return { hasPartner, setHasPartner, role, partnerRole, changeRole, handleRemovePartner }
}

export default usePartnerAndRoles
