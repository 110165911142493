import styled from '@emotion/styled'
import { Tooltip, styled as muiStyled, TooltipProps } from '@mui/material'
import { ElementType } from 'react'
import Box from '../Box'
import { ActivityMetadataItem } from '../../types/activity.types'
import { MOBILE_BREAKPOINT } from '../../config/activity.config'
import { getThemeConfig } from '../../themeConfig'

export const ActivityMetadata = ({ items }: { items: ActivityMetadataItem[] }) => (
  <MetadataContainer direction="row" fullWidth justify="flex-end">
    {items
      .filter((item) => item.value)
      .map((item, index) => {
        const IconComponent: ElementType = item.icon
        const Wrapper = item.hideOnMobile ? MetadataItemHideOnMobile : MetadataItem

        return (
          <StyledTooltip key={index} title={item.label} arrow placement="top">
            <Wrapper direction="row" align="center" gap="4px">
              <IconComponent sx={{ color: `${getThemeConfig().textColor}CC`, height: '16px' }} />
              <span style={{ opacity: 0.8 }}>{item.value}</span>
            </Wrapper>
          </StyledTooltip>
        )
      })}
  </MetadataContainer>
)
const StyledTooltip = muiStyled((props: TooltipProps) => <Tooltip {...props} classes={{ popper: props.className }} />)`
  & .MuiTooltip-tooltip {
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 8px 12px;
  }
  & .MuiTooltip-arrow {
    color: white;
  }
`
const MetadataContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  justify-content: flex-start;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-content: flex-start;
  }
`

const MetadataItem = styled(Box)`
  display: flex;
  color: ${() => getThemeConfig().textColor};
  align-items: center;
`

const MetadataItemHideOnMobile = styled(MetadataItem)`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`
