import { memo } from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import emptyActivity from '/public/emptyActivity.svg'
import emptyStateImage from '/public/emptyStateImage.svg'
import { Client } from '../../../pure-js/types/GrooverTypes'

const DeletedEnrollment = ({ client }: { client?: Client }) => {
  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <img src={emptyActivity} width={375} height={150} alt="Missing enrollment" />
      <img src={emptyStateImage} width={375} height={150} alt="Empty state" />
      <Box top>
        <FigmaText textKey={Texts.adminGeneralInfoTextsDeletedEnrollmentInfo} />
        {client?.contactEmail}
      </Box>
    </Box>
  )
}

export default memo(DeletedEnrollment)
