import { memo, useCallback, useEffect, useState } from 'react'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import { styled } from '@mui/material'
import { ContinueButton } from './StyledComponents'
import { COURSELY_PRIVACY_POLICY, COURSELY_REGISTRATION_RULES } from '../enums/HardCodedValues'
import Spinner from './Spinner'
import {
  Activity,
  EnrollmentPublic,
  EnrollmentRequestV2,
  EnrollmentStatus,
  PaymentMethod
} from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { saveUserRegistrationV2 } from '../libs/CloudFunctionsApiHandler'
import iconCompleted from '/public/iconCompleted.svg'
import iconArrow from '/public/iconArrow.svg'
import checkIcon from '/public/checkIcon.svg'
import { EIGHT_PX, FOUR_PX, SIXTEEN_PX } from '../enums/Spacings'
import { useEnrollmentPublic } from '../libs/DBApiHandler'
import { BorderRadixes } from '../enums/BorderRadixes'

type Props = {
  state: State
  activity: Activity
  onNextStep: () => void
  onSetIsRegistrationSubmitted: () => void
  onSetEnrollmentId: (enrollmentId: string) => void
}

const CourseRegistrationStepThreeOpenedContent = ({
  state,
  activity,
  onNextStep,
  onSetIsRegistrationSubmitted,
  onSetEnrollmentId
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [enrollmentStatus, setEnrollmentStatus] = useState<EnrollmentPublic['status']>()

  const enrollmentPublic = useEnrollmentPublic(
    !state.enrollmentId ? state.obfuscatedEnrollment?.id : state.enrollmentId
  )

  const hasPartner = state.partnerName && state.partnerEmail && state.partnerPhoneNumber && state.partnerRole
  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber
  const isRegistrationCompleted = state.isRegistrationCompleted || state.isRegistrationSubmitted
  const isCheckboxDisabled = !isCheckboxChecked || !hasMainUser || state.hasFormErrors

  const handleCheckboxChange = useCallback(() => {
    if (!isRegistrationCompleted) {
      setIsCheckboxChecked((prev) => !prev)
    }
  }, [isRegistrationCompleted])

  const handleContinue = useCallback(() => {
    if (state.isRegistrationSubmitted) return onNextStep()
    if (isCheckboxDisabled || !activity.clientId) return

    setIsLoading(true)

    const mainUserRegistration: EnrollmentRequestV2 = {
      clientId: activity.clientId,
      mainUser: {
        name: state.name,
        email: state.email.trim(),
        phone: state.phoneNumber,
        type: state.role,
        ssn: state.mainUserSsn.trim()
      },
      activityIds: state.selectedActivities.map((activity) => activity.id),
      paymentInput: [{ user: 'mainUser', method: PaymentMethod.BankTransfer }],
      discountUserInput: {
        coursesAmount: state.selectedActivities.length,
        participantsAmount: 1,
        mainUser: {
          isStudent: state.isMainUserStudent,
          isSenior: state.isMainUserSenior,
          userActivePeriods: state.mainUserActivePeriods
        }
      },
      comment: state.enrollmentComment,
      env: process.env.NODE_ENV || 'local'
    }

    const registrationData: EnrollmentRequestV2 = hasPartner
      ? {
          ...mainUserRegistration,
          partnerUser: {
            name: state.partnerName!,
            email: state.partnerEmail?.trim() ?? '', // safe trim so TS is not complaining; email is always set there
            phone: state.partnerPhoneNumber!,
            type: state.partnerRole!,
            ssn: state.partnerUserSsn.trim()
          },
          discountUserInput: {
            ...mainUserRegistration.discountUserInput,
            participantsAmount: 2,
            partnerUser: {
              isStudent: state.isPartnerStudent,
              isSenior: state.isPartnerSenior,
              userActivePeriods: state.partnerUserActivePeriods
            }
          }
        }
      : mainUserRegistration

    saveUserRegistrationV2(registrationData, state)
      .then((response) => {
        if (response.enrollmentId) {
          onSetEnrollmentId(response.enrollmentId)
        }
      })
      .catch(console.error)
      .finally(() => {
        onSetIsRegistrationSubmitted()
        setIsLoading(false)
      })
  }, [state, activity, isCheckboxDisabled, hasPartner, onNextStep, onSetIsRegistrationSubmitted, onSetEnrollmentId])

  const onOpenExternalLink = (e: React.MouseEvent, url: string) => {
    e.stopPropagation()
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!enrollmentPublic.data) return
    if (enrollmentPublic.status == 'success') {
      const { status } = enrollmentPublic.data as EnrollmentPublic
      if (!!status) {
        setEnrollmentStatus(status)
      }
    }
  }, [state.enrollmentId, enrollmentPublic.data])

  useEffect(() => {
    if (enrollmentStatus === EnrollmentStatus.Confirmed) {
      onNextStep()
    }
  }, [enrollmentStatus])

  return (
    <>
      <Box gap={SIXTEEN_PX}>
        <Box direction="row" gap={SIXTEEN_PX}>
          {state.isRegistrationCompleted ||
            (!!state.obfuscatedEnrollment?.mainUser && <img src={iconCompleted} width={24} height={24} />)}
          <FigmaText
            textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
            text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSummaryAndApproval)}
          />
        </Box>
      </Box>
      {enrollmentStatus !== EnrollmentStatus.Pending ? (
        <>
          <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalPositive} />
          <Box gap={EIGHT_PX}>
            {!!activity.approvalRequired && activity.approvalRequired && (
              <Box direction="row" align="center" gap={EIGHT_PX}>
                <img src={checkIcon} width={20} height={16} alt="Check icon" />
                <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalStep1} />
              </Box>
            )}
            <Box direction="row" align="center" gap={EIGHT_PX}>
              <img src={checkIcon} width={20} height={16} alt="Check icon" />
              <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalStep2} />
            </Box>
            <Box direction="row" align="center" gap={EIGHT_PX}>
              <img src={checkIcon} width={20} height={16} alt="Check icon" />
              <FigmaText textKey={Texts.registrationStep4ApprovalInfoApprovalStep3} />
            </Box>
          </Box>
          <Link fullWidth direction="row" align="center" gap={EIGHT_PX} onClick={handleCheckboxChange}>
            <input
              type="checkbox"
              id="accountApproval"
              checked={isCheckboxChecked || !!state.obfuscatedEnrollment?.mainUser}
              disabled={state.isRegistrationCompleted || state.isRegistrationSubmitted}
            />
            <LinkBox fullWidth direction="row" align="center" gap={FOUR_PX}>
              <FigmaText textKey={Texts.registrationStep4ApprovalLegalIApprove} />
              <Link onClick={(e) => onOpenExternalLink(e, COURSELY_PRIVACY_POLICY)}>
                <FigmaText textKey={Texts.registrationStep4ApprovalLegalPrivacy} />
              </Link>
              <FigmaText textKey={Texts.registrationStep4ApprovalLegalAnd} />
              <Link onClick={(e) => onOpenExternalLink(e, COURSELY_REGISTRATION_RULES)}>
                <FigmaText textKey={Texts.registrationStep4ApprovalLegalCondition} />
              </Link>
            </LinkBox>
          </Link>
          <Box fullWidth align="flex-end">
            {!state.isRegistrationCompleted ? (
              <ContinueButton
                isDisabled={
                  (!isCheckboxChecked || !hasMainUser || state?.hasFormErrors || isLoading) &&
                  !state.obfuscatedEnrollment?.mainUser
                }
                onClick={!!state.obfuscatedEnrollment?.mainUser ? onNextStep : handleContinue}
                disabled={
                  (!isCheckboxChecked || !hasMainUser || state?.hasFormErrors || isLoading) &&
                  !state.obfuscatedEnrollment?.mainUser
                }
              >
                <FigmaText textKey={Texts.registrationCtactaContinue} />
                <img src={iconArrow} width={16} height={16} />
                {isLoading ? <Spinner /> : null}
              </ContinueButton>
            ) : null}
          </Box>
        </>
      ) : (
        <YellowBox fullWidth align="center">
          <FigmaText textKey={Texts.registrationStep4ApprovalApprovalWaiting} />
        </YellowBox>
      )}
    </>
  )
}

const Link = styled(Box)`
  cursor: pointer;
`

const LinkBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`

const YellowBox = styled(Box)`
  padding: 16px;
  background-color: rgba(239, 239, 25, 1);
  border-radius: ${BorderRadixes.small};
`

export default memo(CourseRegistrationStepThreeOpenedContent)
