import { memo } from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { getFigmaText } from '../libs/TextRepository'
import { State } from '../hooks/useAppState'
import iconCompleted from '/public/iconCompleted.svg'

type Props = {
  state: State
  registerActiveStep: number
}

const CourseRegistrationStepThreeClosedContent = ({ registerActiveStep, state }: Props) => (
  <Box fullWidth direction="row" align="center" gap="15px">
    {registerActiveStep > 3 || state.isRegistrationCompleted || !!state.obfuscatedEnrollment?.mainUser ? (
      <img src={iconCompleted} width={24} height={24} />
    ) : null}
    <FigmaText
      textKey={
        state?.activeRegisterStep > 2 || !!state.obfuscatedEnrollment?.mainUser
          ? Texts.registrationHeadersAndStatusHeaderCssDone
          : Texts.registrationHeadersAndStatusHeaderCssNotDone
      }
      text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSummaryAndApproval)}
    />
  </Box>
)

export default memo(CourseRegistrationStepThreeClosedContent)
