import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import iconCompleted from '/public/iconCompleted.svg'
import removeCourseIcon from '/public/removeCourseIcon.svg'
import addCourseIcon from '/public/addCourseIcon.svg'
import iconArrow from '/public/iconArrow.svg'
import { memo, useEffect, useState } from 'react'
import { ClosedContainer, ContinueButton, OpenContainer } from './StyledComponents'
import { EIGHT_PX, FOUR_PX, SIX_PX, TEN_PX, TWO_PX } from '../enums/Spacings'
import { Link } from 'react-router-dom'
import { getClient, getClientActivities } from '../libs/DBApiHandler'
import dayjs from 'dayjs'
import { BorderRadixes } from '../enums/BorderRadixes'
import { getWeekdayAbbreviation } from '../libs/DayJsHelper'
import PriceContainer from './PriceContainer'
import useDiscountCalculations, { calculateDiscountData } from '../hooks/useDiscountCalculations'
import { getDateColor } from '../libs/ColorUtils'
import { getFigmaText } from '../libs/TextRepository'
import { getActivePeriodsForUser } from '../libs/CloudFunctionsApiHandler'
import { Tabs, Tab } from '@mui/material'
import { OpenInNew, Add, Close } from '@mui/icons-material'
import { getLocalizedActivityType } from '../utils/activityUtils'
import * as Yup from 'yup'
import { useForm } from '../hooks/useForm'
import { Controller } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  state: State
  activity?: Activity
  onNextStep: () => void
  onOpenStepTwo: () => void
  registerActiveStep: number
  setFinalPrice: (price: string) => void
  onSetEnrollmentComment: (enrollmentComment: string) => void
  onSetSelectedActivities: (selectedActivities: Activity[]) => void
  onSetMainUserActivePeriods: (mainUserActivePeriods: string[]) => void
  onSetPartnerUserActivePeriods: (partnerUserActivePeriods: string[]) => void
}

export const ValidationSchema = Yup.object().shape({
  comment: Yup.string().required('Kommentar är obligatoriskt')
})

const CourseRegistrationStepTwo = ({
  registerActiveStep,
  onNextStep,
  onOpenStepTwo,
  activity,
  state,
  setFinalPrice,
  onSetEnrollmentComment,
  onSetSelectedActivities,
  onSetMainUserActivePeriods,
  onSetPartnerUserActivePeriods
}: Props) => {
  const [allActivities, setAllActivities] = useState<Activity[] | null>(null)
  const [client, setClient] = useState<Client | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [showAddSection, setShowAddSection] = useState(false)
  const [activeAddTab, setActiveAddTab] = useState(0)
  const [showCommentInput, setShowCommentInput] = useState(false)
  const isMobile = useIsMobile()

  const formProps = useForm<{ comment: string }>(ValidationSchema, {
    defaultValues: {
      comment: ''
    },
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const handleNext = () => {
    onSetEnrollmentComment(formProps.getValues().comment)
    onNextStep()
  }

  const handleAddTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveAddTab(newValue)
  }

  const filterActivitiesByType = (activities: Activity[] | null, type: 'Course' | 'Occasion' | 'Related' | 'Other') => {
    const threeWeeksAgo = dayjs().subtract(21, 'days').startOf('day')

    const filteredActivities = (activities || [])
      .filter((activity) => {
        // Check if activity has already started
        const activityDate = dayjs(activity.startDate).startOf('day')
        if (activityDate.isBefore(threeWeeksAgo)) return false

        // First check if the activity is already selected
        const isSelected = state?.selectedActivities?.some((a) => a.id === activity?.id)
        if (isSelected) return false

        // Then check if there's any selected activity with the same start date and time
        const hasConflict = state?.selectedActivities?.some(
          (selectedActivity) =>
            dayjs(selectedActivity.startDate).format('YYYY-MM-DD') === dayjs(activity.startDate).format('YYYY-MM-DD') &&
            selectedActivity.time === activity.time
        )
        if (hasConflict) return false

        // For Related type, check if activity's groupingCode matches any of the selected activities' groupingCodes
        if (type === 'Related') {
          const selectedGroupingCodes = state?.selectedActivities?.map((a) => a.groupingCode).filter((code) => code)
          return activity.groupingCode && selectedGroupingCodes?.includes(activity.groupingCode)
        }

        return activity.type === type
      })
      .sort((a, b) => new Date(a.startDate || '').getTime() - new Date(b.startDate || '').getTime())

    // Group activities by groupingCode
    return filteredActivities.reduce((groups: Record<string, Activity[]>, activity) => {
      const key = activity.groupingCode || activity.id
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(activity)
      return groups
    }, {})
  }

  const formatMonthHeader = (dateKey: string) => {
    const date = dayjs(dateKey + '-01') // Add day to ensure valid date
    return date.toDate().toLocaleString('sv-SE', { month: 'short', year: 'numeric' })
  }

  const groupActivitiesByMonth = (activities: Activity[]) => {
    // Normalize all dates to the first day of their month to ensure proper grouping
    return activities.reduce((groups: { [key: string]: Activity[] }, activity) => {
      const date = dayjs(activity.startDate).startOf('month')
      const key = date.format('YYYY-MM') // Use consistent date format
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(activity)
      return groups
    }, {})
  }

  // This function is used to render events that grouped per month
  const renderGroupedActivities = (groupedActivities: Record<string, Activity[]>) => {
    // Always flatten activities and group by month
    const allActivitiesFlat = Object.values(groupedActivities).flat()
    const byMonth = groupActivitiesByMonth(allActivitiesFlat)

    return Object.entries(byMonth).map(([monthKey, monthActivities]) => (
      <Box key={monthKey} fullWidth>
        <MonthHeader>{formatMonthHeader(monthKey)}</MonthHeader>
        {monthActivities.map((activity) => (
          <ActivityListItem
            id="activity-list-item-container"
            key={activity?.id}
            fullWidth
            direction="row"
            align="flex-start"
            justify="flex-start"
            left
            spacing={EIGHT_PX}
            gap={EIGHT_PX}
          >
            <Box
              id="activivy-list-item-selector"
              direction="row"
              align="center"
              gap={TWO_PX}
              top
              spacing={EIGHT_PX}
              style={{ cursor: 'pointer' }}
              onClick={() => onSetSelectedActivities([...state?.selectedActivities, activity])}
            >
              <img src={addCourseIcon} width={16} height={16} />
            </Box>
            <Box id="activity-list-main-container" fullWidth style={{ cursor: 'pointer' }}>
              <Box direction="row" align="center" justify="space-between" fullWidth>
                <ActivityItemTitle
                  id="activity-list-item-title"
                  direction="row"
                  align="center"
                  gap={SIX_PX}
                  onClick={() => onSetSelectedActivities([...state?.selectedActivities, activity])}
                >
                  <FigmaText
                    textKey={Texts.registrationStep3ActivitiesSelectedActivityName}
                    text={`${activity?.name}`}
                  />
                </ActivityItemTitle>
                <Link style={{ paddingTop: FOUR_PX }} to={`/activity/${activity?.id}`} target="_blank">
                  <OpenInNew sx={{ width: 20, height: 20, color: '#DFDFDF' }} />{' '}
                </Link>
              </Box>
              <Box
                id="activity-list-item-metadata"
                fullWidth
                justify="space-between"
                direction="row"
                align="center"
                gap={FOUR_PX}
              >
                <Box direction="row" align="center" gap={FOUR_PX}>
                  <Box direction="row" align="center" gap={FOUR_PX}>
                    <StartDate fullPadding spacing={FOUR_PX}>
                      <FigmaText
                        textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                        text={dayjs(activity?.startDate).format('D/M')}
                      />
                    </StartDate>
                  </Box>
                  <Box id="activity-list-item-weekday" direction="row" gap={FOUR_PX}>
                    <FigmaText
                      textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                      text={getWeekdayAbbreviation(activity?.startDate)}
                    />
                    <FigmaText textKey={Texts.registrationStep3ActivitiesSelectedActivityTime} text={activity?.time} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </ActivityListItem>
        ))}
      </Box>
    ))
  }

  // Add this type definition before the component
  type TabItem = {
    label: string
    content: Record<string, Activity[]>
  }

  // Replace the getAvailableTabsAndContent function
  const getAvailableTabsAndContent = (allActivities: Activity[] | null) => {
    // First, filter activities based on singleRegistration flag
    let filteredAllActivities = allActivities
    if (activity?.singleRegistration) {
      // If current activity has singleRegistration=true, only show other activities with singleRegistration=true
      filteredAllActivities = allActivities?.filter((act) => act.singleRegistration === true) || null
    }

    const showRelatedTab = activity?.groupingCode && state?.selectedActivities?.length > 0

    const relatedActivities = filterActivitiesByType(filteredAllActivities, 'Related')
    const filteredCourses = filterActivitiesByType(filteredAllActivities, 'Course')
    const filteredEvents = filterActivitiesByType(filteredAllActivities, 'Occasion')
    const filteredOthers = filterActivitiesByType(filteredAllActivities, 'Other')

    const showRelatedActivities = relatedActivities && Object.keys(relatedActivities).length > 0
    const showFilteredCourses = filteredCourses && Object.keys(filteredCourses).length > 0
    const showFilteredEvents = filteredEvents && Object.keys(filteredEvents).length > 0
    const showFilteredOthers = filteredOthers && Object.keys(filteredOthers).length > 0

    // Create array of available tabs with proper typing
    const availableTabs: TabItem[] = [
      showRelatedTab && showRelatedActivities
        ? {
            label: getFigmaText(Texts.externalActivityPageSegmentOtherTabRelated),
            content: relatedActivities
          }
        : null,
      showFilteredCourses
        ? {
            label: getLocalizedActivityType('Course', client ?? ({} as Client)),
            content: filteredCourses
          }
        : null,
      showFilteredEvents
        ? {
            label: getLocalizedActivityType('Occasion', client ?? ({} as Client)),
            content: filteredEvents
          }
        : null,
      showFilteredOthers
        ? {
            label: getLocalizedActivityType('Other', client ?? ({} as Client)),
            content: filteredOthers
          }
        : null
    ].filter((tab): tab is TabItem => tab !== null)

    return (
      <>
        <AddActivitiesHeader fullWidth direction="row" align="center" justify="space-between">
          <FigmaText textKey={Texts.registrationStep3ActivitiesHeaderStep3AddMoreCourses} />
          <CloseButton onClick={() => setShowAddSection(false)}>
            <Close sx={{ fontSize: 20 }} />
          </CloseButton>
        </AddActivitiesHeader>

        {availableTabs.length > 0 && (
          <>
            <TabsContainer>
              <Tabs value={activeAddTab} onChange={handleAddTabChange} variant="fullWidth">
                {availableTabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  />
                ))}
              </Tabs>
            </TabsContainer>

            <TabContent fullWidth>
              {availableTabs[activeAddTab] && renderGroupedActivities(availableTabs[activeAddTab].content)}
            </TabContent>
          </>
        )}
      </>
    )
  }

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        let fetchedActivities = await getClientActivities(activity?.clientId)

        // Filter out activities with startDate more than 7 days ago, activities that have already been selected,
        // and activities from different cities
        fetchedActivities = fetchedActivities.filter((fetchedActivity) => {
          const isNotSelected = !state?.selectedActivities?.some((selected) => selected.id === fetchedActivity.id)
          const isSameCity = fetchedActivity.city === activity?.city
          return isNotSelected && isSameCity
        })

        setAllActivities(fetchedActivities)
      } catch (err) {
        console.error(err)
      }
    }

    const fetchClient = async () => {
      if (activity?.clientId) {
        const clientData = await getClient(activity.clientId)
        if (clientData) setClient(clientData)
      }
    }

    if (activity?.id) {
      fetchActivity()
      fetchClient()
    }
  }, [activity?.clientId])

  useEffect(() => {
    if (activity?.id) onSetSelectedActivities([activity])
  }, [activity?.id])

  let { hasMainUser, hasPartner, mainUserPrices, partnerUserPrices, finalPrice, vatData } = useDiscountCalculations({
    activity,
    client,
    state
  })

  useEffect(() => {
    const fresh = calculateDiscountData({
      activity,
      client,
      state
    })
    mainUserPrices = fresh.mainUserPrices
    partnerUserPrices = fresh.partnerUserPrices
    finalPrice = fresh.finalPrice
    vatData = fresh.vatData
  }, [state.mainUserActivePeriods, state.partnerUserActivePeriods])

  useEffect(() => {
    if (!client) return

    setIsLoading(true)

    const fetchActivePeriodsForUser = async (phone: string, clientId: string) => {
      if (!phone) return []

      const response = await getActivePeriodsForUser({ phone, clientId }, state)
      return response
    }

    const mainUserActivePeriodsPromise = fetchActivePeriodsForUser(state.phoneNumber, client.id)
    const partnerUserActivePeriodsPromise = state.partnerPhoneNumber
      ? fetchActivePeriodsForUser(state.partnerPhoneNumber, client.id)
      : Promise.resolve([])

    Promise.all([mainUserActivePeriodsPromise, partnerUserActivePeriodsPromise])
      .then(([mainUserActivePeriods, partnerUserActivePeriods]) => {
        onSetMainUserActivePeriods(mainUserActivePeriods)
        onSetPartnerUserActivePeriods(partnerUserActivePeriods)
      })
      .finally(() => setIsLoading(false))
  }, [client, state.phoneNumber, state.partnerPhoneNumber])

  const onOpenStep = () => {
    if (registerActiveStep > 2 || state.isRegistrationCompleted || !!state.obfuscatedEnrollment?.mainUser)
      onOpenStepTwo()
  }

  useEffect(() => {
    if (finalPrice) setFinalPrice(finalPrice + ' SEK')
  }, [finalPrice])

  return registerActiveStep !== 2 ? (
    <ClosedContainer onClick={onOpenStep}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {(registerActiveStep > 1 || state.isRegistrationCompleted || state.obfuscatedEnrollment?.mainUser?.name) &&
        hasMainUser ? (
          <img src={iconCompleted} width={24} height={24} />
        ) : null}
        <FigmaText
          textKey={
            state?.activeRegisterStep > 2 || !!state.obfuscatedEnrollment?.mainUser
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssNotDone
          }
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSelectedCourses)}
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer>
      <Box fullWidth direction="row" align="center" gap="15px">
        <Box direction="row" gap="15px">
          {state.isRegistrationCompleted ||
            (!!state.obfuscatedEnrollment?.mainUser && <img src={iconCompleted} width={24} height={24} />)}
          <FigmaText
            textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
            text={getFigmaText(Texts.registrationHeadersAndStatusHeaderSelectedCourses)}
          />
        </Box>
        <StepCircle align="center" justify="center">
          {state?.selectedActivities.length}
        </StepCircle>
      </Box>
      {hasMainUser || !!state.obfuscatedEnrollment?.mainUser?.phone ? (
        <>
          <SelectedCourses fullWidth direction="row" align="flex-start" justify="space-between">
            <Box fullWidth>
              <Box fullWidth fullHeight gap={TEN_PX}>
                {!state.obfuscatedEnrollment?.activities &&
                  Object.entries(
                    state?.selectedActivities?.reduce(
                      (acc, activity) => {
                        const key = activity.group || activity.id
                        return {
                          ...acc,
                          [key]: [...(acc[key] || []), activity]
                        }
                      },
                      {} as Record<string, Activity[]>
                    )
                  ).map(([group, groupEntries]) => (
                    <Box
                      id="activity-list-item-container"
                      key={`group-${group}`}
                      fullWidth
                      direction="row"
                      align="flex-start"
                      justify="flex-start"
                      left
                      spacing={EIGHT_PX}
                      gap={EIGHT_PX}
                    >
                      <Box
                        id="activivy-list-item-selector"
                        direction="row"
                        align="center"
                        gap={TWO_PX}
                        top
                        spacing={TWO_PX}
                      >
                        {!state.isRegistrationSubmitted || !!state.obfuscatedEnrollment?.mainUser ? (
                          <InvisibleButton
                            onClick={() =>
                              onSetSelectedActivities(
                                state?.selectedActivities.filter((a) => !groupEntries.map((a) => a.id).includes(a.id))
                              )
                            }
                          >
                            <img src={removeCourseIcon} width={16} height={16} />
                          </InvisibleButton>
                        ) : null}
                      </Box>
                      <Box id="activity-list-main-container" fullWidth>
                        <Box
                          id="activity-list-item-header"
                          fullWidth
                          direction="row"
                          justify="space-between"
                          gap={SIX_PX}
                        >
                          <Box align="flex-start" direction="row" gap="8px">
                            {groupEntries.map((selectedActivity) => (
                              <ColorCircle
                                key={selectedActivity.id}
                                dateColor={getDateColor(selectedActivity?.period || '')}
                              />
                            ))}

                            <FigmaText
                              textKey={Texts.registrationStep3ActivitiesSelectedActivityName}
                              text={groupEntries[0]?.name}
                            />
                          </Box>
                          <Link style={{ paddingTop: TWO_PX }} to={`/activity/${groupEntries[0]?.id}`} target="_blank">
                            <OpenInNew sx={{ width: 20, height: 20, color: '#DFDFDF' }} />{' '}
                          </Link>
                        </Box>
                        {groupEntries.map((selectedActivity) => (
                          <Box key={`activity-${selectedActivity.id}`} id="activity-list-main-container" fullWidth>
                            <Box direction="row" align="center" gap={FOUR_PX} fullWidth>
                              <Box id="activity-list-startdate" direction="row" align="center" gap={FOUR_PX}>
                                <StartDate fullPadding spacing={FOUR_PX}>
                                  <FigmaText
                                    textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                    text={dayjs(selectedActivity?.startDate).format('D/M')}
                                  />
                                </StartDate>
                              </Box>
                              <Box direction="row" gap={FOUR_PX} fullWidth>
                                <FigmaText
                                  textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                  text={getWeekdayAbbreviation(selectedActivity?.startDate)}
                                />
                                <FigmaText
                                  textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                  text={selectedActivity?.time}
                                />
                                {groupEntries.length > 1 && !state.isRegistrationCompleted && (
                                  <InvisibleButton
                                    onClick={() =>
                                      onSetSelectedActivities(
                                        state?.selectedActivities.filter((a) => a.id !== selectedActivity.id)
                                      )
                                    }
                                  >
                                    <img src={removeCourseIcon} width={16} height={16} />
                                  </InvisibleButton>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}

                {!!state?.obfuscatedEnrollment?.activities &&
                  Object.entries(
                    state?.obfuscatedEnrollment?.activities?.reduce(
                      (acc, activity) => {
                        const key = activity.group || activity.id
                        return {
                          ...acc,
                          [key]: [...(acc[key] || []), activity]
                        }
                      },
                      {} as Record<string, Activity[]>
                    )
                  ).map(([group, groupEntries]) => (
                    <Box
                      id="activity-list-item-container"
                      key={`group-${group}`}
                      fullWidth
                      direction="row"
                      align="flex-start"
                      justify="flex-start"
                      left
                      spacing={EIGHT_PX}
                      gap={EIGHT_PX}
                    >
                      <Box
                        id="activivy-list-item-selector"
                        direction="row"
                        align="center"
                        gap={TWO_PX}
                        top
                        spacing={TWO_PX}
                      >
                        {!state.isRegistrationSubmitted ||
                          (!!state.obfuscatedEnrollment?.mainUser && (
                            <InvisibleButton
                              onClick={() =>
                                onSetSelectedActivities(
                                  state?.selectedActivities.filter((a) => !groupEntries.map((a) => a.id).includes(a.id))
                                )
                              }
                            >
                              <img src={removeCourseIcon} width={16} height={16} />
                            </InvisibleButton>
                          ))}
                      </Box>
                      <Box id="activity-list-main-container" fullWidth>
                        <Box
                          id="activity-list-item-header"
                          fullWidth
                          direction="row"
                          justify="space-between"
                          gap={SIX_PX}
                        >
                          <Box align="flex-start" direction="row" gap="8px">
                            {groupEntries.map((selectedActivity) => (
                              <ColorCircle
                                key={selectedActivity.id}
                                dateColor={getDateColor(selectedActivity?.period || '')}
                              />
                            ))}

                            <FigmaText
                              textKey={Texts.registrationStep3ActivitiesSelectedActivityName}
                              text={groupEntries[0]?.name}
                            />
                          </Box>
                          <Link style={{ paddingTop: TWO_PX }} to={`/activity/${groupEntries[0]?.id}`} target="_blank">
                            <OpenInNew sx={{ width: 20, height: 20, color: '#DFDFDF' }} />{' '}
                          </Link>
                        </Box>
                        {groupEntries.map((selectedActivity) => (
                          <Box key={`activity-${selectedActivity.id}`} id="activity-list-main-container" fullWidth>
                            <Box direction="row" align="center" gap={FOUR_PX} fullWidth>
                              <Box id="activity-list-startdate" direction="row" align="center" gap={FOUR_PX}>
                                <StartDate fullPadding spacing={FOUR_PX}>
                                  <FigmaText
                                    textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                    text={dayjs(selectedActivity?.startDate).format('D/M')}
                                  />
                                </StartDate>
                              </Box>
                              <Box direction="row" gap={FOUR_PX} fullWidth>
                                <FigmaText
                                  textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                  text={getWeekdayAbbreviation(selectedActivity?.startDate)}
                                />
                                <FigmaText
                                  textKey={Texts.registrationStep3ActivitiesSelectedActivityTime}
                                  text={selectedActivity?.time}
                                />
                                {groupEntries.length > 1 && !state.isRegistrationCompleted && (
                                  <InvisibleButton
                                    onClick={() =>
                                      onSetSelectedActivities(
                                        state?.selectedActivities.filter((a) => a.id !== selectedActivity.id)
                                      )
                                    }
                                  >
                                    <img src={removeCourseIcon} width={16} height={16} />
                                  </InvisibleButton>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>

            <PriceContainer
              mainUserPrices={mainUserPrices}
              partnerUserPrices={partnerUserPrices}
              hasPartner={hasPartner}
              finalPrice={finalPrice}
              vatData={vatData}
              state={state}
              isLoading={isLoading}
            />
          </SelectedCourses>

          {!state.isRegistrationCompleted &&
            allActivities &&
            (() => {
              const filteredActivities = allActivities.filter(
                (activity) => !state?.selectedActivities?.some((selected) => selected.id === activity.id)
              )
              return filteredActivities.length > 0 &&
                !state.isRegistrationCompleted &&
                !state.isRegistrationSubmitted &&
                !state.obfuscatedEnrollment?.mainUser ? (
                <Box fullWidth>
                  {showAddSection && (
                    <SectionAddMoreActivities id="SectionAddMoreActivities" fullWidth>
                      {getAvailableTabsAndContent(filteredActivities)}
                    </SectionAddMoreActivities>
                  )}
                </Box>
              ) : null
            })()}
        </>
      ) : (
        <Box top>Vänligen ange dina uppgifter i steg 1.</Box>
      )}

      {showCommentInput && (
        <Controller
          control={formProps.control}
          name="comment"
          render={({ field, fieldState }) => (
            <TextFieldWithLabelForm
              {...field}
              value={field.value}
              fullWidth
              fieldState={fieldState}
              placeholderTextKey={Texts.registrationStep3ActivitiesCommentHelperText}
            />
          )}
        />
      )}

      <Box fullWidth direction={isMobile ? 'column' : 'row'} justify="space-between" top spacing={EIGHT_PX}>
        <Box direction="row" gap={SIX_PX} bottom={isMobile}>
          {!showAddSection && !state.obfuscatedEnrollment.mainUser?.phone && (
            <AddMoreButton
              isDisabled={state.isRegistrationSubmitted}
              direction="row"
              align="center"
              onClick={!state.isRegistrationSubmitted ? () => setShowAddSection(true) : undefined}
            >
              <Add sx={{ fontSize: 20, marginRight: '8px' }} />
              <FigmaText textKey={Texts.registrationCtactaAddMoreCourses} />
            </AddMoreButton>
          )}
          {!state.obfuscatedEnrollment.mainUser?.phone && (
            <AddCommentButton
              isDisabled={state.isRegistrationSubmitted}
              direction="row"
              align="center"
              onClick={!state.isRegistrationSubmitted ? () => setShowCommentInput(!showCommentInput) : undefined}
            >
              <Add sx={{ fontSize: 20, marginRight: '8px' }} />
              <FigmaText textKey={Texts.registrationCtactaAddComment} />
            </AddCommentButton>
          )}
        </Box>
        {!state.isRegistrationCompleted ? (
          <Box alignSelf="flex-end">
            <ContinueButton
              onClick={handleNext}
              disabled={
                (!state.obfuscatedEnrollment?.activities && !hasMainUser) ||
                state.selectedActivities.length <= 0 ||
                isLoading
              }
              isDisabled={
                (!state.obfuscatedEnrollment?.activities && !hasMainUser) ||
                state.selectedActivities.length <= 0 ||
                isLoading
              }
            >
              <FigmaText textKey={Texts.registrationCtactaContinue} />
              <img src={iconArrow} width={16} height={16} />
            </ContinueButton>
          </Box>
        ) : null}
      </Box>
    </OpenContainer>
  )
}

const AddCommentButton = styled(Box)<{ isDisabled?: boolean }>`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  border: 1px solid #aaa;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  background: ${(props) => (props.isDisabled ? '#f5f5f5' : '#fff')};
  border-radius: ${BorderRadixes.button};
  padding: 8px 16px;
  align-self: flex-start;

  &:hover {
    background-color: #f5f5f5;
  }
`

const AddMoreButton = styled(Box)<{ isDisabled?: boolean }>`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  border: ${(props) => (props.isDisabled ? '1px solid #aaa' : '1px solid #90c4ff')};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  background: ${(props) => (props.isDisabled ? '#f5f5f5' : '#d6efff')};
  border-radius: ${BorderRadixes.button};
  padding: 8px 16px;
  align-self: flex-start;

  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#f5f5f5' : 'rgb(188, 215, 233)')};
  }
`

const AddActivitiesHeader = styled(Box)`
  padding-bottom: 8px;
`

const CloseButton = styled(Box)`
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const SectionAddMoreActivities = styled(Box)`
  border-radius: 12px;
  background: E6F8EA;
  border: 1px solid #eee;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
`

const ActivityItemTitle = styled(Box)`
  width: 100%;
`
const ActivityListItem = styled(Box)`
  &:hover {
    background-color: #f5f5f5;
  }
`

const SelectedCourses = styled(Box)`
  gap: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const InvisibleButton = styled(Box)`
  cursor: pointer;
`

const StepCircle = styled(Box)`
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: #3e55c9;
`

const StartDate = styled(Box)`
  border-radius: ${BorderRadixes.regular};
`

const ColorCircle = styled(Box)<{ dateColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.dateColor || '#EFEBEB'};
  flex-shrink: 0;
`

const TabsContainer = styled(Box)`
  border-bottom: 1px solid #3b59ec;
  margin-bottom: 16px;
  width: 100%;
  .MuiTabs-root {
    min-height: 40px;
  }

  .MuiTab-root {
    min-height: 40px;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(80, 80, 80) !important;
    &:first-of-type {
      min-width: 150px;
    }
  }

  .Mui-selected {
    color: rgb(80, 80, 80) !important;
  }

  .MuiTabs-indicator {
    background-color: #3b59ec;
  }
`

const TabContent = styled(Box)``

const MonthHeader = styled.h4`
  font-size: 14px;
  color: rgb(176, 176, 176);
  text-transform: uppercase;
`

export default memo(CourseRegistrationStepTwo)
