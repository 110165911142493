import React, { useEffect } from 'react'
import Box from '../components/Box'
import theme from '../libs/theme'
import { useMediaQuery } from '@mui/material'

const TestPage: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  console.log(isMobile)
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://app.coursely.se/scripts/activityLoader.js'
    script.defer = true

    script.onload = () => console.log('✅ Script loaded successfully')
    script.onerror = (error) => console.error('❌ Script failed to load:', error)

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Box style={styles.pageContainer}>
      {/* Top Content with Filler Text */}
      <Box style={styles.topContent}>
        <h1>Test Page</h1>
        <p>This is some random filler text for testing purposes. The content should appear at the top.</p>
        <ul>
          <li>Item 1 - Example</li>
          <li>Item 2 - More Filler</li>
          <li>Item 3 - Just a Test</li>
        </ul>
      </Box>

      <Box style={styles.mainContent} direction={isMobile ? 'column' : 'row'}>
        {/* Left Test Content */}
        <Box style={styles.sideContent} width={isMobile ? '100%' : '20%'}>
          <h3>Left Panel</h3>
          <p>Some additional text for testing on the left side.</p>
        </Box>

        {/* Dynamic iFrame Container */}
        <Box
          id="container-iframe"
          style={styles.iframeContainer}
          data-client-id={'2smyyb7im'}
          width={isMobile ? '100%' : '100%'}
        ></Box>

        {/* Right Test Content */}
        <Box style={styles.sideContent} width={isMobile ? '100%' : '20%'}>
          <h3>Right Panel</h3>
          <p>Some random text for testing on the right side.</p>
        </Box>
      </Box>
    </Box>
  )
}

// Inline Styles
const styles: { [key: string]: React.CSSProperties } = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
  },
  topContent: {
    width: '80%',
    textAlign: 'center',
    marginBottom: '20px'
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '20px'
  },
  sideContent: {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
    borderRadius: '5px'
  },
  iframeContainer: {
    flex: 1,
    textAlign: 'center',
    overflow: 'hidden'
  },
  iframe: {
    border: '1px solid #ddd',
    borderRadius: '5px'
  }
}

export default TestPage
