import { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import * as stripeJs from '@stripe/stripe-js'
import { ContinueButton } from './StyledComponents'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import iconArrow from '/public/iconArrow.svg'
import Box from './Box'
import { EIGHT_PX, EIGHTEEN_PX } from '../enums/Spacings'

type Props = {
  onIsPaid: (isPaid) => void
}

const StripeCheckoutForm = ({ onIsPaid }: Props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setLoading(true)

    const returnUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/pay-success`

    const { error } = await stripe.confirmPayment({
      elements, // `Elements` instance that was used to create the Payment Element
      confirmParams: {
        return_url: returnUrl
      },
      redirect: 'if_required'
    })

    setLoading(true)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message || 'uknown error')
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      console.log('Stripe payment sucessful')
      onIsPaid(true)
    }
  }

  const paymentElementOptions: stripeJs.StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box fullWidth>
        <PaymentElement options={paymentElementOptions} />
        <Box alignSelf="flex-end" top spacing={EIGHTEEN_PX} gap={EIGHT_PX}>
          <ContinueButton type="submit" isDisabled={!stripe || loading} disabled={!stripe || loading}>
            <FigmaText textKey={Texts.registrationCtactaContinue} />
            <img src={iconArrow} width={16} height={16} />
          </ContinueButton>
        </Box>
      </Box>
    </form>
  )
}

export default StripeCheckoutForm
