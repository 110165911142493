import { Activity, Client, GroupByOption } from '../../../pure-js/types/GrooverTypes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
const TYPE_ORDER = ['Course', 'Other', 'Occasion']

export const formatDateSwedish = (date: string) => {
  return new Date(date)
    .toLocaleString('sv-SE', { month: 'long', year: 'numeric' })
    .replace(/^\w/, (c) => c.toUpperCase())
}

export const getLocalizedActivityType = (type: string, client: Client) => {
  //TODO ADD DYNAMIC localization types
  const types = {
    Other: client?.typeTranslations?.Other || getFigmaText(Texts.adminGeneralEnumsActivityTypeOther),
    Course: client?.typeTranslations?.Course || getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse),
    Occasion: client?.typeTranslations?.Occasion || getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
  }

  return types[type as keyof typeof types] || '-'
}

export const getLocalizedActivityTypeTranslated = (type: string, client: Client) => {
  // Create mapping from original type keys to translated values
  const types = {
    Other: client?.typeTranslations?.Other || getFigmaText(Texts.adminGeneralEnumsActivityTypeOther),
    Course: client?.typeTranslations?.Course || getFigmaText(Texts.adminGeneralEnumsActivityTypeCourse),
    Occasion: client?.typeTranslations?.Occasion || getFigmaText(Texts.adminGeneralEnumsActivityTypeOccasion)
  }

  // Reverse map: { "Fasta kurser": "Course", "Intensivos": "Occasion", "Specials": "Other" }
  const reverseTypeMap = Object.entries(types).reduce(
    (acc, [original, translated]) => {
      acc[translated] = original
      return acc
    },
    {} as Record<string, string>
  )

  // Use reverse map to find original key
  const originalKey = reverseTypeMap[type] || type // Fallback to type if not found

  return { translated: type, original: originalKey }
}

/**
 * Sorts activities inside groups based on `groupSorting`
 */
export const sortActivities = (activities: Activity[], sortBy: string): Activity[] => {
  return [...activities].sort((a, b) => {
    switch (sortBy) {
      case 'level':
        return (a.level || '').localeCompare(b.level || '')
      case 'startdate': {
        const dateA = new Date(a.startDate).getTime()
        const dateB = new Date(b.startDate).getTime()

        // First, compare by startDate
        if (dateA !== dateB) {
          return dateA - dateB
        }

        // If startDates are the same, compare by time
        const timeA = (a.time || '00:00').split(':').map(Number)
        const timeB = (b.time || '00:00').split(':').map(Number)

        // Convert hours & minutes to total minutes and compare
        return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1])
      }

      case 'name':
        return (a.name || '').localeCompare(b.name || '')
      default:
        return 0
    }
  })
}

/**
 * Groups activities first by `groupBy`, then always by `startdate`
 * Finally, sorts activities inside each group based on `groupSorting`
 */
export const groupAndSortActivities = (
  activities: Activity[],
  groupBy: GroupByOption,
  groupSorting: string,
  cityName: string,
  client: Client
) => {
  // ✅ Filter by city first
  const filteredActivities = cityName
    ? activities.filter((a) => a.city?.toLowerCase() === cityName.toLowerCase())
    : activities

  // ✅ First-level grouping based on `groupBy`
  const primaryGrouped = filteredActivities.reduce((acc: { [key: string]: Activity[] }, activity) => {
    let groupKey = ''

    switch (groupBy) {
      case 'startdate':
        groupKey = formatDateSwedish(activity.startDate || '')
        break // This break was missing!
      case 'type':
        groupKey = getLocalizedActivityType(activity.type, client)
        break
      case 'city':
        groupKey = activity.city || 'No City'
        break
      case 'style':
        groupKey = activity.style || 'No Style'
        break
      default:
        groupKey = 'Other'
    }

    if (!acc[groupKey]) acc[groupKey] = []
    acc[groupKey].push(activity)

    return acc
  }, {})

  // Special handling for startdate grouping - no need for second level
  if (groupBy === 'startdate') {
    const sortedGroups = Object.entries(primaryGrouped).sort(([, activitiesA], [, activitiesB]) => {
      const dateA = Math.min(...activitiesA.map((a) => new Date(a.startDate).getTime()))
      const dateB = Math.min(...activitiesB.map((b) => new Date(b.startDate).getTime()))
      return dateA - dateB
    })

    return Object.fromEntries(
      sortedGroups.map(([key, activities]) => [key, { [key]: sortActivities(activities, groupSorting) }])
    )
  }

  // Sort primary groups if `groupBy === "type"` using `TYPE_ORDER`
  const sortedPrimaryGroups = Object.entries(primaryGrouped).sort(([keyA], [keyB]) => {
    const { original: originalA, translated: typeA } = getLocalizedActivityTypeTranslated(keyA, client)
    const { original: originalB, translated: typeB } = getLocalizedActivityTypeTranslated(keyB, client)
    // Get index in TYPE_ORDER based on the original type key
    const indexA = TYPE_ORDER.indexOf(originalA)
    const indexB = TYPE_ORDER.indexOf(originalB)

    if (indexA === -1 && indexB === -1) {
      return typeA.localeCompare(typeB) // Default sorting if not in TYPE_ORDER
    }

    return indexA - indexB || typeA.localeCompare(typeB)
  })

  // Apply second-level grouping (by start date)
  const finalGrouped: Record<string, Record<string, Activity[]>> = Object.fromEntries(
    sortedPrimaryGroups
      .map(([groupKey, activities]) => {
        const subGrouped = activities.reduce((acc: { [key: string]: Activity[] }, activity) => {
          const subGroupKey = formatDateSwedish(activity.startDate || '')

          if (!acc[subGroupKey]) acc[subGroupKey] = []
          acc[subGroupKey].push(activity)

          return acc
        }, {})

        // Sort activities inside each subgroup
        Object.keys(subGrouped).forEach((key) => {
          subGrouped[key] = sortActivities(subGrouped[key], groupSorting)
        })

        // Sort subgroups by the earliest activity date in each group
        const sortedSubGroups = Object.entries(subGrouped).sort(([, activitiesA], [, activitiesB]) => {
          // Get the earliest date in each subgroup
          const dateA = Math.min(...activitiesA.map((a) => new Date(a.startDate).getTime()))
          const dateB = Math.min(...activitiesB.map((b) => new Date(b.startDate).getTime()))

          // First, compare by startDate
          if (dateA !== dateB) {
            return dateA - dateB
          }

          // If dates are the same, compare by earliest time
          const timeA = Math.min(
            ...activitiesA.map((a) => {
              const [hours, minutes] = (a.time || '00:00').split(':').map(Number)
              return hours * 60 + minutes // Convert time to total minutes for comparison
            })
          )
          const timeB = Math.min(
            ...activitiesB.map((b) => {
              const [hours, minutes] = (b.time || '00:00').split(':').map(Number)
              return hours * 60 + minutes
            })
          )

          return timeA - timeB
        })

        return [groupKey, Object.fromEntries(sortedSubGroups)]
      })
      .sort(([, activitiesA], [, activitiesB]) => {
        if (groupBy === 'type') return 0
        const dateA = Math.min(
          ...Object.values(activitiesA)
            .flat()
            .map((a) => new Date(a.startDate).getTime())
        )
        const dateB = Math.min(
          ...Object.values(activitiesB)
            .flat()
            .map((b) => new Date(b.startDate).getTime())
        )
        return dateA - dateB
      })
  )

  return finalGrouped
}

export const filterByDanceStyle = (activities: Activity[], danceStyle: string) => {
  return activities.filter((activity) => activity.style.toLowerCase() === danceStyle.toLowerCase())
}
