import React from 'react'
import styled from '@emotion/styled'
import FigmaText from './FigmaText'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import Box from './Box'
import { Texts } from '../../../pure-js/libs/Texts'

interface PreSelectedActivityProps {
  activity: Activity
}

const PreSelectedActivity: React.FC<PreSelectedActivityProps> = ({ activity }) => {
  return (
    <Container direction="row" gap="8px" fullWidth>
      <FigmaText textKey={Texts.registrationHeadersAndStatusPreSelectedActivityLabel} />
      <ActivityName textKey={Texts.registrationHeadersAndStatusPreSelectedActivityNameCss} text={activity.name} />
    </Container>
  )
}

const Container = styled(Box)`
  align-items: center;
  background-color: rgb(248, 247, 247);
  padding: 8px 16px;
`

const ActivityName = styled(FigmaText)`
  font-weight: 500;
`

export default PreSelectedActivity
