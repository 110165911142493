import { Activity, PaymentMethod } from '../../../pure-js/types/GrooverTypes'
import CourseRegistrationStepFive from '../components/CourseRegistrationStepFive'
import CourseRegistrationStepFour from '../components/CourseRegistrationStepFour'
import CourseRegistrationStepOne from '../components/CourseRegistrationStepOne'
import CourseRegistrationStepThree from '../components/CourseRegistrationStepThree'
import CourseRegistrationStepTwo from '../components/CourseRegistrationStepTwo'
import { State } from '../hooks/useAppState'
import { RegisterActionFunctions } from '../hooks/useRegisterActions'
import { CourseRegistrationFormValues } from './CourseRegistrationStepOneHelper'

export type StepConfig = {
  id: number
  component: React.ComponentType<any>
  props: (
    state: State,
    activity: Activity,
    actions: RegisterActionFunctions
  ) => {
    state: State
    activity?: Activity
    onNextStep?: () => void
    onOpenStepOne?: () => void
    onOpenStepTwo?: () => void
    onOpenStepThree?: () => void
    onOpenStepFour?: () => void
    onOpenStepFive?: () => void
    registerActiveStep: number
    onSetFormErrors?: (hasFormErrors: boolean) => void
    onSetIsPartnerSenior?: (isPartnerSenior: boolean) => void
    onSetIsPartnerStudent?: (isPartnerStudent: boolean) => void
    onSetIsMainUserSenior?: (isMainUserSenior: boolean) => void
    onSetIsMainUserStudent?: (isMainUserStudent: boolean) => void
    onSaveRegistrationData?: (data: CourseRegistrationFormValues) => void
    setFinalPrice?: (price: string) => void
    onSetSelectedActivities?: (activities: Activity[]) => void
    onSetEnrollmentId?: (enrollmentId: string) => void
    onCompleteRegistration?: () => void
    onSetPaymentMethod?: (paymentMethod: PaymentMethod) => void
  }
}

export const stepConfig: StepConfig[] = [
  {
    id: 1,
    component: CourseRegistrationStepOne,
    props: (state: State, activity: Activity, actions: RegisterActionFunctions) => ({
      state,
      activity,
      onNextStep: () => actions.onOpenStep(state.activeRegisterStep + 1),
      onOpenStepOne: () => actions.onOpenStep(1),
      registerActiveStep: state.activeRegisterStep,
      onSetFormErrors: actions.onSetFormErrors,
      onSetIsPartnerSenior: actions.onSetIsPartnerSenior,
      onSetIsPartnerStudent: actions.onSetIsPartnerStudent,
      onSetIsMainUserSenior: actions.onSetIsMainUserSenior,
      onSetIsMainUserStudent: actions.onSetIsMainUserStudent,
      onSaveRegistrationData: actions.onSaveRegistrationData
    })
  },
  {
    id: 2,
    component: CourseRegistrationStepTwo,
    props: (state: State, activity: Activity, actions) => ({
      state,
      activity,
      onNextStep: () => actions.onOpenStep(state.activeRegisterStep + 1),
      onOpenStepTwo: () => actions.onOpenStep(2),
      registerActiveStep: state.activeRegisterStep,
      setFinalPrice: (price: string) => actions.onSetFinalPrice(price),
      onSetSelectedActivities: (activities: Activity[]) => actions.onSetSelectedActivities(activities),
      onSetMainUserActivePeriods: actions.onSetMainUserActivePeriods,
      onSetPartnerUserActivePeriods: actions.onSetPartnerUserActivePeriods,
      onSetEnrollmentComment: (enrollmentComment: string) => actions.onSetEnrollmentComment(enrollmentComment)
    })
  },
  {
    id: 3,
    component: CourseRegistrationStepThree,
    props: (state: State, activity: Activity, actions) => ({
      state,
      activity,
      onNextStep: () => actions.onOpenStep(state.activeRegisterStep + 1),
      registerActiveStep: state.activeRegisterStep,
      onOpenStepThree: () => actions.onOpenStep(3),
      onSetEnrollmentId: (enrollmentId: string) => actions.onSetEnrollmentId(enrollmentId),
      onSetIsRegistrationSubmitted: actions.onSetIsRegistrationSubmitted
    })
  },
  {
    id: 4,
    component: CourseRegistrationStepFour,
    props: (state: State, activity: Activity, actions) => ({
      state,
      activity,
      onNextStep: () => actions.onOpenStep(state.activeRegisterStep + 1),
      onOpenStepFour: () => actions.onOpenStep(4),
      registerActiveStep: state.activeRegisterStep,
      onCompleteRegistration: () => actions.onCompleteRegistration(),
      onSetPaymentMethod: (paymentMethod: PaymentMethod) => actions.onSetPaymentMethod(paymentMethod),
      onSetIsPaid: (isPaid: boolean) => actions.onSetIsPaid(isPaid)
    })
  },
  {
    id: 5,
    component: CourseRegistrationStepFive,
    props: (state: State, activity: Activity, actions) => ({
      state,
      activity,
      onOpenStepFive: () => actions.onOpenStep(5),
      registerActiveStep: state.activeRegisterStep,
      onSetMainUserSsn: (mainUserSsn: string) => actions.onSetMainUserSsn(mainUserSsn),
      onSetPartnerUserSsn: (partnerUserSsn: string) => actions.onSetPartnerUserSsn(partnerUserSsn)
    })
  }
]
