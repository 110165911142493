import { memo } from 'react'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import emptyActivity from '/public/emptyActivity.svg'
import emptyStateImage from '/public/emptyStateImage.svg'

const EmptyActivity = () => {
  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <img src={emptyActivity} width={375} height={150} alt="Missing activity" />
      <img src={emptyStateImage} width={375} height={150} alt="Empty state" />
      <Box top>
        <FigmaText textKey={Texts.externalActivityPageEmptyState} />
      </Box>
    </Box>
  )
}

export default memo(EmptyActivity)
