import { memo, useCallback } from 'react'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { ClosedContainer, OpenContainer } from './StyledComponents'
import CourseRegistrationStepThreeClosedContent from './CourseRegistrationStepThreeClosedContent'
import CourseRegistrationStepThreeOpenedContent from './CourseRegistrationStepThreeOpenedContent'

type Props = {
  state: State
  activity: Activity
  onNextStep: () => void
  registerActiveStep: number
  onOpenStepThree: () => void
  onSetEnrollmentId: (enrollmentId: string) => void
  onSetIsRegistrationSubmitted: () => void
}

const CourseRegistrationStepThree = ({
  state,
  activity,
  onNextStep,
  registerActiveStep,
  onOpenStepThree,
  onSetEnrollmentId,
  onSetIsRegistrationSubmitted
}: Props) => {
  const isStepActive = registerActiveStep === 3
  const shouldOpenStep =
    registerActiveStep > 3 || state.isRegistrationCompleted || !!state.obfuscatedEnrollment?.mainUser

  const handleOpenStep = useCallback(() => {
    if (shouldOpenStep) onOpenStepThree()
  }, [shouldOpenStep, onOpenStepThree])

  return isStepActive ? (
    <OpenContainer>
      <CourseRegistrationStepThreeOpenedContent
        state={state}
        activity={activity}
        onNextStep={onNextStep}
        onSetIsRegistrationSubmitted={onSetIsRegistrationSubmitted}
        onSetEnrollmentId={onSetEnrollmentId}
      />
    </OpenContainer>
  ) : (
    <ClosedContainer onClick={handleOpenStep}>
      <CourseRegistrationStepThreeClosedContent state={state} registerActiveStep={registerActiveStep} />
    </ClosedContainer>
  )
}

export default memo(CourseRegistrationStepThree)
