import { UseFormReturn } from 'react-hook-form'
import { State } from '../hooks/useAppState'
import CourseRegistrationStepOneNoPartnerForm from './CourseRegistrationStepOneNoPartnerForm'
import { CourseRegistrationFormValues } from '../helpers/CourseRegistrationStepOneHelper'
import CourseRegistrationStepOnePartnerForm from './CourseRegistrationStepOnePartnerForm'
import { memo } from 'react'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { DanceRoles } from '../../../pure-js/types/DanceRoles'

type Props = {
  state: State
  client: Client
  activity: Activity
  formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>
  role: DanceRoles
  hasPartner: boolean
  onNextStep: () => void
  setHasPartner: (hasPartner: boolean) => void
  changeRole: (isPartnerRole: boolean, newRole: DanceRoles) => void
  partnerRole: DanceRoles
  onSubmit: (data: CourseRegistrationFormValues) => void
  onSetIsMainUserStudent: (isMainUserStudent: boolean) => void
  onSetIsMainUserSenior: (isMainUserSenior: boolean) => void
  onSetIsPartnerStudent: (isPartnerStudent: boolean) => void
  onSetIsPartnerSenior: (isPartnerSenior: boolean) => void
  handleRemovePartner: (formProps: UseFormReturn<CourseRegistrationFormValues, any, undefined>) => void
}

const CourseRegistrationStepOneOpenedContent = ({
  state,
  client,
  activity,
  formProps,
  role,
  hasPartner,
  onNextStep,
  setHasPartner,
  changeRole,
  partnerRole,
  onSubmit,
  onSetIsMainUserStudent,
  onSetIsMainUserSenior,
  onSetIsPartnerStudent,
  onSetIsPartnerSenior,
  handleRemovePartner
}: Props) => {
  return (
    <>
      <CourseRegistrationStepOneNoPartnerForm
        state={state}
        client={client}
        formProps={formProps}
        role={role}
        onNextStep={onNextStep}
        changeRole={(isPartnerRole, newRole) => changeRole(isPartnerRole, newRole)}
        hasPartner={hasPartner}
        setHasPartner={setHasPartner}
        onSubmit={onSubmit}
        onSetIsMainUserStudent={onSetIsMainUserStudent}
        onSetIsMainUserSenior={onSetIsMainUserSenior}
        isSingleRegistration={activity.singleRegistration}
      />
      {hasPartner || state.obfuscatedEnrollment.partnerUser?.phone ? (
        <CourseRegistrationStepOnePartnerForm
          state={state}
          client={client}
          formProps={formProps}
          partnerRole={partnerRole}
          onNextStep={onNextStep}
          changeRole={(isPartnerRole, newRole) => changeRole(isPartnerRole, newRole)}
          handleRemovePartner={() => handleRemovePartner(formProps)}
          onSubmit={onSubmit}
          onSetIsPartnerStudent={onSetIsPartnerStudent}
          onSetIsPartnerSenior={onSetIsPartnerSenior}
        />
      ) : null}
    </>
  )
}

export default memo(CourseRegistrationStepOneOpenedContent)
