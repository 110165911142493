const BorderRadixes = {
  small: '4px',
  regular: '8px',
  moderate: '12px',
  button: '16px',
  big: '32px',
  circle: '45px'
}

export { BorderRadixes }
