import { Client } from '../../pure-js/types/GrooverTypes'

// src/config/themeConfig.ts
export interface ThemeConfig {
  backgroundColor: string
  borderColor: string
  textColor: string
  primaryColor: string
  secondaryColor: string
  activityBoxBorder: string
  activityBoxBackground: string
  activityHover: string
  courseBGColor: string
  occationBGColor: string
  otherBGColor: string
}

export const defaultTheme: ThemeConfig = {
  backgroundColor: 'rgba(224, 217, 254, 0.03)',
  borderColor: '#e0e0e0',
  textColor: '#fff',
  primaryColor: '#2A569D',
  secondaryColor: '#1D5E11',
  activityBoxBorder: '#e0e0e0',
  activityBoxBackground: 'transparent',
  activityHover: 'rgba(108, 108, 158, 0.9)',
  courseBGColor: '#124B8D',
  occationBGColor: '#076712',
  otherBGColor: '#550767'
}

export let themeConfig: ThemeConfig = { ...defaultTheme }

// Add this function
export const getThemeConfig = () => themeConfig

export const setThemeConfig = (client: Client) => {
  themeConfig = {
    backgroundColor: client.themeConfig?.backgroundColor || defaultTheme.backgroundColor,
    borderColor: client.themeConfig?.borderColor || defaultTheme.borderColor,
    textColor: client.themeConfig?.textColor || defaultTheme.textColor,
    primaryColor: client.themeConfig?.primaryColor || defaultTheme.primaryColor,
    secondaryColor: client.themeConfig?.secondaryColor || defaultTheme.secondaryColor,
    activityBoxBorder: client.themeConfig?.activityBoxBorder || defaultTheme.activityBoxBorder,
    activityBoxBackground: client.themeConfig?.activityBoxBackground || defaultTheme.activityBoxBackground,
    activityHover: client.themeConfig?.activityHover || defaultTheme.activityHover,
    courseBGColor: client.themeConfig?.courseBGColor || defaultTheme.courseBGColor,
    occationBGColor: client.themeConfig?.occationBGColor || defaultTheme.occationBGColor,
    otherBGColor: client.themeConfig?.otherBGColor || defaultTheme.otherBGColor
  }
}
