import { Texts } from '../../../pure-js/libs/Texts'
import { EIGHT_PX, FOUR_PX, TWELVE_PX, TWO_PX } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { formatText, getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import iconCompleted from '/public/iconCompleted.svg'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Activity, PaymentMethod, VatData } from '../../../pure-js/types/GrooverTypes'
import ActivityDetails from './ActivityDetails'
import { useClient } from '../hooks/useClient'
import QRCodeElement from '../components/QRCodeElement'
import { getQRCode, QRRequestVisit } from '../../../pure-js/libs/QRHelper'
import useIsMobile from '../hooks/useIsMobile'
import CourseRegistrationReceipt from './CourseRegistrationReceipt'
import useDiscountCalculations from '../hooks/useDiscountCalculations'
import { getVatFromBruttoPrice } from '../../../pure-js/libs/EnrollmentHelper'
import { useEnrollment } from '../libs/ReactQueryHooks'
import plusIcon from '/public/plusIcon.svg'
import penIcon from '/public/penIcon.svg'
import printIcon from '../../public/printIcon.svg'
import { Controller } from 'react-hook-form'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { SsnValidationSchema } from './CourseRegistrationStepFour'
import { useForm } from '../hooks/useForm'
import { ContinueButton } from './StyledComponents'
import iconArrow from '/public/iconArrow.svg'
import { addSsnForEnrollmentUser } from '../libs/CloudFunctionsApiHandler'

type Props = {
  state: State
  activity: Activity
  onSetMainUserSsn: (mainUserSsn: string) => void
  onSetPartnerUserSsn: (partnerUserSsn: string) => void
}

const CourseRegistrationStepFiveOpenedContent = ({ state, activity, onSetMainUserSsn, onSetPartnerUserSsn }: Props) => {
  const isMobile = useIsMobile()
  const client = useClient(activity)
  const [expandedActivityId, setExpandedActivityId] = useState<string | null>(null)
  const [vatData, setVatData] = useState<VatData>({})
  const [finalPrice, setFinalPrice] = useState(0)
  const { data: enrollment } = useEnrollment(state)
  const receiptRef = useRef<any>(null)
  const [showPersonalNumberInput, setShowPersonalNumberInput] = useState(false)
  const [isLoadingSsn, setIsLoadingSsn] = useState(false)

  const googleMapsUrl = useMemo(
    () => client?.locations?.find((location) => location?.id === activity?.locationId)?.googleUrl,
    [client?.locations, activity?.locationId]
  )

  const onOpenGoogleMaps = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (googleMapsUrl) {
        window.open(googleMapsUrl, '_blank')
      }
    },
    [googleMapsUrl]
  )

  const { mainUserPrices, partnerUserPrices } = useDiscountCalculations({
    activity,
    client,
    state
  })

  const handleDownloadPDF = async () => {
    const element = receiptRef.current
    const canvas = await html2canvas(element, { scale: 2 })
    const imgData = canvas.toDataURL('image/png')

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    })

    const imgWidth = 190 // A4 width in mm minus margins
    const imgHeight = (canvas.height * imgWidth) / canvas.width

    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight)
    pdf.save(`${client?.name}_Course_Registration_Receipt.pdf`)
  }

  const handleAddSsn = (ssn: string, userType: 'mainUser' | 'partnerUser') => {
    setIsLoadingSsn(true)

    if (userType === 'mainUser') {
      onSetMainUserSsn(ssn)
    } else if (userType === 'partnerUser') {
      onSetPartnerUserSsn(ssn)
    }

    addSsnForEnrollmentUser(
      {
        clientId: client?.id as string,
        enrollmentId: state.obfuscatedEnrollment?.id ? state.obfuscatedEnrollment?.id : state.enrollmentId,
        ssn: ssn,
        userType: userType
      },
      state
    )
      .then(() => {
        console.log('SSN added')
        setIsLoadingSsn(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoadingSsn(false)
      })
      .finally(() => {
        setIsLoadingSsn(false)
      })
  }

  useEffect(() => {
    if (!state.obfuscatedEnrollment.priceDetails) return
    let _finalPrice = 0
    const _vatData = {}
    for (const pd of state.obfuscatedEnrollment.priceDetails) {
      const activity = state.obfuscatedEnrollment.activities.find((a) => a.id === pd.activityId)
      const price = pd.finalPrice / 100
      if (activity) {
        const { vat } = activity
        _finalPrice += price
        if (!_vatData[vat]) _vatData[vat] = 0
        _vatData[vat] += getVatFromBruttoPrice(price, vat)
        _vatData[vat] = Math.round(_vatData[vat] * 100) / 100
      }
    }
    setFinalPrice(_finalPrice)
    setVatData(_vatData)
  }, [state.obfuscatedEnrollment])

  const ssnFormProps = useForm<{ mainUserSsn: string; partnerUserSsn: string }>(SsnValidationSchema, {
    defaultValues: {
      mainUserSsn: '',
      partnerUserSsn: ''
    },
    mode: 'all', // This enables validation on blur
    reValidateMode: 'onChange' // This re-validates on change after initial
  })

  return (
    <>
      <Box direction="row" gap="15px" bottom spacing={FOUR_PX}>
        {state.isRegistrationCompleted && <img src={iconCompleted} alt="Completed icon" width={24} height={24} />}
        <FigmaText
          textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderFinalSummary)}
        />
      </Box>

      {!state.isPaid && state.paymentMethod === PaymentMethod.BankTransfer && (
        <InfoContainer fullWidth align="center" justify="center" gap={EIGHT_PX}>
          <FigmaText textKey={Texts.registrationStep5PaymentWaitingBankInfo} />
          <Box direction="row" align="center" gap={TWO_PX}>
            <FigmaText
              textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewAmount}
              text={formatText(getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewAmount), [
                state?.finalPrice.split(' ')[0]
              ])}
            />
            <FigmaText
              textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewAccountNumber}
              text={formatText(getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewAccountNumber), [
                client?.bankGiro
              ])}
            />
            <FigmaText
              textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewRef}
              text={formatText(getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewRef), [
                state?.obfuscatedEnrollment?.ocr ? state?.obfuscatedEnrollment?.ocr : enrollment?.ocr
              ])}
            />
          </Box>
        </InfoContainer>
      )}

      {state.isPaid && (
        <Box fullWidth direction={isMobile ? 'column' : 'row'}>
          <Box fullWidth gap={FOUR_PX}>
            <FigmaText textKey={Texts.registrationStep6SummarySummaryWelcomeToCourses} />
            {state.selectedActivities.map((activity) => (
              <ActivityDetails
                key={activity.id}
                activity={activity}
                expanded={expandedActivityId === activity.id}
                onExpand={() => setExpandedActivityId(activity.id === expandedActivityId ? null : activity.id)}
                onOpenGoogleMaps={onOpenGoogleMaps}
              />
            ))}
          </Box>
          <Box fullWidth align="center" gap={TWELVE_PX} top={isMobile}>
            <FigmaText textKey={Texts.registrationStep6SummaryQrCodeLabel} />
            <QRCodeElement
              value={getQRCode({
                type: 'visit',
                data: { enrollmentId: state.obfuscatedEnrollment?.id || state.enrollmentId }
              } as QRRequestVisit)}
              showText={false}
            />
          </Box>
        </Box>
      )}
      {state.isPaid && (
        <>
          <DownloadButton fullWidth direction="row" align="center" justify="space-between" top>
            <FigmaText
              textKey={Texts.registrationStep5PaymentReceiptHeader}
              text={getFigmaText(Texts.registrationStep5PaymentReceiptHeader)}
            />
            <Box direction="row" align="center" gap={TWELVE_PX}>
              {!state.mainUserSsn ? (
                <Box
                  direction="row"
                  align="center"
                  gap={EIGHT_PX}
                  onClick={() => setShowPersonalNumberInput(!showPersonalNumberInput)}
                >
                  <img src={plusIcon} width={16} height={16} />
                  <FigmaText textKey={Texts.registrationCtactaAddPersonalNumber} />
                </Box>
              ) : (
                <Box
                  direction="row"
                  align="center"
                  gap={EIGHT_PX}
                  onClick={() => setShowPersonalNumberInput(!showPersonalNumberInput)}
                >
                  <img src={penIcon} width={16} height={16} />
                  <FigmaText textKey={Texts.registrationCtactaEditPersonalNumber} />
                </Box>
              )}
              <Box direction="row" align="center" gap={EIGHT_PX} onClick={handleDownloadPDF}>
                <img src={printIcon} width={16} height={16} />
                <FigmaText textKey={Texts.registrationCtactaDownloadReceipt} />
              </Box>
            </Box>
          </DownloadButton>
          {showPersonalNumberInput && (
            <>
              <Box fullWidth direction="row" align="center" justify="space-between" top spacing={FOUR_PX} gap={FOUR_PX}>
                <Controller
                  control={ssnFormProps.control}
                  name="mainUserSsn"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      placeholderTextKey={Texts.registrationStep5PaymentInputAddPersonalNumberHelper}
                      placeholder={formatText(
                        getFigmaText(Texts.registrationStep5PaymentInputAddPersonalNumberHelper),
                        [
                          // @ts-ignore
                          state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                            ? // @ts-ignore
                              state.obfuscatedEnrollment?.mainUser?.nonObfuscatedName
                            : state.name
                        ]
                      )}
                    />
                  )}
                />
                <ContinueButton
                  isDisabled={isLoadingSsn}
                  disabled={isLoadingSsn}
                  onClick={() => handleAddSsn(ssnFormProps.getValues('mainUserSsn'), 'mainUser')}
                >
                  <FigmaText textKey={Texts.registrationCtactaContinue} />
                  <img src={iconArrow} width={16} height={16} />
                </ContinueButton>
              </Box>
              {(!!state.partnerPhoneNumber || state.obfuscatedEnrollment?.partnerUser?.phone) && (
                <Box
                  fullWidth
                  direction="row"
                  align="center"
                  justify="space-between"
                  bottom
                  spacing={FOUR_PX}
                  gap={FOUR_PX}
                >
                  <Controller
                    control={ssnFormProps.control}
                    name="partnerUserSsn"
                    render={({ field, fieldState }) => (
                      <TextFieldWithLabelForm
                        {...field}
                        value={field.value}
                        fullWidth
                        fieldState={fieldState}
                        placeholderTextKey={Texts.registrationStep5PaymentInputAddPersonalNumberHelper}
                        placeholder={formatText(
                          getFigmaText(Texts.registrationStep5PaymentInputAddPersonalNumberHelper),
                          [
                            // @ts-ignore
                            state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                              ? // @ts-ignore
                                state.obfuscatedEnrollment?.partnerUser?.nonObfuscatedName
                              : state.partnerName
                          ]
                        )}
                      />
                    )}
                  />
                  <ContinueButton
                    isDisabled={isLoadingSsn}
                    disabled={isLoadingSsn}
                    onClick={() => handleAddSsn(ssnFormProps.getValues('partnerUserSsn'), 'partnerUser')}
                  >
                    <FigmaText textKey={Texts.registrationCtactaContinue} />
                    <img src={iconArrow} width={16} height={16} />
                  </ContinueButton>
                </Box>
              )}
            </>
          )}
        </>
      )}
      {state.isPaid && (
        <CourseRegistrationReceipt
          ref={receiptRef}
          mainUserCalculations={mainUserPrices}
          partnerUserCalculations={partnerUserPrices}
          state={state}
          vatData={vatData}
          finalPrice={finalPrice}
          obfuscatedEnrollment={enrollment}
        />
      )}
    </>
  )
}

const InfoContainer = styled(Box)`
  padding: 10px;
  background-color: rgb(243, 250, 194);
`

const DownloadButton = styled(Box)`
  cursor: pointer;
`

export default memo(CourseRegistrationStepFiveOpenedContent)
