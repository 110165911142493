import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { TextField, MenuItem, IconButton } from '@mui/material'
import styled from '@emotion/styled'
import { Activity, Client, GroupByOption } from '../../../pure-js/types/GrooverTypes'
import { getActivitiesByCustomerId, getClient } from '../libs/DBApiHandler'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { ActivityCard } from '../components/activities/ActivityCard'
import { filterByDanceStyle, groupAndSortActivities } from '../utils/activityUtils'
import Box from '../components/Box'
import FigmaText from '../components/FigmaText'
import { GROUP_BY_OPTIONS, CONTAINER_MAX_WIDTH } from '../config/activity.config'
import { themeConfig, setThemeConfig } from '../themeConfig'
import GridViewIcon from '@mui/icons-material/GridView'
import ViewListIcon from '@mui/icons-material/ViewList'

// Utility function for mobile detection
const isMobile = () => window.innerWidth <= 768

export const PublicActivitiesPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // Extract filter values from the URL params
  const searchText = searchParams.get('filter') ?? ''
  const searchGroup = (searchParams.get('group') as GroupByOption) ?? 'style'
  const cityName = searchParams.get('cityName') ?? ''
  const danceStyle = searchParams.get('danceStyle') ?? ''
  const activityType = searchParams.get('activityType') ?? ''
  const groupSorting = searchParams.get('groupSorting') ?? 'startdate'
  const viewMode = searchParams.get('viewMode') ?? 'grid'
  const hideFilter = searchParams.get('hideFilter') === 'true'

  // State Variables
  const [activities, setActivities] = useState<Activity[]>([])
  const [filterText, setFilterText] = useState(searchText)
  const [groupBy, setGroupBy] = useState<GroupByOption>(searchGroup)
  const [viewType, setViewType] = useState<'list' | 'grid'>(viewMode as 'list' | 'grid')
  const [client, setClient] = useState<Client | null>(null)
  const [isMobileView, setIsMobileView] = useState(isMobile())

  useEffect(() => {
    const fetchData = async () => {
      const [activitiesResult, clientResult] = await Promise.all([
        getActivitiesByCustomerId(clientId || ''),
        getClient(clientId || '')
      ])

      setActivities(activitiesResult.filter((a: Activity) => a.status === 'active'))

      if (clientResult) {
        setClient(clientResult)
        setThemeConfig(clientResult)
      }
    }
    fetchData()
  }, [clientId])

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isMobile())
      if (isMobile()) setViewType('list')
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Apply search filters and grouping
  const filteredActivities = useMemo(() => {
    let filtered = activities

    if (filterText) {
      filtered = filtered.filter((activity) => activity.name.toLowerCase().includes(filterText.toLowerCase()))
    }

    if (danceStyle) {
      filtered = filterByDanceStyle(filtered, danceStyle)
    }

    if (activityType) {
      filtered = filtered.filter((activity) => activity.type === activityType)
    }

    return filtered
  }, [activities, filterText, danceStyle, activityType])

  const groupedActivities = useMemo(() => {
    return groupAndSortActivities(filteredActivities, groupBy, groupSorting, cityName, client as Client)
  }, [filteredActivities, groupBy, groupSorting, cityName, client])

  // Handle filter text change
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilterText(value)
    setSearchParams({ filter: value, group: groupBy, cityName, danceStyle, activityType, groupSorting })
  }

  // Handle group change
  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value as GroupByOption
    setGroupBy(value)
    setSearchParams({ filter: filterText, group: value, cityName, danceStyle, activityType, groupSorting })
  }

  const renderSection = (groupKey: string, subGroups: { [key: string]: Activity[] }) => {
    return (
      <Section key={`section-${groupKey}`} fullWidth>
        <FigmaText text={groupKey} textKey={Texts.externalActivityPageSectionHeader} />

        {Object.entries(subGroups).map(([subGroupKey, activities]) => (
          <div key={`sub-section-${subGroupKey}`}>
            {groupBy !== groupSorting && (
              <FigmaText
                styleTextKey={Texts.componentsExternalCourseExtCourseColStartDate}
                text={subGroupKey}
                textKey={Texts.externalActivityPageSectionHeader}
              />
            )}

            <ActivityGrid viewType={viewType}>
              {activities.map((activity) => (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  onClick={() => {
                    if (client?.openActivityInNewTab) {
                      window.open(`/activity/${activity.id}`, '_blank')
                      return
                    }

                    return navigate(`/activity/${activity.id}`, {
                      state: { from: 'PublicActivitiesPage' }
                    })
                  }}
                />
              ))}
            </ActivityGrid>
          </div>
        ))}
      </Section>
    )
  }

  useEffect(() => {
    let hasAdjustedInitialHeight = false // Prevents infinite loop

    const sendHeightToParent = () => {
      const height = document.documentElement.scrollHeight || document.body.scrollHeight

      if (!hasAdjustedInitialHeight) {
        hasAdjustedInitialHeight = true // Mark as adjusted
        window.parent.postMessage({ type: 'adjustHeight', height: height + 10 }, '*') // Add buffer initially
      } else {
        window.parent.postMessage({ type: 'adjustHeight', height }, '*') // Normal height updates
      }

      console.log(`📤 Sending height: ${height}px to parent`)
    }

    // Trigger on load and resize
    sendHeightToParent()
    window.addEventListener('resize', sendHeightToParent)

    const observer = new MutationObserver(sendHeightToParent)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      window.removeEventListener('resize', sendHeightToParent)
      observer.disconnect()
    }
  }, [activities, filterText, danceStyle, groupBy])

  return (
    <Container fullWidth>
      {!hideFilter && (
        <FilterContainer direction="row" fullWidth mobileColumn>
          <StyledTextField
            label={getFigmaText(Texts.adminGeneralSearchHelperText)}
            value={filterText}
            onChange={handleFilterChange}
            fullWidth
          />
          <StyledTextField
            select
            label={getFigmaText(Texts.adminGeneralInputFieldsGroupByLabel)}
            value={groupBy}
            onChange={handleGroupChange}
          >
            {GROUP_BY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {getFigmaText(Texts[option.label])}
              </MenuItem>
            ))}
          </StyledTextField>
          <ViewToggleContainer>
            <IconButton
              onClick={() => {
                setViewType('list')
                setSearchParams({
                  filter: filterText,
                  group: groupBy,
                  cityName,
                  danceStyle,
                  activityType,
                  viewMode: 'list'
                }) // Added activityType
              }}
              color={viewType === 'list' ? 'primary' : 'default'}
            >
              <ViewListIcon />
            </IconButton>
            {!isMobileView && (
              <IconButton
                onClick={() => {
                  setViewType('grid')
                  setSearchParams({
                    filter: filterText,
                    group: groupBy,
                    cityName,
                    danceStyle,
                    activityType,
                    viewMode: 'grid'
                  }) // Added activityType
                }}
                color={viewType === 'grid' ? 'primary' : 'default'}
              >
                <GridViewIcon />
              </IconButton>
            )}
          </ViewToggleContainer>
        </FilterContainer>
      )}
      <ActivitiesContainer fullWidth>
        {Object.entries(groupedActivities).map(([groupKey, subGroups]) => renderSection(groupKey, subGroups))}
      </ActivitiesContainer>
    </Container>
  )
}

// Styled Components
const Container = styled(Box)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding: 20px;
  background: ${themeConfig.backgroundColor};
`
const FilterContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`
const StyledTextField = styled(TextField)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  min-width: 200px;
`
const ActivitiesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`
const Section = styled(Box)`
  margin-bottom: 24px;
  font-size: 16px;
`
const ViewToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
interface ActivityGridProps {
  viewType: 'list' | 'grid'
}
const ActivityGrid = styled.div<ActivityGridProps>`
  display: flex;
  flex-wrap: ${(props) => (props.viewType === 'grid' ? 'wrap' : 'nowrap')};
  flex-direction: ${(props) => (props.viewType === 'grid' ? 'row' : 'column')};
  gap: 2px;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;

    & > * {
      flex: 1;
      max-width: 100%;
    }
  }

  ${(props) =>
    props.viewType === 'grid' &&
    `
    & > * {
      flex: 0 0 280px;
      max-width: 280px;
    }
  `}
`
