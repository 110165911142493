import { doc, getDoc, setDoc, query, collection, where, getDocs } from 'firebase/firestore'
import { Collections } from '../../../pure-js/libs/Collections.js'
import { mapQueryResponse } from '../../../pure-js/libs/FirebaseStorageClientBaseHelper.js'
import { PartialUser, User } from '../../../pure-js/types/User.js'
import { Id } from '../../../pure-js/types/types.js'
import { db } from './Firebase.js'
import { Activity, ActivityStatus, Client, UrlShortHash } from '../../../pure-js/types/GrooverTypes.js'
import { useFirestoreQuery } from '../hooks/useFirestoreQuery.js'
import { createUniqueId } from '../../../pure-js/libs/Common.js'
import { StripeConfig } from '../../../pure-js/types/StripeTypes.js'
import invariant from 'invariant'

export const updateUser = (user: PartialUser): Promise<unknown> =>
  setDoc(doc(db, `${Collections.USERS}/${user.id}`), user, { merge: true })

export const getUser = (id: Id): Promise<User | undefined> =>
  getDoc(doc(db, `${Collections.USERS}/${id}`)).then(mapQueryResponse)

export const upsertActivity = (o: Activity): Promise<unknown> =>
  setDoc(doc(db, `${Collections.ACTIVITIES}/${o.id}`), o, { merge: true })

export const getActivity = async (id: Id): Promise<Activity | undefined> => {
  const activity = await getDoc(doc(db, `${Collections.ACTIVITIES}/${id}`)).then(mapQueryResponse)
  if (activity?.status === ActivityStatus.Deleted) return undefined
  return activity
}

export const upsertClient = (o: Client): Promise<unknown> =>
  setDoc(doc(db, `${Collections.CLIENTS}/${o.id}`), o, { merge: true })

export const getClient = (id: Id): Promise<Client | undefined> =>
  getDoc(doc(db, `${Collections.CLIENTS}/${id}`)).then(mapQueryResponse)

export const getStripeConfig = async (client: Client): Promise<StripeConfig> => {
  invariant(client.stripe, `getStripeConfig: Client ${client.id} is missing client.stripe config`)
  return getDoc(
    doc(db, `${Collections.STRIPE_CONFIG}/${client.stripe.operator}/frontend/${client.stripe.environment}`)
  ).then(mapQueryResponse)
}

export const getActivitiesByCustomerId = async (clientId: string): Promise<Activity[]> => {
  const q = query(collection(db, Collections.ACTIVITIES), where('clientId', '==', clientId))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs
    .map((doc) => doc.data() as Activity)
    .filter((activity) => activity.status !== ActivityStatus.Deleted)
}

export const useSwishPaymentIntentStatus = (instructionUUID: string) =>
  useFirestoreQuery(
    // createUniqueId() is needed here to make query working with no instructionUUID
    doc(db, Collections.SWISH_PAYMENT_INTENT_STATUS, instructionUUID || createUniqueId()),
    {
      fnName: 'getSwishPaymentIntentStatus',
      enabled: !!instructionUUID // run query only when instructionUUID is available
    }
  )

export const useEnrollmentPublic = (enrollmentId: string) =>
  useFirestoreQuery(
    // createUniqueId() is needed here to make query working with no enrollmentId
    doc(db, Collections.ENROLLMENTS_PUBLIC, enrollmentId || `dummy-${createUniqueId()}`),
    {
      fnName: 'useEnrollmentPublic',
      enabled: !!enrollmentId // run query only when enrollmentId is available
    }
  )

export const getClientActivities = async (currentActivityClientId: string | undefined) => {
  const activitiesQuery = await getDocs(collection(db, Collections.ACTIVITIES))
  const activities = activitiesQuery.docs.map((doc) => doc.data() as Activity)

  return activities.filter((activity) => activity.clientId === currentActivityClientId && activity.status == 'active')
}

export const getUrlShort = async (hash: string): Promise<UrlShortHash> => {
  return getDoc(doc(db, `${Collections.URL_SHORT}/${hash}`)).then(mapQueryResponse)
}
