import React from 'react'
import { Button, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import styled from '@emotion/styled'
import Box from './Box'

interface ActivityInfoProps {
  activity: Activity
  client?: Client
}

const RegistrationInfo: React.FC<ActivityInfoProps> = ({ activity, client }) => {
  let imageUrl
  if (activity.bannerUrl) {
    imageUrl = activity.bannerUrl
  } else if (client) {
    if (client.logoFullUrl) {
      imageUrl = client.logoFullUrl
    } else if (client.logoUrl) {
      imageUrl = `/assets/logos/${client.logoUrl}`
    }
  }

  return (
    <div className="segment-container">
      <div className="header">
        {imageUrl && <ActivityBanner src={imageUrl} alt={activity.name} />}
        <Typography variant="h5" component="h2" className="header-title">
          {activity.name}
        </Typography>
      </div>

      {/* Button with Arrow Icon */}
      <Box fullWidth direction="row">
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          className="action-button"
          href={`/register/${activity.id}`}
        >
          Anmäl mig/oss
        </Button>
      </Box>

      {/* Description Text */}
      <Typography variant="subtitle1" className="description">
        <div dangerouslySetInnerHTML={{ __html: activity.description }} />
      </Typography>
    </div>
  )
}

const ActivityBanner = styled.img`
  max-width: 100%; /* Ensures the logo doesn't exceed the container's width */
  height: auto; /* Maintains aspect ratio */
  padding-bottom: 20px; /* Adds the desired spacing */
  display: block; /* Centers the logo if used with margin */
  margin: 0 auto; /* Centers horizontally */
  border-radius: 6px;
`

export default RegistrationInfo
