import React, { ReactElement } from 'react'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { getFigmaText } from '../libs/TextRepository'
import { Skeleton } from '@mui/material'

export type FigmaTextProps = {
  textKey?: TextKey
  styleTextKey?: TextKey
  className?: string
  text?: string
  onClick?: () => unknown
  link?: boolean
  anchorHref?: string
  textTransform?: 'uppercase'
  separator?: ReactElement
  separatorWhenEmptyContent?: ReactElement
  lineHeight?: number
}

const FigmaText = (props: React.PropsWithChildren<FigmaTextProps>) => {
  const { text: _text, styleTextKey, textKey, className, link, anchorHref, textTransform } = props
  let { style } = styleTextKey || textKey || {}

  if (!style) return null
  style = mapStyle(style, link)

  let text = typeof _text === 'string' ? _text : (textKey && getFigmaText(textKey)) || ''
  if (textTransform === 'uppercase') text = text.toUpperCase()
  if (anchorHref)
    return (
      <a href={anchorHref} style={style} dangerouslySetInnerHTML={{ __html: text }} target="_blank" rel="noreferrer" />
    )

  return <span dangerouslySetInnerHTML={{ __html: text }} className={className} style={style} />
}

export type FigmaTextOrSkeletonProps = FigmaTextProps & {
  isLoading?: boolean
}

export const FigmaTextOrSkeleton = (props: FigmaTextOrSkeletonProps) => {
  const { isLoading, ...rest } = props
  if (isLoading) {
    return (
      <SkeletonCustom>
        <FigmaText {...rest} />
      </SkeletonCustom>
    )
  }
  return <FigmaText {...rest} />
}

export const SkeletonCustom = ({ children }: { children: React.ReactNode }) => {
  return (
    <Skeleton
      animation="wave"
      sx={{
        '&::after': {
          animationDuration: '1s',
          background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.2), transparent)'
        }
      }}
    >
      {children}
    </Skeleton>
  )
}

const mapStyle = (style, link) => {
  if (link) return { ...style, textDecoration: 'underline', pointer: 'cursor' }
  return style
}

export default FigmaText
