import * as Yup from 'yup'
import { isValidNumber } from '../../../pure-js/libs/PhoneHelper'
import { DanceRoles } from '../../../pure-js/types/DanceRoles'

export const PhoneRegExp = /^\+?\d{1,3}\d{7,10}$/
export const EMAIL_REGEX = /\S+@\S+\.\S+/
export const SSN_REGEX = /^(?:\d{2})?\d{6}-?\d{4}$/

export const isValidEmail = (email: string) => {
  try {
    return EMAIL_REGEX.test(email)
  } catch (err) {
    console.log(err)
    return false
  }
}

export type CourseRegistrationFormValues = {
  name: string
  phoneNumber: string
  email: string
  role: DanceRoles
  partnerName: string
  partnerPhoneNumber: string
  partnerEmail: string
  partnerRole: DanceRoles
  mainUserSsn: string
  partnerUserSsn: string
}

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required()
})

export const ValidationSchemaSsn = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required(),
  mainUserSsn: Yup.string()
    .transform((value) => (value === '' ? undefined : value))
    .notRequired()
    .matches(SSN_REGEX, 'Personnummer är ogiltigt')
    .nullable()
})

export const ValidationSchemaPartner = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required(),
  mainUserSsn: Yup.string(),
  partnerName: Yup.string().required('Namn är obligatoriskt'),
  partnerPhoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  partnerEmail: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  partnerRole: Yup.string().required()
})

export const ValidationSchemaPartnerSsn = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required(),
  mainUserSsn: Yup.string()
    .transform((value) => (value === '' ? undefined : value))
    .notRequired()
    .matches(SSN_REGEX, 'Personnummer är ogiltigt')
    .nullable(),
  partnerName: Yup.string().required('Namn är obligatoriskt'),
  partnerPhoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    }),
  partnerEmail: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  partnerRole: Yup.string().required(),
  partnerUserSsn: Yup.string()
    .transform((value) => (value === '' ? undefined : value))
    .notRequired()
    .matches(SSN_REGEX, 'Personnummer är ogiltigt')
    .nullable()
})
