import styled from '@emotion/styled'
import { Radio } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import * as stripeJs from '@stripe/stripe-js'
import { memo, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { getPhoneForSwish, isValidNumber } from '../../../pure-js/libs/PhoneHelper'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, EnrollmentPublic, PaymentMethod, PaymentMethodFee } from '../../../pure-js/types/GrooverTypes'
import { CreateStripePaymentIntentWebRequest, StripeConfig } from '../../../pure-js/types/StripeTypes'
import { SwishPaymentStatus } from '../../../pure-js/types/SwishTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import {
  EIGHT_PX,
  FOUR_PX,
  SIX_PX,
  SIXTEEN_PX,
  THIRTY_TWO_PX,
  TWELVE_PX,
  TWENTY_FOUR_PX,
  TWO_PX
} from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { useClient } from '../hooks/useClient'
import useDiscountCalculations from '../hooks/useDiscountCalculations'
import { useForm } from '../hooks/useForm'
import {
  cancelSwishPaymentIntent,
  createStripePaymentIntent,
  createSwishPaymentIntent
} from '../libs/CloudFunctionsApiHandler'
import { getStripeConfig, useEnrollmentPublic, useSwishPaymentIntentStatus } from '../libs/DBApiHandler'
import getStripe from '../libs/getStripe'
import { useEnrollment } from '../libs/ReactQueryHooks'
import { formatText, getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import StripeCheckoutForm from './StripeCheckoutForm'
import { AbortButton, ClosedContainer, ContinueButton, OpenContainer } from './StyledComponents'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import iconCompleted from '/iconCompleted.svg'
import bankIcon from '/public/bankIcon.svg'
import cards from '/public/cards.svg'
import iconArrow from '/public/iconArrow.svg'
import iconArrowLeft from '/public/iconArrowLeft.svg'
import swishBigLogo from '/public/swishBigLogo.svg'
import swishIcon from '/public/swishIcon.svg'
import { getTotalUnpaidPaymentsAmount } from '../../../pure-js/libs/EnrollmentHelper'

type Props = {
  state: State
  activity?: Activity
  onNextStep: () => void
  onOpenStepFour: () => void
  registerActiveStep?: number
  onCompleteRegistration: () => void
  onSetPaymentMethod: (paymentMethod: PaymentMethod) => void
  onSetIsPaid: (isPaid: boolean) => void
  onSetMainUserSsn: (mainUserSsn: string) => void
  onSetPartnerUserSsn: (partnerUserSsn: string) => void
}

export const ValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Telefonnummer är obligatoriskt')
    .test('is-valid-phone', 'Telefonnummer är ogiltigt', (value) => {
      if (!value) return false
      return isValidNumber(value)
    })
})

export const SsnValidationSchema = Yup.object().shape({
  mainUserSsn: Yup.string().required('Personnummer är obligatoriskt'),
  partnerUserSsn: Yup.string().optional()
})

const CourseRegistrationStepFour = ({
  state,
  activity,
  onOpenStepFour,
  onNextStep,
  registerActiveStep,
  onCompleteRegistration,
  onSetPaymentMethod,
  onSetIsPaid
}: Props) => {
  const SWISH_PAYMENT_STATUS_PENDING = 'PENDING'

  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [swishInstructionUUID, setSwishInstructionUUID] = useState('')
  const [enabledPaymentMethods, setEnabledPaymentMethods] = useState<PaymentMethodFee[]>([])
  const [swishPaymentIntentStatus, setSwishPaymentIntentStatus] = useState(SWISH_PAYMENT_STATUS_PENDING)
  const [stripeConfig, setStripeConfig] = useState<StripeConfig>()
  const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(null)
  const [stripeOptions, setStripeOptions] = useState<stripeJs.StripeElementsOptions>()

  const { data: enrollment, refetch: refetchEnrollment } = useEnrollment(state)

  const client = useClient(activity!)

  // initialize payment monitoring (by default with empty string, will use real instructionUUID as soon as we have it)
  let swishPaymentIntentMonitor = useSwishPaymentIntentStatus(swishInstructionUUID)

  const onHandleNext = () => {
    onNextStep()
    onCompleteRegistration()
  }

  // monitor enrollment public document
  const enrollmentPublicMonitor = useEnrollmentPublic(
    !state.enrollmentId ? state.obfuscatedEnrollment?.id : state.enrollmentId
  )

  useEffect(() => {
    if (!enrollmentPublicMonitor.data) return
    if (enrollmentPublicMonitor.status == 'success') {
      // if monitor listening is not error
      const { paymentStatus } = enrollmentPublicMonitor.data as EnrollmentPublic
      if (paymentStatus === 'paid') {
        // check if enrollment is paid
        console.log('enrollment is paid')
        refetchEnrollment()
        onSetIsPaid(true)
      }
    }
  }, [state.enrollmentId, enrollmentPublicMonitor.data])

  useEffect(() => {
    if (state.isPaid) {
      onCompleteRegistration()
    }
  }, [state.isPaid])

  useEffect(() => {
    if (!client) return

    // if payment is below 3 SEK, disable Stripe
    // https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
    let availablePaymentMethods = client.paymentMethodFees

    if (Number(state.finalPrice.split(' ')[0]) < 3) {
      availablePaymentMethods = client.paymentMethodFees.filter((pm) => pm.method !== PaymentMethod.Stripe)
    }

    if (Number(state.finalPrice.split(' ')[0]) <= 0) {
      availablePaymentMethods = client.paymentMethodFees.filter(
        (pm) => pm.method !== PaymentMethod.Stripe && pm.method !== PaymentMethod.Swish
      )
    }

    setEnabledPaymentMethods(availablePaymentMethods)
  }, [client, state.finalPrice])

  useEffect(() => {
    const setupStripe = async () => {
      if (!client || (!state.enrollmentId && !state.obfuscatedEnrollment?.id)) return
      // if already paid, no need to setup stripe
      if (state.isPaid) return

      // is stripe enabled as payment method for this client?
      if (!enabledPaymentMethods.some((pm) => pm.method == PaymentMethod.Stripe)) return

      // check if enrollment is paid
      if (state.obfuscatedEnrollment?.id) {
        const amount = getTotalUnpaidPaymentsAmount(state.obfuscatedEnrollment)
        if (amount < 3) return
      }

      // Stripe config
      const config = await getStripeConfig(client)
      setStripeConfig(config)

      // Stripe intent
      const request: CreateStripePaymentIntentWebRequest = {
        enrollmentId: !!state.obfuscatedEnrollment?.id ? state.obfuscatedEnrollment?.id : state.enrollmentId
      }
      const result = await createStripePaymentIntent(request, state)

      if ('isError' in result) {
        setHasError(true)
        console.error('Error creating Stripe payment intent:', result)
      } else {
        setStripeClientSecret(result.clientSecret)
      }
    }

    setupStripe()
  }, [client, state.enrollmentId, state.isPaid, state.obfuscatedEnrollment?.id, enabledPaymentMethods])

  useEffect(() => {
    if (!stripeClientSecret) return
    setStripeOptions({ clientSecret: stripeClientSecret })
  }, [stripeClientSecret])

  const formProps = useForm<{ phoneNumber: string }>(ValidationSchema, {
    defaultValues: {
      phoneNumber: ''
    },
    mode: 'all', // This enables validation on blur
    reValidateMode: 'onChange' // This re-validates on change after initial blur
  })

  const onSubmit = async (data: { phoneNumber: string }) => {
    setHasError(false)
    setIsLoading(true)
    const response = await createSwishPaymentIntent(
      {
        enrollmentId: !!state.obfuscatedEnrollment?.id ? state.obfuscatedEnrollment?.id : state.enrollmentId,
        payerPhone: getPhoneForSwish(data.phoneNumber)
      },
      state
    )
    if ('isError' in response) {
      setIsLoading(false)
      return setHasError(true)
    }
    setSwishInstructionUUID(response.instructionUUID)
    setIsLoading(false)
    setSwishPaymentIntentStatus(response.instructionUUID) // monitor swish payment status for just created payment
  }

  const onHandleCancelPayment = () => {
    cancelSwishPaymentIntent({ instructionUUID: swishInstructionUUID }, state)
    setSwishInstructionUUID('')
    setSwishPaymentIntentStatus(SWISH_PAYMENT_STATUS_PENDING)
    formProps.setValue('phoneNumber', '')
  }

  useEffect(() => {
    if (!swishPaymentIntentMonitor) return
    if (swishPaymentIntentMonitor.status == 'success') {
      // db listening is not error
      // @ts-ignore
      const { status } = swishPaymentIntentMonitor.data // get payment status data; need just the status
      if (status) {
        setSwishPaymentIntentStatus(status)
      }
    }
  }, [swishPaymentIntentMonitor.data])

  useEffect(() => {
    if (swishPaymentIntentStatus == SwishPaymentStatus.PAID) {
      console.log(`swish payment is ${swishPaymentIntentStatus}, will move forward to next step`)
      onCompleteRegistration()
    }
    if (swishPaymentIntentStatus == SwishPaymentStatus.ERROR) {
      setSwishInstructionUUID('') // this will allow us to come back to state where user need to enter the phone number
      setHasError(true)
    }
    if (
      [SwishPaymentStatus.CANCELLED, SwishPaymentStatus.DECLINED].includes(
        swishPaymentIntentStatus as SwishPaymentStatus
      )
    ) {
      setSwishInstructionUUID('') // this will allow us to come back to state where user need to enter the phone number
    }
  }, [swishPaymentIntentStatus])

  const { hasPartner } = useDiscountCalculations({
    activity,
    client,
    state
  })

  const onSelectPaymentMethod = (paymentMethod: PaymentMethod) => {
    if (!state.isPaid) {
      if (state.paymentMethod !== paymentMethod) {
        setHasError(false)
      }
      return onSetPaymentMethod(paymentMethod)
    }
  }

  return registerActiveStep !== 4 ? (
    <ClosedContainer
      onClick={
        state.isRegistrationCompleted || (!!state.obfuscatedEnrollment?.mainUser && state.isPaid)
          ? onOpenStepFour
          : undefined
      }
    >
      <Box fullWidth direction="row" align="center" gap="15px">
        {state.isRegistrationCompleted || (!!state.obfuscatedEnrollment?.mainUser && state.isPaid) ? (
          <img src={iconCompleted} width={24} height={24} />
        ) : null}
        <FigmaText
          textKey={
            state?.activeRegisterStep > 4
              ? Texts.registrationHeadersAndStatusHeaderCssDone
              : Texts.registrationHeadersAndStatusHeaderCssNotDone
          }
          text={getFigmaText(Texts.registrationHeadersAndStatusHeaderPrefPayment)}
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer>
      <FigmaText
        textKey={Texts.registrationHeadersAndStatusHeaderCssActive}
        text={getFigmaText(Texts.registrationHeadersAndStatusHeaderPrefPayment)}
      />
      {state.isPaid ? (
        <>
          <SuccessMessage fullWidth align="center" fullPadding spacing={SIXTEEN_PX}>
            <FigmaText textKey={Texts.registrationStep5PaymentPaymentCompleteStatus} />
          </SuccessMessage>

          <Box alignSelf="flex-end">
            <ContinueButton onClick={onNextStep}>
              <FigmaText textKey={Texts.registrationCtactaContinue} />
              <img src={iconArrow} width={16} height={16} />
            </ContinueButton>
          </Box>
        </>
      ) : null}
      {!state.isPaid ? (
        <>
          <GrayBox fullWidth fullPadding spacing={EIGHT_PX}>
            <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={SIXTEEN_PX}>
              <FigmaText textKey={Texts.componentsPricingControlPaymentTotalRow} />
              <FigmaText
                textKey={Texts.componentsPricingControlCssPaymentTotalAmount}
                text={
                  state.obfuscatedEnrollment?.priceDetails
                    ? String(
                        state.obfuscatedEnrollment?.priceDetails?.reduce((acc, pd) => acc + pd.finalPrice, 0) / 100
                      ) + ' SEK'
                    : enrollment
                      ? String(enrollment?.priceDetails?.reduce((acc, pd) => acc + pd.finalPrice, 0) / 100) + ' SEK'
                      : ''
                }
              />
            </Box>

            <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={SIX_PX}>
              <FigmaText
                textKey={Texts.componentsExternalCourseExtCourseColName}
                text={
                  state.obfuscatedEnrollment?.mainUser?.name ? state.obfuscatedEnrollment?.mainUser?.name : state.name
                }
              />
              <FigmaText
                textKey={Texts.componentsExternalCourseExtCourseColName}
                text={
                  String(
                    state.obfuscatedEnrollment?.priceDetails
                      ? state.obfuscatedEnrollment?.priceDetails
                          .filter((pd) => pd.appliesTo == 'mainUser')
                          .reduce((acc, pd) => acc + pd.finalPrice, 0) / 100
                      : enrollment
                        ? enrollment?.priceDetails
                            .filter((pd) => pd.appliesTo == 'mainUser')
                            .reduce((acc, pd) => acc + pd.finalPrice, 0) / 100
                        : ''
                  ) + ' SEK'
                }
              />
            </Box>
            {hasPartner && (
              <Box fullWidth direction="row" align="center" justify="space-between" bottom spacing={SIX_PX}>
                <FigmaText
                  textKey={Texts.componentsExternalCourseExtCourseColName}
                  text={
                    state.obfuscatedEnrollment?.partnerUser?.name
                      ? state.obfuscatedEnrollment?.partnerUser?.name
                      : state?.partnerName
                  }
                />
                <FigmaText
                  textKey={Texts.componentsExternalCourseExtCourseColName}
                  text={
                    String(
                      state.obfuscatedEnrollment?.priceDetails
                        ? state.obfuscatedEnrollment?.priceDetails
                            .filter((pd) => pd.appliesTo == 'partnerUser')
                            .reduce((acc, pd) => acc + pd.finalPrice, 0) / 100
                        : enrollment
                          ? enrollment?.priceDetails
                              .filter((pd) => pd.appliesTo == 'partnerUser')
                              .reduce((acc, pd) => acc + pd.finalPrice, 0) / 100
                          : ''
                    ) + ' SEK'
                  }
                />
              </Box>
            )}
          </GrayBox>
          <Box fullWidth top spacing={SIXTEEN_PX}>
            <FigmaText textKey={Texts.registrationStep5PaymentPaymentOptionHeader} />
            {enabledPaymentMethods.some((pm) => pm.method == PaymentMethod.BankTransfer) &&
              (state?.paymentMethod !== PaymentMethod.BankTransfer ? (
                <PaymentBox
                  fullWidth
                  direction="row"
                  align="center"
                  justify="space-between"
                  right
                  spacing={EIGHT_PX}
                  onClick={() => onSelectPaymentMethod(PaymentMethod.BankTransfer)}
                >
                  <Box direction="row" align="center" gap={FOUR_PX}>
                    <Radio checked={(state?.paymentMethod as PaymentMethod) === PaymentMethod.BankTransfer} />
                    <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentBank} />
                  </Box>
                  <Box direction="row" align="center" gap={TWELVE_PX}>
                    {/* <FigmaText
                      textKey={Texts.registrationStep5PaymentPaymentMethodCost}
                      text={getPaymentMethodFee(PaymentMethod.BankTransfer)}
                    /> */}
                    <img src={bankIcon} width={20} />
                  </Box>
                </PaymentBox>
              ) : (
                <PurpleContainer fullWidth>
                  <HeaderBox
                    fullWidth
                    direction="row"
                    align="center"
                    justify="space-between"
                    right
                    spacing={EIGHT_PX}
                    onClick={() => onSelectPaymentMethod(PaymentMethod.BankTransfer)}
                  >
                    <Box direction="row" align="center" gap={FOUR_PX}>
                      <Radio checked={state?.paymentMethod === PaymentMethod.BankTransfer} />
                      <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentBank} />
                    </Box>
                    <Box direction="row" align="center" gap={TWELVE_PX}>
                      {/* <FigmaText
                        textKey={Texts.componentsPricingControlCssPaymentTotalAmount}
                        text={getPaymentMethodFee(PaymentMethod.BankTransfer)}
                      /> */}
                      <img src={bankIcon} />
                    </Box>
                  </HeaderBox>
                  <Box
                    fullWidth
                    direction="row"
                    align="center"
                    justify="space-between"
                    top
                    bottom
                    left
                    right
                    gap={EIGHT_PX}
                  >
                    <Box>
                      <Box direction="row" align="center" gap={TWO_PX}>
                        <FigmaText
                          textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewAmount}
                          text={formatText(getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewAmount), [
                            state?.finalPrice.split(' ')[0]
                          ])}
                        />
                        <FigmaText
                          textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewAccountNumber}
                          text={formatText(
                            getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewAccountNumber),
                            [client?.bankGiro]
                          )}
                        />
                        <FigmaText
                          textKey={Texts.registrationStep5PaymentPayInfoBankAccountNewRef}
                          text={formatText(getFigmaText(Texts.registrationStep5PaymentPayInfoBankAccountNewRef), [
                            state?.obfuscatedEnrollment?.ocr ? state?.obfuscatedEnrollment?.ocr : enrollment?.ocr
                          ])}
                        />
                      </Box>
                    </Box>
                    <ContinueButton onClick={onHandleNext}>
                      <FigmaText textKey={Texts.registrationCtactaContinue} />
                      <img src={iconArrow} width={16} height={16} />
                    </ContinueButton>
                  </Box>
                </PurpleContainer>
              ))}
            {enabledPaymentMethods.some((pm) => pm.method == PaymentMethod.Swish) &&
              (state?.paymentMethod !== PaymentMethod.Swish ? (
                <PaymentBox
                  fullWidth
                  direction="row"
                  align="center"
                  justify="space-between"
                  right
                  spacing={EIGHT_PX}
                  onClick={() => onSelectPaymentMethod(PaymentMethod.Swish)}
                >
                  <Box direction="row" align="center" gap={FOUR_PX}>
                    <Radio checked={(state?.paymentMethod as PaymentMethod) === PaymentMethod.Swish} />
                    <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentSwish} />
                  </Box>
                  <Box direction="row" align="center" gap={TWELVE_PX}>
                    {/* <FigmaText
                      textKey={Texts.registrationStep5PaymentPaymentMethodCost}
                      text={getPaymentMethodFee(PaymentMethod.Swish)}
                    /> */}
                    <img src={swishIcon} width="20px" />
                  </Box>
                </PaymentBox>
              ) : (
                <PurpleContainer fullWidth>
                  <HeaderBox
                    fullWidth
                    direction="row"
                    align="center"
                    justify="space-between"
                    right
                    spacing={EIGHT_PX}
                    onClick={() => onSelectPaymentMethod(PaymentMethod.Swish)}
                  >
                    <Box direction="row" align="center" gap={FOUR_PX}>
                      <Radio checked={state?.paymentMethod === PaymentMethod.Swish} />
                      <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentSwish} />
                    </Box>
                    <Box direction="row" align="center" gap={TWELVE_PX}>
                      {/* <FigmaText
                        textKey={Texts.componentsPricingControlCssPaymentTotalAmount}
                        text={getPaymentMethodFee(PaymentMethod.Swish)}
                      /> */}
                      <img src={swishIcon} />
                    </Box>
                  </HeaderBox>
                  {!swishInstructionUUID && !isLoading && (
                    <Box fullWidth top bottom left right>
                      <FigmaText textKey={Texts.registrationStep5PaymentStripePaymentInfo} />
                      <Controller
                        control={formProps.control}
                        name="phoneNumber"
                        render={({ field, fieldState }) => (
                          <TextFieldWithLabelForm
                            {...field}
                            value={field.value}
                            fullWidth
                            fieldState={fieldState}
                            placeholderTextKey={Texts.registrationStep5PaymentStripeEnterPhone}
                          />
                        )}
                      />
                      <Box top spacing={EIGHT_PX}>
                        <ContinueButton
                          onClick={formProps.handleSubmit(onSubmit)}
                          isDisabled={!formProps.formState.isValid}
                        >
                          <FigmaText textKey={Texts.registrationCtactaContinue} />
                          <img src={iconArrow} width={16} height={16} />
                        </ContinueButton>
                      </Box>
                    </Box>
                  )}
                  {isLoading && (
                    <Box fullWidth fullPadding align="center" gap={TWENTY_FOUR_PX}>
                      <img src={swishBigLogo} width={205} height={65} />
                      <GreenBox fullWidth fullPadding spacing={EIGHT_PX}>
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishPreparingPayment} />
                      </GreenBox>
                    </Box>
                  )}
                  {!isLoading && swishInstructionUUID && (
                    <Box fullWidth left right>
                      <Box fullWidth align="center" top bottom>
                        <img src={swishBigLogo} width={205} height={65} />
                      </Box>
                      <Header fullWidth fullPadding spacing={EIGHT_PX}>
                        <FigmaText
                          textKey={Texts.registrationStep5PaymentSwishWaiting}
                          text={`Väntar på Swish-betalning från ${formProps.getValues('phoneNumber')}`}
                        />
                      </Header>
                      <InformationContainer fullWidth fullPadding spacing={EIGHT_PX}>
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishWaitingInfo1} />
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishWaitingInfo2} />
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishWaitingInfo3} />
                      </InformationContainer>
                      <Box bottom>
                        <AbortButton isDisabled={state.isRegistrationCompleted} onClick={onHandleCancelPayment}>
                          <img src={iconArrowLeft} width={16} height={16} />
                          <FigmaText textKey={Texts.registrationCtactaAbort} />
                        </AbortButton>
                      </Box>
                    </Box>
                  )}
                  {hasError && (
                    <Box fullWidth fullPadding align="center" gap={TWENTY_FOUR_PX}>
                      <img src={swishBigLogo} width={205} height={65} />
                      <RedBox fullWidth fullPadding spacing={EIGHT_PX}>
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishGenericError} />
                      </RedBox>
                    </Box>
                  )}
                </PurpleContainer>
              ))}
            {enabledPaymentMethods.some((pm) => pm.method == PaymentMethod.Stripe) &&
              (state?.paymentMethod !== PaymentMethod.Stripe ? (
                <PaymentBox
                  fullWidth
                  direction="row"
                  align="center"
                  justify="space-between"
                  right
                  spacing={EIGHT_PX}
                  onClick={() => onSelectPaymentMethod(PaymentMethod.Stripe)}
                >
                  <Box direction="row" align="center" gap={FOUR_PX}>
                    <Radio checked={(state?.paymentMethod as PaymentMethod) === PaymentMethod.Stripe} />
                    <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentCard} />
                  </Box>
                  <Box direction="row" align="center" gap={TWELVE_PX}>
                    {/* <FigmaText
                      textKey={Texts.registrationStep5PaymentPaymentMethodCost}
                      text={getPaymentMethodFee(PaymentMethod.Stripe)}
                    /> */}
                    <img src={cards} width={50} />
                  </Box>
                </PaymentBox>
              ) : (
                <PurpleContainer fullWidth>
                  <HeaderBox
                    fullWidth
                    direction="row"
                    align="center"
                    justify="space-between"
                    right
                    spacing={EIGHT_PX}
                    onClick={() => onSelectPaymentMethod(PaymentMethod.Stripe)}
                  >
                    <Box direction="row" align="center" gap={FOUR_PX}>
                      <Radio checked={state?.paymentMethod === PaymentMethod.Stripe} />
                      <FigmaText textKey={Texts.registrationStep5PaymentRegPaymentCard} />
                    </Box>
                    <Box direction="row" align="center" gap={TWELVE_PX}>
                      {/* <FigmaText
                        textKey={Texts.componentsPricingControlCssPaymentTotalAmount}
                        text={getPaymentMethodFee(PaymentMethod.Stripe)}
                      /> */}
                      <img src={cards} width={60} />
                    </Box>
                  </HeaderBox>
                  {stripeConfig && stripeOptions && !isLoading && (
                    <Box fullWidth top bottom left right>
                      <Elements stripe={getStripe(stripeConfig)} options={stripeOptions}>
                        <StripeCheckoutForm onIsPaid={onSetIsPaid} />
                      </Elements>
                    </Box>
                  )}
                  {isLoading ||
                    (!stripeOptions && (
                      <Box fullWidth fullPadding align="center" gap={TWENTY_FOUR_PX}>
                        <img src={cards} width={205} />
                        <GreenBox fullWidth fullPadding spacing={EIGHT_PX}>
                          <FigmaText textKey={Texts.registrationStep5PaymentSwishPreparingPayment} />
                        </GreenBox>
                      </Box>
                    ))}
                  {hasError && (
                    <Box fullWidth fullPadding align="center" gap={TWENTY_FOUR_PX}>
                      <img src={cards} width={205} />
                      <RedBox fullWidth fullPadding spacing={EIGHT_PX}>
                        <FigmaText textKey={Texts.registrationStep5PaymentSwishGenericError} />
                      </RedBox>
                    </Box>
                  )}
                </PurpleContainer>
              ))}
          </Box>
        </>
      ) : null}
    </OpenContainer>
  )
}

const SuccessMessage = styled(Box)`
  background: #8aff7e;
  border-radius: ${BorderRadixes.small};
`

const GrayBox = styled(Box)`
  max-width: 350px;
  background-color: #efefef;
  border-radius: ${BorderRadixes.regular};
`

const PaymentBox = styled(Box)`
  border: 1px solid #e6e6e6;
  margin-top: ${EIGHT_PX};
  border-radius: ${BorderRadixes.small};
  cursor: pointer;
`

const PurpleContainer = styled(Box)`
  margin-top: ${EIGHT_PX};
  border: 1px solid #7617d0;
  box-shadow: 0px 0px 8px 0px #7617d0;
  border-radius: ${BorderRadixes.small};
`

const HeaderBox = styled(Box)`
  border-bottom: 1px solid #c9c9c9;
`

const Header = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: rgba(106, 171, 55, 0.2);
`

const GreenBox = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: rgba(106, 171, 55, 0.2);
`

const InformationContainer = styled(Box)`
  margin-top: ${TWELVE_PX};
  margin-bottom: ${THIRTY_TWO_PX};
  border-radius: ${BorderRadixes.regular};
  background-color: rgba(106, 171, 55, 0.1);
`

const RedBox = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: rgba(171, 55, 55, 0.2);
`

export default memo(CourseRegistrationStepFour)
