import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { getActivitiesByCustomerId } from '../libs/DBApiHandler'
import { Texts } from '../../../pure-js/libs/Texts'
import { Link } from 'react-router-dom'
import { Tabs, Tab, Typography } from '@mui/material'
import { getFigmaText } from '../libs/TextRepository'
import { AccessTime, CalendarToday } from '@mui/icons-material'
import Box from './Box'
import dayjs from 'dayjs'
import { getLocalizedActivityType } from '../utils/activityUtils'

interface RelatedActivitiesProps {
  activity: Activity
  client: Client
}

const OtherActivititesTabs = ({ activity, client }: RelatedActivitiesProps) => {
  const getDefaultTab = (hasRelated: boolean, hasCourses: boolean, hasEvents: boolean, hasOthers: boolean) => {
    if (activity.groupingCode && hasRelated) return 0
    if (hasCourses) return activity.groupingCode ? 1 : 0
    if (hasEvents) return activity.groupingCode ? 2 : 1
    if (hasOthers) return activity.groupingCode ? 3 : 2
    return 0 // fallback
  }

  const [relatedActivities, setRelatedActivities] = useState<Activity[]>([])
  const [courses, setCourses] = useState<Activity[]>([])
  const [events, setEvents] = useState<Activity[]>([])
  const [others, setOthers] = useState<Activity[]>([])
  const [activeTab, setActiveTab] = useState(0) // Start with 0, will be updated in useEffect
  useEffect(() => {
    const fetchActivities = async () => {
      if (!client.id) return

      const allActivities = await getActivitiesByCustomerId(client.id)
      const threeWeeksAgo = dayjs().subtract(21, 'days').startOf('day')

      // Filter out the current activity from all activities and filter by city
      const filteredActivities = allActivities.filter((a) => a.id !== activity.id && a.city === activity.city)

      // Filter future activities first
      const futureActivities = filteredActivities.filter((a) =>
        dayjs(a.startDate).startOf('day').isAfter(threeWeeksAgo)
      )

      // Sort helper function
      const sortByDate = (a: Activity, b: Activity) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()

      // Filter related activities (these don't need date filtering)
      if (activity.groupingCode) {
        const related = filteredActivities // Use filteredActivities for related since we want all dates
          .filter((a) => a.groupingCode === activity.groupingCode)
          .sort(sortByDate)
        setRelatedActivities(related)
      }
      console.log(futureActivities, 'futureActivities')

      // Filter courses and events from pre-filtered future activities.
      const activeCourses = futureActivities
        .filter((a) => a.status === 'active' && a.type === 'Course')
        .sort(sortByDate)
      setCourses(activeCourses)

      const activeEvents = futureActivities
        .filter((a) => a.status === 'active' && a.type === 'Occasion')
        .sort(sortByDate)
      setEvents(activeEvents)

      const activeOthers = futureActivities.filter((a) => a.status === 'active' && a.type === 'Other').sort(sortByDate)
      setOthers(activeOthers)
    }

    fetchActivities()
  }, [client.id, activity.groupingCode])

  // Add new useEffect to update active tab when activities change
  useEffect(() => {
    const hasRelated = Boolean(activity.groupingCode && relatedActivities.length > 0)
    const hasCourses = courses.length > 0
    const hasEvents = events.length > 0
    const hasOthers = others.length > 0

    setActiveTab(getDefaultTab(hasRelated, hasCourses, hasEvents, hasOthers))
  }, [relatedActivities.length, courses.length, events.length, others.length, activity.groupingCode])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return `${date.getDate()}/${date.getMonth() + 1}`
  }

  const groupActivitiesByMonth = (activities: Activity[]) => {
    return activities.reduce((groups: { [key: string]: Activity[] }, activity) => {
      const date = dayjs(activity.startDate).startOf('month')
      const key = date.format('YYYY-MM') // Use consistent date format
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(activity)
      return groups
    }, {})
  }

  const formatMonthHeader = (dateKey: string) => {
    const date = dayjs(dateKey + '-01') // Add day to ensure valid date
    return date.toDate().toLocaleString('sv-SE', { month: 'short', year: 'numeric' })
  }

  const renderActivityList = (activities: Activity[]) => {
    if (activities.length === 0) return null

    if (activeTab === (activity.groupingCode ? 2 : 1)) {
      // Events tab
      const groupedActivities = groupActivitiesByMonth(activities)
      return (
        <List>
          {Object.entries(groupedActivities).map(([monthKey, monthActivities]) => (
            <div key={monthKey}>
              <MonthHeader>{formatMonthHeader(monthKey)}</MonthHeader>
              {monthActivities.map((activity) => (
                <ListItem key={activity.id}>
                  <ActivityLink to={`/activity/${activity.id}`} target="_blank" rel="noopener noreferrer">
                    {activity.name}
                  </ActivityLink>
                  <ActivityMetadata direction="row" align="center">
                    <MetadataItem direction="row" align="center">
                      <CalendarToday sx={{ fontSize: 16, marginRight: 1, color: '#999' }} />
                      {formatDate(activity.startDate)}
                    </MetadataItem>
                    <MetadataItem direction="row" align="center">
                      <AccessTime sx={{ fontSize: 16, marginRight: 1, color: '#999' }} />
                      {activity.time}
                    </MetadataItem>
                  </ActivityMetadata>
                </ListItem>
              ))}
            </div>
          ))}
        </List>
      )
    }

    // Regular list for non-event tabs
    return (
      <List>
        {activities.map((activity) => (
          <ListItem key={activity.id}>
            <ActivityLink to={`/activity/${activity.id}`} target="_blank" rel="noopener noreferrer">
              {activity.name}
            </ActivityLink>
            <ActivityMetadata direction="row" align="center">
              <MetadataItem direction="row" align="center">
                <CalendarToday sx={{ fontSize: 16, marginRight: 1, color: '#999' }} />
                {formatDate(activity.startDate)}
              </MetadataItem>
              <MetadataItem direction="row" align="center">
                <AccessTime sx={{ fontSize: 16, marginRight: 1, color: '#999' }} />
                {activity.time}
              </MetadataItem>
            </ActivityMetadata>
          </ListItem>
        ))}
      </List>
    )
  }

  const getAvailableTabs = () => {
    return (
      <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
        {activity.groupingCode && relatedActivities.length > 0 && (
          <Tab label={getFigmaText(Texts.externalActivityPageSegmentOtherTabRelated)} />
        )}
        {<Tab label={getLocalizedActivityType('Course', client)} />}
        {<Tab label={getLocalizedActivityType('Occasion', client)} />}
        {<Tab label={getLocalizedActivityType('Other', client)} />}
      </Tabs>
    )
  }

  // Don't render anything if no activities in any category
  if (!hasActivitiesToDisplay()) {
    return null
  }

  return (
    <Container fullWidth className="segment-container">
      <Typography variant="h5" component="h2" className="header-title">
        {getFigmaText(Texts.externalActivityPageSegmentPlaceRelevantActivities)}
      </Typography>
      <TabsContainer>{getAvailableTabs()}</TabsContainer>
      <TabContent fullWidth>
        {activity.groupingCode &&
          relatedActivities.length > 0 &&
          activeTab === 0 &&
          renderActivityList(relatedActivities)}
        {courses.length > 0 && activeTab === (activity.groupingCode ? 1 : 0) && renderActivityList(courses)}
        {events.length > 0 && activeTab === (activity.groupingCode ? 2 : 1) && renderActivityList(events)}
        {others.length > 0 && activeTab === (activity.groupingCode ? 3 : 2) && renderActivityList(others)}
      </TabContent>
    </Container>
  )

  // Helper function to check if there are any activities to display
  function hasActivitiesToDisplay() {
    return (activity.groupingCode && relatedActivities.length > 0) || courses.length > 0 || events.length > 0
  }
}

const ActivityMetadata = styled(Box)`
  color: #444;
  gap: 16px;
`

const MetadataItem = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 14px;
`

const Container = styled(Box)`
  padding: 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
`

const List = styled.ul`
  margin: 0;
  padding: 8px 0px;
  list-style: none;
`

const ListItem = styled.li`
  padding: 2px 8px;
  border-radius: 4px;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const ActivityLink = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  color: #333;

  padding: 8px 0;
  display: block;
  width: 100%;
`

const TabsContainer = styled(Box)`
  border-bottom: 1px solid #eee;
  width: 100%;
  .MuiTabs-root {
    min-height: 40px;
  }

  .MuiTab-root {
    min-height: 40px;
    text-transform: none;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
  }
`

const TabContent = styled(Box)``

const MonthHeader = styled.h4`
  font-size: 14px;
  color: rgb(176, 176, 176);
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 4px;
`

export default OtherActivititesTabs
