import styled from '@emotion/styled'
import { memo, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import Box from '../components/Box'
import RegisterStepsHeader from '../components/RegisterStepsHeader'
import CourseRegistrationStep from '../components/CourseRegistrationStep'
import { useActivity } from '../hooks/useActivity'
import { registerReducer } from '../libs/RegisterReducer'
import { useRegisterActionFunctions } from '../hooks/useRegisterActions'
import { DEFAULT_STATE } from '../hooks/useAppState'
import { REGISTER_PAGE_MAX_WIDTH } from '../enums/HardCodedValues'
import { SIXTEEN_PX, TEN_PX } from '../enums/Spacings'
import { stepConfig } from '../helpers/RegisterPageHelper'
import EmptyActivity from '../components/EmptyActivity'
import Spinner from '../components/Spinner'
import { useObfuscatedEnrollment } from '../libs/ReactQueryHooks'
import { EnrollmentPaymentStatus, EnrollmentStatus } from '../../../pure-js/types/GrooverTypes'
import PreSelectedActivity from '../components/PreSelectedActivity'
import DeletedEnrollment from '../components/DeletedEnrollment'
import { useClient } from '../hooks/useClient'

const RegisterPage = () => {
  const { activityId, enrollmentId } = useParams<{ activityId: string; enrollmentId: string }>()
  const { data: activity, isLoading } = useActivity(activityId as string)
  const [state, dispatcher] = useReducer(registerReducer, DEFAULT_STATE)
  const { data: obfuscatedEnrollmentData, isLoading: isLoadingObfuscatedData } = useObfuscatedEnrollment(
    enrollmentId as string,
    state
  )
  const activityClient = useClient(activity)
  const actions = useRegisterActionFunctions(dispatcher)

  useEffect(() => {
    if (!!enrollmentId && !!obfuscatedEnrollmentData) {
      actions.onSetObfuscatedEnrollment(obfuscatedEnrollmentData)

      if (obfuscatedEnrollmentData?.paymentStatus === EnrollmentPaymentStatus.Paid) return actions.onOpenStep(5)
      if (obfuscatedEnrollmentData.status !== EnrollmentStatus.Pending) return actions.onOpenStep(4)
      actions.onOpenStep(3)
    }
  }, [enrollmentId, obfuscatedEnrollmentData, state.isPaid])

  if (obfuscatedEnrollmentData?.status === EnrollmentStatus.Deleted)
    return <DeletedEnrollment client={activityClient} />
  if (isLoading || isLoadingObfuscatedData) return <Spinner />
  if (!activity) return <EmptyActivity />

  return (
    <Container fullWidth fullHeight fullPadding spacing={TEN_PX} gap={SIXTEEN_PX}>
      {/* <LanguageToggle /> */}
      <RegisterStepsHeader
        state={state}
        activeRegisterStep={state.activeRegisterStep}
        isRegistrationCompleted={state.isRegistrationCompleted}
      />

      {/*  Only show preselected activities during first steps of registration */}
      {activity && !obfuscatedEnrollmentData && <PreSelectedActivity activity={activity} />}

      <CourseRegistrationStep stepConfig={stepConfig} state={state} activity={activity} actions={actions} />
    </Container>
  )
}

const Container = styled(Box)`
  max-width: ${REGISTER_PAGE_MAX_WIDTH};
`

export default memo(RegisterPage)
