import QRCode from 'react-qr-code'
import Box from './Box'

type Props = {
  value: string
  showText?: boolean
}

const QRCodeElement = ({ value, showText }: Props) => {
  return (
    <Box fullWidth direction="column" justify="space-between" align="center">
      <QRCode value={value} />
      {showText && <span>{value}</span>}
    </Box>
  )
}

export default QRCodeElement
