import { memo } from 'react'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { FOUR_PX, SIXTEEN_PX } from '../enums/Spacings'
import { getWeekdayAbbreviation } from '../libs/DayJsHelper'
import Box from './Box'
import FigmaText from './FigmaText'

type Props = {
  activity: Activity
}

const ClosedActivityDetails = ({ activity }: Props) => (
  <Box direction="row" gap={SIXTEEN_PX}>
    <FigmaText textKey={Texts.registrationStep6SummaryCssActivitySummeryMetaData} text={activity.startDate} />
    <Box direction="row" gap={FOUR_PX}>
      <FigmaText
        textKey={Texts.registrationStep6SummaryCssActivitySummeryMetaData}
        text={getWeekdayAbbreviation(activity.startDate)}
      />
      <FigmaText textKey={Texts.registrationStep6SummaryCssActivitySummeryMetaData} text={activity.time} />
    </Box>
  </Box>
)

export default memo(ClosedActivityDetails)
