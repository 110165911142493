import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'
import { NODE_ENV_DEVELOPMENT, FIREBASE_CONFIG, REGION } from '../../../pure-js/libs/Consts'
import config from '../Config'

export const app = initializeApp(FIREBASE_CONFIG)

export const analytics = config.enableAnalytics && getAnalytics(app)

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true })

export const auth = getAuth(app)

export const functions = getFunctions(app, REGION)

export const storage = getStorage(app)

// local development
if (process.env.NODE_ENV === NODE_ENV_DEVELOPMENT && import.meta.env.VITE_FUNCTIONS_LOCAL === 'true') {
  console.log('[LOCAL] Using functions from local emulator, needs "functions" folder started with "yarn serve"')
  connectFunctionsEmulator(functions, 'localhost', 5001)
}
